import {
  ProductContract,
  ShoppingCartItemModel,
  UpdateCartReq,
} from 'epromo-types';
import { MutableRefObject } from 'react';

import { isCheckoutPage } from './useCart';

export const updateQueue = (
  itemsQueue: MutableRefObject<Record<string, ShoppingCartItemModel>>,
  product: ProductContract,
  amount: number,
  invoiceNumber?: number
) => {
  if (!itemsQueue.current[product.id]) {
    itemsQueue.current[product.id] = {
      product,
      amount,
      measureUnit: product.measureUnit,
      invoiceNumber: invoiceNumber || 0,
    };
  }
  itemsQueue.current[product.id].amount = amount;
  itemsQueue.current[product.id].invoiceNumber = invoiceNumber || 0;
};

interface CartProductPayload {
  amount: number;
  product: ProductContract;
  invoiceNumber: number;
}

export const updateFn = async (
  addingTm: MutableRefObject<NodeJS.Timer | undefined>,
  updateCartFromCatalog: (payload: UpdateCartReq[]) => Promise<void>,
  refetchCartTm: MutableRefObject<NodeJS.Timer | undefined>,
  sendQueue: MutableRefObject<Record<string, ShoppingCartItemModel>>,
  routerPath?: string,
  checkoutPageCallback?: (payload: CartProductPayload[]) => void,
  checkoutPageFetchCallback?: (payload: CartProductPayload[]) => void
) => {
  // @ts-ignore
  clearTimeout(addingTm.current);
  // @ts-ignore
  clearTimeout(refetchCartTm.current);
  const keys = Object.keys(sendQueue);

  if (keys.length > 0) {
    const payload = Object.keys(sendQueue.current).map((acc) => {
      return {
        amount: sendQueue.current[acc].amount,
        product: sendQueue.current[acc].product,
        invoiceNumber: sendQueue.current[acc].invoiceNumber || 0,
      };
    });
    sendQueue.current = {};

    if (isCheckoutPage(routerPath)) {
      checkoutPageCallback?.(payload);
    }

    await updateCartFromCatalog(payload);

    checkoutPageFetchCallback?.(payload);
  }
};

export function pageLeaveSentOutQueue(
  sendQueue: MutableRefObject<Record<string, ShoppingCartItemModel>>,
  itemsQueue: MutableRefObject<Record<string, ShoppingCartItemModel>>,
  addingTm: MutableRefObject<NodeJS.Timer | undefined>,
  refetchCartTm: MutableRefObject<NodeJS.Timer | undefined>,
  routerPath: string,
  updateCartFromCatalogCallback: (payload: CartProductPayload[]) => void,
  checkoutPageCallback?: (payload: CartProductPayload[]) => void
) {
  const pp = {
    ...sendQueue.current,
    ...itemsQueue.current,
  };
  const keys = Object.keys(pp);
  if (keys.length > 0) {
    // @ts-ignore
    clearTimeout(addingTm.current);
    // @ts-ignore
    clearTimeout(refetchCartTm.current);

    const payload = Object.keys(pp).map((acc) => {
      return {
        amount: pp[acc].amount,
        product: pp[acc].product,
        invoiceNumber: pp[acc].invoiceNumber || 0,
      };
    });
    if (isCheckoutPage(routerPath)) {
      checkoutPageCallback?.(payload);
    }
    updateCartFromCatalogCallback(payload);
  }
}
