import { useTranslation } from 'next-i18next';

import { PaymentCardContract } from 'epromo-types/Checkout';
import { useToaster, getAddressId } from 'epromo-lib/hooks';
import { Endpoints, useGetQuery } from 'epromo-lib';

export const usePaymentCards = (
  isReferenceAlreadyUsed: boolean,
  payment_reference: string | undefined
) => {
  const { t } = useTranslation('common');
  const { toast } = useToaster();

  const urlParamExists = payment_reference != null;

  const {
    isFetched: hasCheckedCard,
    isError,
    isLoading: isCheckingCard,
  } = useGetQuery<PaymentCardContract>({
    queryKey: [`new-card-check-${payment_reference}`, getAddressId()],
    endPoint: `${Endpoints.payment}/${payment_reference}/check`,
    enabled: urlParamExists && !isReferenceAlreadyUsed,
    toastErrorGlobally: true,
    refetchOnWindowFocus: false,
  });

  const fetchCardsList =
    isReferenceAlreadyUsed ||
    !urlParamExists ||
    (urlParamExists && hasCheckedCard);

  const {
    data: paymentCards,
    isLoading: isLoadingCards,
    isFetched: hasFetchedCards,
  } = useGetQuery<PaymentCardContract[]>({
    queryKey: ['user-payment-methods', getAddressId()],
    endPoint: Endpoints.payment,
    enabled: fetchCardsList,
  });

  const showSuccessToast =
    !isError && !isCheckingCard && hasCheckedCard && !isReferenceAlreadyUsed;

  if (showSuccessToast) {
    toast({
      type: 'success',
      message: t('cardAdded'),
      toastId: 'veryUniqueIdToPreventMultipleToasts',
    });
  }

  return {
    paymentCards,
    isLoading: isLoadingCards,
    hasFetchedCards,
    hasCheckedCard,
  };
};
