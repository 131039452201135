import Select, {
  CSSObjectWithLabel,
  MultiValue,
  SingleValue,
  PropsValue,
  GroupBase,
  SelectComponentsConfig,
  StylesConfig,
  SelectInstance,
  InputActionMeta,
} from 'react-select';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { MutableRefObject } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

const DropDownStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    minHeight: 48,
    padding: '8px 12px',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    margin: 0,
    lineHeight: '20px',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
};

export interface DropDownProps<T, Option> {
  value?: PropsValue<Option> | undefined;
  placeholder?: string;
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  defaultOptions?: Option[] | undefined;
  isMulti?: boolean;
  hasError?: boolean;
  getOptionValue?: (item: Option) => string;
  getOptionLabel?: (item: Option) => string;
  noOptionsMessage?: string;
  onChange?: (item: MultiValue<Option> | SingleValue<Option>) => void;
  onInputChange?: (newValue: string, action: InputActionMeta) => void;
  isRounded?: boolean;
  components?:
    | Partial<SelectComponentsConfig<Option, boolean, GroupBase<Option>>>
    | undefined;
  styles?: StylesConfig<Option, boolean, GroupBase<Option>> | undefined;
  selectRef?: MutableRefObject<SelectInstance<T> | null>;
  isLoading?: boolean;
  filterOption?: (
    option: FilterOptionOption<Option>,
    inputValue: string
  ) => boolean;
  inputValue?: string;
  controlShouldRenderValue?: boolean;
}

export function DropDown<T, Option>({
  value,
  name,
  placeholder,
  disabled,
  defaultOptions,
  isMulti,
  getOptionValue,
  getOptionLabel,
  onChange,
  onInputChange,
  noOptionsMessage = '',
  hasError,
  isRounded,
  components,
  styles = {},
  selectRef,
  isLoading,
  filterOption,
  inputValue,
  controlShouldRenderValue,
}: DropDownProps<T, Option>) {
  const extraComponents = components ? { ...components } : {};
  const mergedStyles = {
    ...DropDownStyles,
    ...styles,
  };

  return (
    <Select
      //@ts-ignore
      ref={selectRef}
      instanceId={name}
      //@ts-ignore
      components={{
        DropdownIndicator: () => (
          <ChevronDownIcon
            className="text-secondary-500 h-6 w-6 text-sm"
            aria-hidden="true"
          />
        ),
        IndicatorSeparator: null,
        ...extraComponents,
      }}
      inputValue={inputValue}
      className={clsx(hasError && 'has-error', isRounded && 'is-rounded')}
      styles={mergedStyles}
      value={value}
      onChange={(v) => {
        onChange?.(v);
      }}
      controlShouldRenderValue={controlShouldRenderValue}
      isLoading={isLoading}
      classNamePrefix="rs"
      placeholder={placeholder}
      isMulti={isMulti}
      name={name}
      noOptionsMessage={() => <span>{noOptionsMessage || ''}</span>}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      options={defaultOptions}
      filterOption={filterOption}
      isDisabled={disabled}
      menuPlacement="auto"
      onInputChange={onInputChange}
      menuPortalTarget={
        typeof window !== 'undefined' ? document.querySelector('body') : null
      }
    />
  );
}
