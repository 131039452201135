import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import {
  B2B_CART_KEY,
  B2C_CART_KEY,
  B2C_CART_KEY_REFETCHED,
} from '../constants';

export function useCartIsFetching() {
  const isB2bCartFetching = useIsMutating({
    mutationKey: [B2B_CART_KEY],
  });
  const isB2cCartFetching = useIsFetching({
    queryKey: [B2C_CART_KEY],
  });
  const isB2cCartRefetching = useIsFetching({
    queryKey: [B2C_CART_KEY_REFETCHED],
  });

  const isCartFetching =
    !!isB2cCartFetching || !!isB2bCartFetching || !!isB2cCartRefetching;

  return {
    isCartFetching: !!isCartFetching,
    isB2bCartFetching: !!isB2bCartFetching,
    isB2cCartFetching: !!isB2cCartFetching,
  };
}
