import {
  AddressTypeEnum,
  BaseBilling,
  CreateB2CCompanyReq,
  DeviceTypeEnum,
  LoyaltyCard,
  NewLoyaltyCardApplication,
} from '../index';
import { B2CCompanyContract } from '../Company';

export type CardData = {
  cardNumber: string;
  registrationCode: string;
  clientName: string;
  registrationAddress: string;
  vatCode: string;
  cardOwner: string;
  validUntil: string;
  firmId: string;
  isGuest: boolean;
};

export type RegisterUserReq = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  marketingOrdersAllowed: boolean;
  phoneNumber?: string;
  birthDateTimestamp: number;
  socialMediaAccount: boolean;
  deviceType: DeviceTypeEnum;
  userInvoicesNeeded: boolean;
  promoCardCode?: string;
  newLoyaltyCardContract?: NewLoyaltyCardApplication;
  individualActivity?: CreateB2CCompanyReq;
};

export type LinkCardReq = {
  string: string;
};

export type VerificationCodeReq = {
  code: number;
};

export type CreateB2cCompanyReq = {
  email?: string | undefined;
  phoneNumber: string;
  id: string;
};

export interface CreateB2cCompanyResponse extends B2CCompanyContract {}

export type ResendEmailCodeReq = {
  deviceType: DeviceTypeEnum;
};

export type ResendPhoneCodeReq = {
  deviceType: DeviceTypeEnum;
  string: string;
};

export type ResendCodeResponse = {
  value: boolean;
};

export interface VerificationCodeResponse extends UserProfile {}

export type RegisterUserResponse = {
  token: string;
  userProfile: UserProfile;
};

export interface UserBillingAddress {
  id: string;
  phoneNumberVerified: boolean;
  address: string | null | undefined;
  city: string | null | undefined;
  apartmentNumber: string | null | undefined;
  phoneNumber: string | null | undefined;
  zipCode: string | null | undefined;
}

export interface UserAddress extends DeliveryAddressContract {
  isPrimary?: boolean;
  city?: string;
  name?: string;
  apartmentNumber?: string;
  comment?: string;
  longitude?: number;
  latitude?: number;
  phoneNumberVerified?: boolean;
  zipCode?: string;
  depotId?: string;
  regionId?: string;
  title: string | null;
  orgId: number | null;
  shopChain: string | null;
}

export interface PersonalDataBase {
  firstName: string;
  lastName: string;
  birthDateTimestamp: number;
}

export interface UserProfile extends PersonalDataBase {
  id: string;
  email: string;
  image: string | null | undefined;
  phoneNumber: string | null | undefined;
  phoneNumberVerified: boolean;
  emailVerified: boolean;
  showCreateBusinessAccount: boolean | null;
  userBillingAddress: UserBillingAddress;
  primaryAddress: UserAddress;
  primaryBillingAddress: BaseBilling;
  userLoyaltyCards: LoyaltyCard[];
}

export interface DeliveryAddressContract {
  id: string;
  holderName: string;
  address: string;
  type: keyof typeof AddressTypeEnum;
  isServiceable: boolean;
  showContractPricesFilter?: boolean | null;
  activity: string | null;
  phoneNumber: string | null;
  phoneNumberConfirmationRequired: boolean;
  posId: string;
}

export interface UserAddressB2b {
  title: string;
  type: keyof typeof AddressTypeEnum;
  addresses: UserAddress[];
}

export enum CookieCategoryIdEnum {
  mandatory = 'mandatory',
  functional = 'functional',
  marketing = 'marketing',
  thirdParty = 'thirdParty',
  statistics = 'statistics',
}

export type ConsentValues = Record<CookieCategoryIdEnum, boolean> & {
  consentTimestamp: number;
};
