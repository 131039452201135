import { fetchFooterData } from 'epromo-lib/Footer';
import { fetchCookieConsent } from 'epromo-lib/CookieConsent';
import { fetchGlobalNotifications } from 'epromo-lib/GlobalNotifications';
import { fetchLandingPageData, ILandingPage } from 'epromo-lib/Landing';

import {
  ICmsCookieConsentResponse,
  ICmsFooter,
  IGlobalNotificationsResponse,
} from '../types/payload-types';

export async function getAppData(
  locale?: string | undefined
): // eslint-disable-next-line max-len
Promise<
  (
    | ICmsFooter
    | ICmsCookieConsentResponse
    | IGlobalNotificationsResponse
    | undefined
    | null
  )[]
> {
  const promises = [
    fetchFooterData(locale, null),
    fetchCookieConsent(locale),
    fetchGlobalNotifications(locale),
  ];
  return Promise.all(promises);
}

export async function getLandingPageData(
  locale?: string | undefined,
  token?: string | undefined
): Promise<ILandingPage | null> {
  return fetchLandingPageData(locale, token);
}
