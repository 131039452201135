import { Fragment, HTMLAttributes } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { CloseDialogButton } from '@components/molecules/CloseDialogButton';

const alignStyle = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
};

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  isOpened: boolean;
  isClosable?: boolean;
  verticalPosition?: 'top' | 'center' | 'bottom';
  headlessDialogClassname?: string;
}

export function Dialog({
  onClose = () => {},
  isOpened,
  children,
  isClosable = false,
  className,
  verticalPosition = 'center',
  headlessDialogClassname,
}: DialogProps) {
  return (
    <Transition appear show={isOpened} as={Fragment}>
      <HeadlessDialog
        as="div"
        className={twMerge(`relative z-50`, headlessDialogClassname)}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={clsx(
              'flex min-h-full justify-center p-4',
              alignStyle[verticalPosition]
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessDialog.Panel
                className={twMerge(
                  'relative',
                  'w-full md:max-w-[40rem]',
                  className
                )}
              >
                {isClosable && (
                  <div
                    className={clsx(
                      'absolute -top-2',
                      '-right-3 md:-right-4',
                      'z-10 sm:block'
                    )}
                  >
                    <CloseDialogButton onClick={onClose} />
                  </div>
                )}
                {children}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
}
