import { useRouter } from 'next/router';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function useInternalLinks(stripLocale: boolean = false) {
  const { locale } = useRouter();

  const localeStr = stripLocale ? '' : `/${locale}`;

  const internalLinksDict: Record<
    string,
    {
      privacyPolicyUrlSlug: string;
      termsAndConditionsUrlSlug: string;
      contactsUrlSlug: string;
      invoicingPolicyUrlSlug: string;
      marketingTermsUrlSlug: string;
    }
  > = {
    lt: {
      contactsUrlSlug: publicRuntimeConfig.contactsSlug,
      privacyPolicyUrlSlug: publicRuntimeConfig.privacyPolicyUrlSlug,
      termsAndConditionsUrlSlug: publicRuntimeConfig.termsAndConditionsUrlSlug,
      invoicingPolicyUrlSlug: publicRuntimeConfig.invoicingPolicyUrlSlug,
      marketingTermsUrlSlug: publicRuntimeConfig.marketingTermsUrlSlug,
    },
    en: {
      contactsUrlSlug: publicRuntimeConfig.contactsSlugEn,
      privacyPolicyUrlSlug: publicRuntimeConfig.privacyPolicyUrlSlugEn,
      termsAndConditionsUrlSlug:
        publicRuntimeConfig.termsAndConditionsUrlSlugEn,
      invoicingPolicyUrlSlug: publicRuntimeConfig.invoicingPolicyUrlSlugEn,
      marketingTermsUrlSlug: publicRuntimeConfig.marketingTermsUrlSlugEn,
    },
    lv: {
      contactsUrlSlug: publicRuntimeConfig.contactsSlugLv,
      privacyPolicyUrlSlug: publicRuntimeConfig.privacyPolicyUrlSlugLv,
      termsAndConditionsUrlSlug:
        publicRuntimeConfig.termsAndConditionsUrlSlugLv,
      invoicingPolicyUrlSlug: publicRuntimeConfig.invoicingPolicyUrlSlugLv,
      marketingTermsUrlSlug: publicRuntimeConfig.marketingTermsUrlSlug,
    },
    ee: {
      contactsUrlSlug: publicRuntimeConfig.contactsSlugEn,
      privacyPolicyUrlSlug: publicRuntimeConfig.privacyPolicyUrlSlugEe,
      termsAndConditionsUrlSlug:
        publicRuntimeConfig.termsAndConditionsUrlSlugEn,
      invoicingPolicyUrlSlug: publicRuntimeConfig.invoicingPolicyUrlSlugEn,
      marketingTermsUrlSlug: publicRuntimeConfig.marketingTermsUrlSlug,
    },
  };

  const linksObj = internalLinksDict[locale ?? 'en'];

  return {
    privacyPolicyUrlSlug: `${localeStr}/${linksObj.privacyPolicyUrlSlug}`,
    // eslint-disable-next-line max-len
    termsAndConditionsUrlSlug: `${localeStr}/${linksObj.termsAndConditionsUrlSlug}`,
    contactsUrlSlug: `${localeStr}/${linksObj.contactsUrlSlug}`,
    invoicingPolicyUrlSlug: `${localeStr}/${linksObj.invoicingPolicyUrlSlug}`,
    marketingTermsUrlSlug: `${localeStr}/${linksObj.marketingTermsUrlSlug}`,
  };
}
