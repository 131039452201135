import axios from 'axios';
import getConfig from 'next/config';

import { FeatureFlagsResponse } from 'epromo-types';

import { resolveHeaders } from '../index';
import { getErrorMessage } from '../utils/error';

const { publicRuntimeConfig } = getConfig();

export const fetchFeatureFlags = async (locale?: string) => {
  try {
    const headers = resolveHeaders(null, locale);

    const response = await axios.get<FeatureFlagsResponse>(
      `${publicRuntimeConfig.baseUrl}/api/feature-flags`,
      {
        headers,
      }
    );

    return response.data.FeatureFlag.flags
      ?.filter((x) => x.enabled)
      .map((y) => y.flagKey);
  } catch (error) {
    console.log(getErrorMessage(error));
    return null;
  }
};
