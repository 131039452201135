import debounce from 'lodash.debounce';
import { useEffect, RefObject, useState, useCallback } from 'react';

export const useElementDimensions = <T extends HTMLElement>(
  elementRef: RefObject<T>
) => {
  const [elementHeight, setElementHeight] = useState<number>();
  const [elementWidth, setElementWidth] = useState<number>();

  const onWindowResize = () => {
    setElementWidth(elementRef?.current?.clientWidth);
    setElementHeight(elementRef?.current?.clientHeight);
  };

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', debounce(onWindowResize, 300));

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  return { height: elementHeight, width: elementWidth };
};
