import { IconName } from 'ui/components/atoms/Icon';

export interface MenuLink {
  href: string;
  transKey: string;
  iconName?: IconName;
  innerLinks?: Omit<MenuLink, 'innerLinks'>[];
}

export const StaticLinks = {
  Home: '/',
  Login: '/auth/login',
  NoLoyaltyCard: '/no-loyalty-card',
  Register: '/auth/register',
  RegisterNoCard: '/auth/register/no-card',
  RegisterWithCard: '/auth/register/have-card',
  RegisterB2CCard: '/auth/register/b2c-card',
  ResetPassword: '/auth/reset-password',
  Profile: '/profile',
  Favourites: '/profile/favourites',
  ProfileClientCard: '/profile/client-card',
  ProfileOrders: '/profile/orders',
  ProfileOrder: '/profile/orders/[id]',
  DeliveryAddresses: '/profile/delivery-addresses',
  InvoiceRecipients: '/profile/invoice-recipients',
  ProfileInvoices: '/profile/invoices',
  ProfileOtherDocuments: '/profile/other-documents',
  ProfilePaymentMethodB2B: '/profile/payment-method-b2b',
  ProfilePaymentMethodB2C: '/profile/payment-method-b2c',
  ProfilePurchaseConditions: '/profile/purchase-conditions',
  ProfileEmployeeManagement: '/profile/employee-management',
  Checkout: '/checkout',
  ShoppingCart: '/checkout/summary',
  PaymentResult: '/checkout/payment-result',
  SearchResults: '/search',
  CartTemplates: '/profile/templates',
  Wishlist: '/profile/favourites',
  CookiePolicy: '/cookie-policy',
  Help: '/get-help',
  SignForBusinessAccount: '/sign-up-b2b',
  Search: '/search',
  NotFound: '/not-found',
  ConfirmEmail: '/auth/confirmEmail',
};

export const B2B_CART_KEY = 'new-user-b2b-checkout-summary';
export const B2C_CART_KEY = 'new-user-b2c-checkout-summary';
export const B2C_CART_KEY_REFETCHED = 'new-user-b2c-checkout-summary-refetched';
export const PROFILE_QUERY_KEY = 'profile'

export const PRIVATE_ROUTES = [StaticLinks.Profile, StaticLinks.Checkout];

export const baseUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.Profile,
    transKey: 'profileSettings',
    iconName: 'user',
    innerLinks: [
      {
        href: StaticLinks.Profile,
        transKey: 'editProfile',
      },
      {
        href: StaticLinks.DeliveryAddresses,
        transKey: 'deliveryAddr',
      },
    ],
  },
  {
    href: StaticLinks.ProfileClientCard,
    transKey: 'clientCard',
    iconName: 'cardClient',
  },
  {
    href: StaticLinks.ProfileOrders,
    transKey: 'orders',
    iconName: 'shoppingBasket',
  },
];

export const b2cUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.ProfilePaymentMethodB2C,
    transKey: 'paymentMethod',
    iconName: 'cardPayment',
  },
  {
    href: StaticLinks.InvoiceRecipients,
    transKey: 'vatInvoiceRecipients',
    iconName: 'invoice',
  },
];

export const b2bUserMenuLinks: MenuLink[] = [
  {
    href: StaticLinks.ProfilePaymentMethodB2B,
    transKey: 'paymentMethod',
    iconName: 'cardPayment',
  },
  {
    href: StaticLinks.ProfileInvoices,
    transKey: 'invoicesPayment',
    iconName: 'invoice',
  },
  {
    href: StaticLinks.ProfileOtherDocuments,
    transKey: 'otherDocuments',
    iconName: 'document',
  },
  {
    href: StaticLinks.ProfilePurchaseConditions,
    transKey: 'purchaseConditions',
    iconName: 'info',
  },
  // TODO: Uncomment after implementation from api.
  // {
  //   href: StaticLinks.ProfileEmployeeManagement,
  //   transKey: 'employeeManagement',
  //   iconName: 'users',
  // },
];

export const currency = '€';

export const imageFallbackUrl = '/no_image.svg';
export const imageThumbFallbackUrl = '/thumb_no_image.svg';

export const THEMES = {
  B2B: 'b2b',
  B2C: 'b2c',
};

export const EMAIL_LENGTH = 300;

export const AUTH_COOKIE_EXP = 365;
export const DELIVERY_ADDRESS_COOKIE_EXP = 365;
export const CONSENT_COOKIE_EXP = 365;
export const COOKIE_CONSENT_KEY = 'consent_data';

export const COOKIE_NATIVE_APP_KEY = 'native_app';

export const EMAIL_VERIFICATION_LINK_EXP = 3600000; // 1 hour
export const PHONE_VERIFICATION_CODE_EXP = 120000; // 2 mins

export const APPLE_SDK_URL =
  // eslint-disable-next-line max-len
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
export const FACEBOOK_SDK_URL = 'https://connect.facebook.net/en_US/sdk.js';

export const DEFAULT_INNER_PAGE_BG_IMG =
  'https://epromo.imgix.net/cms-dev/inner_bg.png';

export const storeLocationPinIcon = '/storeMapPin.svg';

export const atLeastOneNumberRgx = /[\W]*\d+/g;
export const passwordLength = { min: 8, max: 20 };

export const MIN_USER_AGE = 18;

export const SHORT_INPUT_MAX_LENGTH = 255;
export const LONG_INPUT_MAX_LENGTH = 500;

export const COOKIE_NATIVE_APP_NOTIFICATION_KEY =
  'native_epromo_app_notification';

export const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_NO_REGEX = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export const BILLING_COMPANIES_QUERY_KEY = 'get-billing-companies';
