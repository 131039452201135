import { useRouter } from 'next/router';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

import { CategoryTree, CategoryTreeItem } from 'epromo-types/Inventory';

import { Endpoints, useGetQuery } from '../index';
import { useDeliveryAddress } from '../hooks/useDeliveryAddress';
import { flattenInventoryTree } from '../utils/slug';

export function useInitialCategories(initialEnabled = true): {
  data: CategoryTree | undefined;
  isLoading: boolean;
  flatInventoryList: CategoryTreeItem[];
  categoryId: string | undefined;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CategoryTree, Error>>;
} {
  const { asPath, locale } = useRouter();
  const { getAddressId } = useDeliveryAddress();
  const addressId = getAddressId();

  const key = ['root-categories', locale, addressId];

  const { data, isLoading, refetch } = useGetQuery<CategoryTree>({
    queryKey: key,
    endPoint: Endpoints.categoriesTree,
    refetchOnWindowFocus: false,
    staleTime: 300000, // 5 min
    enabled: initialEnabled,
  });
  const flatInventoryList = flattenInventoryTree(data?.categories || []);

  const categoryId = flatInventoryList.find((category) => {
    if (locale === 'en') {
      return category.urlSlugEn?.replace(`/${locale}`, '') === asPath;
    }

    return category.urlSlug?.replace(`/${locale}`, '') === asPath;
  })?.id;

  return {
    data,
    isLoading,
    flatInventoryList,
    categoryId,
    refetch,
  };
}
