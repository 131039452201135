import { useEffect, useState } from 'react';

import { IConfirmState } from 'ui/components/organisms/ConfirmationDialog';

export function useDeleteConfirmation() {
  const [confirmState, setConfirmState] = useState<IConfirmState>({
    isOpen: false,
  });

  useEffect(() => {
    if (!confirmState.isOpen && confirmState.remove) {
      confirmState.remove();
      setConfirmState({ isOpen: false });
    }
  }, [confirmState]);
  return { confirmState, setConfirmState };
}
