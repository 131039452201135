import { CategoryContract } from '../Inventory';

export type ProductDeliveryExceptionContract = {
  description: string;
  maxOrderTime: number;
  deliveryDate: number;
};

export interface INutritionValues {
  energyV100G: ProductDescriptionItemContract;
  energyVKj100G: ProductDescriptionItemContract;
  fattyAcids100G: ProductDescriptionItemContract;
  fat100G: ProductDescriptionItemContract;
  carbs100G: ProductDescriptionItemContract;
  sugar100G: ProductDescriptionItemContract;
  proteins100G: ProductDescriptionItemContract;
  salt100G: ProductDescriptionItemContract;
}

export interface IDescriptionValues {
  descriptionText: string | null;
  compositText?: string;
  purposeText: string;
  directionsText: string;
  alergensText: string;
  warningsText: string;
}

export interface ProductContract
  extends SlugItem,
    INutritionValues,
    IDescriptionValues {
  id: string;
  name: string;
  categories: CategoryBaseContract[];
  image: string | null;
  priceWithVat: number;
  price: number;
  basePrice: number;
  vatAmount: number;
  priceSecond: number | null;
  amountForSecondPrice: number | null;
  incrementalValue: number;
  priceCoefficient: string;
  tare: TareContract;
  packagingAmount?: number;
  minimumAmount: number;
  vat: number;
  storageType: keyof typeof StorageType;
  measureUnit: string;
  inStock: boolean;
  inStockAmount: string;
  ageToComply: number;
  logisticScheme: LogisticSchemeContract[];
  descriptionItems: ProductDescriptionItemContract[];
  amountSuggestions: AmountSuggestionContract[];
  productPrices: ProductPricingContract | null;
  isNewProduct: boolean;
  isSaleOut: boolean;
  similarProducts: ProductContract[] | null; // TODO: tbd in api
  productDeliveryException?: ProductDeliveryExceptionContract;
  productDocument?: string;
  isPlaceholder?: boolean;
  expiryInDays: number;
  producerName: string;
  originCountry: string;
  wishlistCatId?: string;
}

export type ProductCardContract = Omit<
  ProductContract,
  | 'descriptionText'
  | 'categories'
  | 'priceWithVat'
  | 'vatAmount'
  | 'priceSecond'
  | 'amountForSecondPrice'
  | 'vat'
  | 'ageToComply'
  | 'logisticScheme'
  | 'amountSuggestions'
  | 'isNewProduct'
  | 'similarProducts'
  | 'productDocument'
>;

export interface SearchResponseContract {
  products: ProductContract[];
  similarProducts: ProductContract[];
  categories: CategoryContract[];
  filters: FilterContract[];
  currentPage: number;
  pageCount: number;
  resultsCount: number;
}

export interface FilterContract {
  id: string;
  name: string;
  values: { value: string; count: number }[];
}

export interface SlugItem {
  urlSlug: string | null;
  urlSlugEn: string | null;
}

export interface CategoryBaseContract extends SlugItem {
  id: string;
  name: string;
}

export type ProductPricingContract = {
  quantityPriceOptions: QuantityPriceOptionContract[] | null;
  basePriceOption: BasePriceOptionContract | null;
  bonusOption: BonusOptionContract[] | null;
};

export type BonusOptionContract = {
  description: string;
  promoId: string;
  productAmount: number;
  minQuantity: MinQuantityContract;
  productId: string;
  multiple: boolean;
};

export type BasePriceOptionContract = {
  description: string;
  promoId: string;
  price: number;
};

export type QuantityPriceOptionContract = {
  description: string;
  promoId: string;
  price: number;
  minQuantity: MinQuantityContract;
};

export type MinQuantityContract = {
  minQuantity: number;
  quantityType: keyof typeof QuantityType;
};

export type AmountSuggestionContract = {
  name: string;
  amount: number;
};

export type ProductDescriptionItemContract = {
  id: string;
  title: string | null;
  description: string | null;
};

export type TareContract = {
  tarePrice: number;
  tareAmount: number;
};

export type LogisticSchemeContract = {
  transportGroup: keyof typeof TransportGroup;
  transportGroupString: string;
  region: string;
  warehouse: string;
};

export enum StorageType {
  regular = 'regular',
  termo = 'termo',
  frozen = 'frozen',
}

export enum TransportGroup {
  default = 'default',
  termo = 'termo',
  frozen = 'frozen',
  tabacco = 'tabacco',
}

export enum QuantityType {
  amount = 'amount',
  price = 'price',
}

export type ProductDisplay = 'wishlist' | 'template' | 'card' | 'details';

export interface ProductDisplayVariant {
  variant?: ProductDisplay;
}
