// @ts-nocheck
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

// prettier-ignore
export const loadIntercom = () => {
  // eslint-disable-next-line max-len
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + publicRuntimeConfig.intercomAppId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

export const bootIntercom = (options = {}) => {
  window &&
    window.Intercom &&
    // eslint-disable-next-line max-len
    window.Intercom('boot', {
      app_id: publicRuntimeConfig.intercomAppId,
      ...options,
    });
};

export const updateIntercom = () => {
  window && window.Intercom && window.Intercom('update');
};
