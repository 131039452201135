import { ReactElement } from 'react';
import { clsx } from 'clsx';

import { Button, ButtonProps } from '../Button';

export function CookieSettingsButton({
  children,
  ...rest
}: ButtonProps): ReactElement {
  return (
    <Button
      {...rest}
      className={clsx(
        'border border-neutral-100 bg-transparent text-neutral-100',
        'hover:bg-neutral-900',
        'outline-secondary-500 focus:bg-neutral-900 focus:outline-2'
      )}
    >
      {children}
    </Button>
  );
}
