import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { THEMES } from 'epromo-lib/constants';

import { Button } from '@components/atoms/Button';
import { CookieSettingsButton } from '@components/atoms/CookieSettingsButton';
import { Icon } from '@components/atoms/Icon';

import { CookieDialogTabId } from '@components/organisms/CookieConsent';

export type CookieConsentFooterProps = {
  openCookiePolicyDialog: (tabId?: CookieDialogTabId) => void;
  onSubmitAll: () => void;
  onSubmitEssential: () => void;
  isOpen?: boolean;
};

export function CookieConsentFooter({
  openCookiePolicyDialog,
  onSubmitAll,
  onSubmitEssential,
  isOpen = false,
}: CookieConsentFooterProps) {
  const { t } = useTranslation('common');
  return (
    <div
      className={twMerge(
        'bg-secondary-500 bottom-0',
        'text-secondary-500 flex gap-4 px-4 py-4',
        'text-sm font-normal md:px-16',
        'z-50 flex-col md:w-full md:flex-row',
        isOpen ? 'fixed' : 'hidden'
      )}
    >
      <div className="text-secondary-100 flex flex-col gap-4 md:grow">
        <h2
          className={twMerge(' flex items-center', 'gap-2 text-xl font-black')}
        >
          {t('cookiesPomptTitle')}
          <Icon name="cookie" className="fill-business-500 shrink-0" />
        </h2>
        <div className="text-sm font-normal">
          <p className="whitespace-pre-wrap">
            {t('cookiesReq')}{' '}
            <button
              type="button"
              className="font-bold"
              onClick={() => openCookiePolicyDialog('settings')}
            >
              {t('cookiesSettings')}
            </button>
            .
          </p>
          <p>
            {t('readAboutCookies')}:{' '}
            <button
              type="button"
              className="font-bold"
              onClick={() => openCookiePolicyDialog('policy')}
            >
              {t('cookiePolicy')}
            </button>
            .
          </p>
        </div>
      </div>
      <div
        className="flex flex-col gap-4 whitespace-nowrap"
        data-theme={THEMES.B2B}
      >
        <Button fluid onClick={onSubmitAll}>
          {t('acceptAllCookies')}
        </Button>
        <Button fluid onClick={onSubmitEssential}>
          {t('acceptNecessaryCookies')}
        </Button>
        <CookieSettingsButton
          fluid
          onClick={() => openCookiePolicyDialog('settings')}
        >
          {t('cookiesSettings')}
        </CookieSettingsButton>
      </div>
    </div>
  );
}

CookieConsentFooter.displayName = 'CookieConsentFooter';
