import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  AddToWishlistItem,
  CreateShoppingCartTemplateContract,
  ProductContract,
  ShoppingCartBulkItemsContract,
  ShoppingCartTemplateContract,
} from 'epromo-types';
import { useDeliveryAddress, useToaster } from 'epromo-lib/hooks';
import { WishlistPaginationContract } from 'epromo-types/Inventory';

import {
  Endpoints,
  handleError,
  resolveHeaders,
  useGetQuery,
  useMutationQuery,
} from '../index';
import { useFavoritesStateTrackedStore } from '../store';

import { getErrorMessage } from '../utils/error';
import { getTokenFromCookie } from '../utils/token';
import { fetcher } from '../mutations';

const { publicRuntimeConfig } = getConfig();

export const GET_WISHLIST_KEY = 'user-wishlist';

export const FILTER_WISHLIST_KEY = 'filter-wishlist-products';

export const GET_TEMPLATES_KEY = 'user-cart-temlates';

export const TOGGLE_WISHLIST_PRODUCT = 'toggle-wishlist-product';

export const useGetFullWishlist = () => {
  const { setWishlist } = useFavoritesStateTrackedStore();

  const { data, refetch, isFetchedAfterMount } = useGetQuery<ProductContract[]>(
    {
      queryKey: [GET_WISHLIST_KEY],
      endPoint: `${Endpoints.wishlist}/products`,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (data && isFetchedAfterMount) {
      setWishlist(data);
    }
  }, [data, isFetchedAfterMount]);

  return {
    fetchFullWishlist: refetch,
  };
};

export const useGetTemplates = () => {
  const { setShoppingCartTemplates } = useFavoritesStateTrackedStore();

  const cartTemplatesQuery = useGetQuery<ShoppingCartTemplateContract[]>({
    queryKey: [GET_TEMPLATES_KEY],
    endPoint: Endpoints.wishlist,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (!!cartTemplatesQuery.data?.length) {
      setShoppingCartTemplates(cartTemplatesQuery.data);
    }
  }, [cartTemplatesQuery.data, setShoppingCartTemplates]);

  return { fetchTemplates: cartTemplatesQuery.refetch, cartTemplatesQuery };
};

export function useFavorites(templateId?: string | undefined | null) {
  const router = useRouter();
  const { toast } = useToaster();
  const queryClient = useQueryClient();
  const [isTogglingWishlist, setIsTogglingWishlist] = useState(false);
  const [isRemovingFromTemplate, setIsRemovingFromTemplate] = useState(false);
  const [removeFromTemplateError, setRemoveFromTemplateError] = useState('');
  const {
    setWishlist,
    setShoppingCartTemplates,
    setNewCartTemplate,
    setUpdatedCartTemplate,
    shoppingCartTemplates,
    getShoppingCartTemplates,
    productsWishlistMap,
    addToWishlist,
    removeFromWishlist,
    setIsDeletingWishlistItem,
    isDeletingWishlistValue,
  } = useFavoritesStateTrackedStore();

  const { getAddressId } = useDeliveryAddress();

  const {
    mutate: addToTemplate,
    isPending: isAddingToTemplate,
    error: addTemplateError,
  } = useMutationQuery<ShoppingCartTemplateContract, AddToWishlistItem>({
    mutationKey: [`create-cart-template`],
    endPoint: `${Endpoints.wishlist}/${templateId}/item`,
    onSuccess(data) {
      if (data) {
        setNewCartTemplate(data);
        queryClient.invalidateQueries({
          queryKey: [GET_TEMPLATES_KEY],
          exact: true,
        });
      }
    },
  });

  const {
    mutate: addBulkToTemplate,
    isPending: isBulkAddingToTemplate,
    error: addBulkTemplateError,
  } = useMutationQuery<
    ShoppingCartTemplateContract,
    ShoppingCartBulkItemsContract
  >({
    mutationKey: ['add-products-to-template'],
    endPoint: `${Endpoints.wishlist}/${templateId}/items`,
    onSuccess(data) {
      if (data) {
        setNewCartTemplate(data);
        queryClient.invalidateQueries({
          queryKey: [GET_TEMPLATES_KEY],
          exact: true,
        });
      }
    },
  });

  const {
    mutate: createEmptyTemplate,
    isPending: isCreatingTemplate,
    error: creatingTemplateError,
  } = useMutationQuery<
    ShoppingCartTemplateContract,
    CreateShoppingCartTemplateContract
  >({
    mutationKey: ['create-cart-template'],
    endPoint: `${Endpoints.wishlist}`,
    onSuccess(data) {
      if (data) {
        setNewCartTemplate(data);
        queryClient.invalidateQueries({
          queryKey: [GET_TEMPLATES_KEY],
          exact: true,
        });
      }
    },
  });

  async function removeFromCartTemplate(templateId: string, id: string) {
    setRemoveFromTemplateError('');
    setIsRemovingFromTemplate(true);
    try {
      const url = `${publicRuntimeConfig.apiUrl}`;
      const response = await axios.delete<ShoppingCartTemplateContract>(
        `${url}/${Endpoints.wishlist}/${templateId}/item?id=${id}`,
        {
          headers: resolveHeaders(
            getTokenFromCookie(),
            router.locale,
            getAddressId()
          ),
        }
      );
      if (response.data) {
        setNewCartTemplate(response.data);
        queryClient.invalidateQueries({
          queryKey: [GET_TEMPLATES_KEY],
          exact: true,
        });
      }
    } catch (error) {
      setRemoveFromTemplateError(getErrorMessage(error));
    } finally {
      setIsRemovingFromTemplate(false);
    }
  }

  async function removeCartTemplate(templateId: string) {
    try {
      const url = `${publicRuntimeConfig.apiUrl}`;
      const response = await axios.delete<ShoppingCartTemplateContract[]>(
        `${url}/${Endpoints.wishlist}/${templateId}`,
        {
          headers: resolveHeaders(
            getTokenFromCookie(),
            router.locale,
            getAddressId()
          ),
        }
      );
      if (response.data) {
        setShoppingCartTemplates(response.data);
        queryClient.invalidateQueries({
          queryKey: [GET_TEMPLATES_KEY],
          exact: true,
        });
      }
    } catch (error) {
      handleError({ err: error, toast, router });
    }
  }

  const { mutateAsync: toggleProductInWishlist } = useMutation<
    AxiosResponse,
    AxiosError,
    ProductContract
  >({
    mutationKey: [TOGGLE_WISHLIST_PRODUCT],
    mutationFn: (product: ProductContract) => {
      const wasInList = !!productsWishlistMap[product.id];
      if (!wasInList) {
        addToWishlist(product);
      } else {
        setIsDeletingWishlistItem(true);
        removeFromWishlist(product);
      }
      setIsTogglingWishlist(true);
      return fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.wishlist}/products/${product.id}`,
          method: wasInList ? 'delete' : 'post',
        },
        router.locale
      );
    },
    onMutate: (product) => {
      const queryKey = [
        FILTER_WISHLIST_KEY,
        getAddressId(),
        router.locale,
        product.wishlistCatId || '',
      ];
      queryClient.setQueryData(
        queryKey,
        (data: InfiniteData<WishlistPaginationContract>) => {
          return {
            ...data,
            pages: data?.pages?.map((page) => {
              if (page.products.find((pr) => pr.id === product.id)) {
                return {
                  ...page,
                  products: page.products.filter((pr) => pr.id !== product.id),
                };
              }
              return page;
            }),
          };
        }
      );
      if (!router.asPath.toLowerCase().includes('profile/favourites')) {
        queryClient.invalidateQueries({ queryKey: queryKey });
      }
    },
    onSuccess: ({ data }) => {
      setWishlist(data);
      setIsDeletingWishlistItem(false);
    },
    onSettled: () => {
      setIsTogglingWishlist(false);
    },
    onError: (err, product) => {
      handleError({ err, toast, router });
      const wasInList = !!productsWishlistMap[product.id];
      if (wasInList) {
        removeFromWishlist(product);
      } else {
        addToWishlist(product);
      }
    },
  });

  return {
    setWishlist,
    setShoppingCartTemplates,
    setNewCartTemplate,
    setUpdatedCartTemplate,
    shoppingCartTemplates,
    getShoppingCartTemplates,
    addToTemplate,
    removeFromTemplate: removeFromCartTemplate,
    removeFromTemplateError,
    isRemovingFromTemplate,
    isAddingToTemplate,
    toggleProductInWishlist,
    isTogglingWishlist,
    removeCartTemplate,
    createEmptyTemplate,
    isCreatingTemplate,
    creatingTemplateError,
    addBulkToTemplate,
    isBulkAddingToTemplate,
    productsWishlistMap,
    isDeletingWishlistValue,
  };
}
