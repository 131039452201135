import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  DefaultError,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import getConfig from 'next/config';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import {
  ApiError,
  AuthenticateJWTResponse,
  AuthenticateWithToken,
} from 'epromo-types';

import {
  getAuthCookieExp,
  ToasterProps,
  useDeliveryAddress,
} from 'epromo-lib/hooks';
import { useToaster } from 'epromo-lib/hooks/useToaster';

import { clearUserData, useUserProfile } from './store';
import { useUserSignIn } from './store/auth/user-signin';
import { getErrorMessage } from './utils/error';
import { loadScript } from './utils/loadScript';
import { getTokenFromCookie, setTokenToCookie } from './utils/token';
import { useRedirect } from './hooks';
import { StaticLinks } from './constants';
import { isObject } from './utils/helpers';
import { checkIfEstonianDomain } from './utils/language';

import StatusResponse = facebook.StatusResponse;
import CredentialResponse = google.accounts.id.CredentialResponse;

const { publicRuntimeConfig } = getConfig();

export enum Endpoints {
  categories = 'inventory/categories',
  categoriesTree = 'v2/web_inventory/categoriesTree',
  productBySlug = 'v2/web_inventory/productSlug',
  categoryBySlug = 'v2/web_inventory/categorySlug',
  category = 'v2/web_inventory/category',
  getCardData = 'v2/users/getCardData',
  profile = 'v2/users/profile',
  linkCard = 'users/linkCard/',
  unLinkCard = 'users/unLinkCard/',
  applyForNewCard = 'v2/loyalty/applyForNewCard',
  registerUser = 'v2/users',
  initiateDelete = 'v2/users/delete',
  verifyDelete = 'v2/users/verifyDelete',
  updateUser = 'users/update',
  countryCodes = 'address/countryCodes',
  address = 'v2/address',
  verifyNumber = 'v2/users/verifyNumber',
  signForBusinessAccount = 'v2/users/signForBusinessAccount',
  b2cCompanies = 'b2cCompanies',
  createB2cCompany = 'b2cCompanies/create',
  addDeliveryAddress = 'v2/address',
  resendUserPhoneVerification = 'v2/users/resendUserPhoneVerification',
  resendUserEmailVerification = 'v2/users/resendEmailVerification',
  resetPassword = 'users/forgotPassword',
  restorePassword = 'v2/users/restorePassword',
  updatePassword = 'users/updatePassword',
  searchCompany = 'companies/search',
  authenticateFbJWT = 'users/AuthenticateFacebook',
  authenticateAppleJWT = 'users/AuthenticateApple',
  authenticateGoogleJWT = 'users/AuthenticateGoogleJWT',
  changeNumber = 'v2/users/changeNumber',
  addressList = 'v2/address',
  searchProducts = 'v2/web_inventory/search',
  wishlist = 'wishlist',
  wishlistV21 = 'v2.1/wishlist',
  shoppingCartTemplate = 'shoppingCartTemplate',
  orders = 'v2/orders',
  webOrder = 'v2/web_order',
  more = 'v2/more',
  morePurchaseConditions = 'v2/more/purchaseConditions',
  b2bRemoveProductFromCart = 'v2/shoppingCart/deleteItems',
  b2bUpdateProductInCart = 'v2/shoppingCart/update',
  b2cUpdateProductInCart = 'shoppingCart',
  b2bCheckoutSummaryV2 = 'v2.1/Orders/b2b/GetCheckoutSummary',
  b2cCheckoutSummaryV2 = 'v2.1/Orders/b2c/GetCheckoutSummary',
  billingUsers = 'b2cCompanies/all',
  updateInvoiceNumbers = 'v2/shoppingCart/updateInvoiceNumbers',
  b2bSetCartDeliveryDate = 'v2/shoppingCart/setDates',
  b2cSetDeliverySlotReservation = 'v2/address',
  b2cOrderCreate = 'orders/create',
  b2bOrderCreate = 'v2/orders/create',
  b2bOrderPayWithCredit = 'v2/orders/pay',
  b2cCartConfirm = 'shoppingCart/confirm',
  b2bCartConfirm = 'v2/shoppingCart/confirm',
  smartId = 'smartId',
  smartIdStatus = 'smartId/Status',
  news = 'news',
  config = 'versions/Config',
  payment = 'payment',
  paymentV2 = 'v2/payment',
  paymentSeb = 'payment/seb',
  sebInfo = 'seb/info',
  invoices = 'v2/invoices',
  otherDocuments = 'v2/invoices/all',
  invoiceDownload = 'v2/invoices/download',
  employees = 'v2/employees',
  companyDetails = 'companies',
  verifyAddressNumber = 'v2/Address/VerifyNumber',
  changeAddressNumber = 'v2/Address/changeNumber',
  verifyEmailVerification = 'v2/users/verifyEmailVerification',
  replaceCartItem = 'v2/ShoppingCart/replace',
  inventoryBreadCrumb = 'inventory/categoryBreadcrumb',
}

type HandleErrorProps = {
  toast?: ({
    type,
    message,
    hideProgressBar,
    position,
  }: ToasterProps) => void | undefined;
  onError?: (err: unknown) => void;
  err: unknown;
  router: NextRouter;
};

type ApiErrors = {
  errors: Record<string, string[]>;
};

export const handleError = ({ err, toast, onError }: HandleErrorProps) => {
  if (
    err instanceof AxiosError &&
    (err as AxiosError).response?.status === 401
  ) {
    const isChangingPwd = (
      (err as AxiosError).response?.request.responseURL as string
    ).includes('updatePassword');
    const isFbLogin = (
      (err as AxiosError).response?.request.responseURL as string
    ).includes('AuthenticateFacebook');

    if (isChangingPwd || isFbLogin) return;

    clearUserData();
    typeof window !== 'undefined' && window.location.assign(StaticLinks.Home);
  } else if (err instanceof AxiosError) {
    const axError = err as AxiosError;

    console.log('axError', axError);

    //@ts-ignore
    if (axError?.response?.data.errors) {
      const apiErrors = axError?.response?.data as ApiErrors;
      const firstErrorKey = Object.keys(apiErrors.errors).shift();
      if (firstErrorKey && apiErrors.errors[firstErrorKey]) {
        const errorMessage = (apiErrors.errors[firstErrorKey] || []).shift();
        if (errorMessage) {
          toast?.({
            message: errorMessage,
            type: 'error',
            toastId: errorMessage,
          });
          onError?.(err);
          return;
        }
      }
    }
    toast?.({
      message: getErrorMessage(err),
      type: 'error',
      toastId: getErrorMessage(err),
    });
    onError?.(err);
  } else {
    toast?.({
      message: getErrorMessage(err),
      type: 'error',
      toastId: getErrorMessage(err),
    });
    onError?.(err);
  }
};

export function resolveHeaders(
  token: string | null = null,
  currentLocale = 'lt',
  addressId: string | null = null
) {
  let Languages = currentLocale;
  if (currentLocale !== 'en' && checkIfEstonianDomain()) {
    Languages = 'et';
  }
  return {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...(token && addressId ? { AddressId: addressId } : {}),
    Languages,
    'App-Version': publicRuntimeConfig.apiVersion,
  };
}

const buildQueryString = (queryKey: QueryKey, qs?: Record<string, string>) => {
  const [, qsKey] = queryKey;
  const qsObj = isObject(qs)
    ? {
        ...qs,
      }
    : {};
  const qsMerged = isObject(qsKey)
    ? {
        ...qsObj,
        ...(qsKey as Object),
      }
    : { ...qsObj };

  return Object.keys(qsMerged).length > 0
    ? `?${new URLSearchParams(qsMerged as URLSearchParams).toString()}`
    : '';
};

interface QueryOptions<TData, TSelectedData = TData, TError = DefaultError>
  extends UseQueryOptions<TData, TError, TSelectedData> {
  endPoint: string;
  toastErrorGlobally?: boolean;
  onError?: ((err: any) => void) | undefined;
  qs?: Record<string, string>;
}

export const useGetQuery = <
  TData,
  TSelectedData = TData,
  TError = DefaultError,
>(
  queryOptions: QueryOptions<TData, TSelectedData, TError>,
  axiosConfig?: AxiosRequestConfig,
  sideEffect?: (data: TData) => void
) => {
  const router = useRouter();
  const { getAddressId } = useDeliveryAddress();
  const { toast } = useToaster();

  const { endPoint, toastErrorGlobally, qs, onError, ...rest } = queryOptions;

  const { error, ...restQuery } = useQuery<TData, TError, TSelectedData>({
    ...rest,
    queryFn: async ({ queryKey }) => {
      const queryString = buildQueryString(queryKey, qs);

      let res: AxiosResponse<any, any> | undefined;

      try {
        res = await axios.request({
          url: `${publicRuntimeConfig.apiUrl}/${endPoint}${queryString}`,
          headers: resolveHeaders(
            getTokenFromCookie(),
            router.locale,
            getAddressId()
          ),
          ...axiosConfig,
        });

        sideEffect?.(res?.data);
      } catch (error) {
        handleError({
          err: error,
          onError,
          router,
          toast: toastErrorGlobally ? toast : undefined,
        });
      }

      return res?.data;
    },
  });

  return {
    ...restQuery,
    error: error
      ? ((error as unknown as AxiosError).response?.data as ApiError)
      : null,
  };
};

interface MutationOptions<TData, TVariables = void, TError = DefaultError>
  extends UseMutationOptions<TData, TError, TVariables> {
  endPoint: string;
  toastErrorGlobally?: boolean;
  onError?: ((err: any) => void) | undefined;
}

export const useMutationQuery = <
  TData,
  TVariables = void,
  TError = DefaultError,
>(
  mutationOptions: MutationOptions<TData, TVariables, TError>,
  axiosConfig?: AxiosRequestConfig
): UseMutationResult<TData, ApiError, TVariables, unknown> => {
  const { toast } = useToaster();
  const router = useRouter();
  const { getAddressId } = useDeliveryAddress();

  const { endPoint, onError, toastErrorGlobally, ...rest } = mutationOptions;

  const { error, ...restMutation } = useMutation<TData, TError, TVariables>({
    ...rest,
    mutationFn: async (payload) => {
      const res = await axios.request({
        url: `${publicRuntimeConfig.apiUrl}/${endPoint}`,
        method: 'post',
        data: payload,
        headers: resolveHeaders(
          getTokenFromCookie(),
          router.locale,
          getAddressId()
        ),
        ...axiosConfig,
      });

      return res?.data;
    },
    onError: (error) => {
      handleError({
        err: error,
        onError,
        router,
        toast: toastErrorGlobally ? toast : undefined,
      });
    },
  });
  return {
    ...restMutation,
    error: error
      ? ((error as unknown as AxiosError).response?.data as ApiError)
      : null,
  } as UseMutationResult<TData, ApiError, TVariables, unknown>;
};

type ExternalLoginProps = {
  onSuccess?: (data: AuthenticateJWTResponse) => void | null;
  onError?: (err: string) => void | null;
  redirectToUrlAfterLogin?: string | null;
};

const usePostExternalLogin = ({
  redirectToUrlAfterLogin,
  onSuccess,
}: ExternalLoginProps) => {
  const { setUserProfile } = useUserProfile();
  const { rememberMe } = useUserSignIn();
  const { toHomePage, toSignUpPage, toPage } = useRedirect();
  const handleSuccess = (response: AuthenticateJWTResponse) => {
    if (response.token && response.userProfile) {
      setTokenToCookie(
        response.token,
        getAuthCookieExp(response.token, rememberMe)
      );
      setUserProfile(response.userProfile);
      if (redirectToUrlAfterLogin) {
        toPage(redirectToUrlAfterLogin);
      } else {
        toHomePage();
      }
    } else if (!response.token && response.userProfile) {
      //mean user not exists
      if (onSuccess) {
        onSuccess(response);
      } else {
        toSignUpPage();
      }
    } else {
      if (redirectToUrlAfterLogin) {
        toPage(redirectToUrlAfterLogin);
      } else if (onSuccess) {
        onSuccess(response);
      } else {
        toSignUpPage();
      }
    }
  };
  return {
    handleSuccess,
  };
};

export function useFacebookLogin({
  onSuccess,
  onError,
  redirectToUrlAfterLogin,
}: ExternalLoginProps = {}) {
  const { handleSuccess } = usePostExternalLogin({
    redirectToUrlAfterLogin,
  });
  const { mutate, isPending, error } = useMutationQuery<
    AuthenticateJWTResponse,
    AuthenticateWithToken
  >({
    endPoint: Endpoints.authenticateFbJWT,
    mutationKey: ['authenticate-fb'],
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      } else {
        handleSuccess(response);
      }
    },
    onError: (response) => {
      onError?.(getErrorMessage(response));
    },
  });

  const loginCb = (response: StatusResponse) => {
    if (response.authResponse) {
      mutate({ accessToken: response.authResponse.accessToken ?? '' });
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  const handleLogin = () => {
    FB.getLoginStatus((response) => {
      const { authResponse, status } = response;
      if (status === 'connected') {
        mutate({ accessToken: authResponse.accessToken ?? '' });
      } else {
        FB.login(loginCb);
      }
    });
  };
  return {
    handleLogin,
    isPending,
    error,
  };
}

export function useAppleLogin({
  onSuccess,
  onError,
  redirectToUrlAfterLogin,
}: ExternalLoginProps = {}) {
  const [appleResponse, setAppleResponse] =
    useState<AppleSignInAPI.SignInResponseI>();
  const { handleSuccess } = usePostExternalLogin({
    redirectToUrlAfterLogin,
  });
  const { mutate, isPending, error } = useMutationQuery<
    AuthenticateJWTResponse,
    AuthenticateWithToken
  >({
    endPoint: Endpoints.authenticateAppleJWT,
    mutationKey: ['authenticate-apple'],
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      } else {
        handleSuccess(response);
      }
    },
    onError: (response) => {
      onError?.(getErrorMessage(response));
    },
  });

  const loginCb = (response: AppleSignInAPI.SignInResponseI) => {
    if (response.authorization) {
      setAppleResponse(response);
      mutate({ accessToken: response.authorization.id_token });
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  const handleLogin = async () => {
    try {
      const response = await AppleID.auth.signIn();
      loginCb(response);
    } catch (err) {
      console.error(getErrorMessage(err));
    }
  };

  return {
    handleLogin,
    isPending,
    error,
  };
}

export function useGoogleLogin({
  onSuccess,
  onError,
  redirectToUrlAfterLogin,
}: ExternalLoginProps = {}) {
  const clientRef = useRef(null);
  const { locale } = useRouter();

  const { handleSuccess } = usePostExternalLogin({
    redirectToUrlAfterLogin,
  });
  const { mutate, isPending, error } = useMutationQuery<
    AuthenticateJWTResponse,
    AuthenticateWithToken
  >({
    endPoint: Endpoints.authenticateGoogleJWT,
    mutationKey: ['authenticate-google'],
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      } else {
        handleSuccess(response);
      }
    },
    onError: (response) => {
      onError?.(getErrorMessage(response));
    },
  });

  function handleCredentialResponse(response: CredentialResponse) {
    if (response.credential) {
      mutate({ accessToken: response.credential });
    }
  }

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';

    loadScript(src)
      .then(() => {
        google.accounts.id.initialize({
          client_id: publicRuntimeConfig.googleId,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById('gSignInBtn'),
          {
            type: 'icon',
            theme: 'outline',
            size: 'large',
            shape: 'rectangular',
            logo_alignment: 'center',
          }
        );

        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById('gSignInBtnWithText'),
          {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            shape: 'pill',
            logo_alignment: 'center',
            dataText: 'continue_with',
            locale,
            width: 230,
          }
        );
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [locale]);

  const handleLogin = () => {
    if (clientRef) {
      // @ts-ignore
      clientRef?.current?.requestAccessToken();
    }
  };
  return {
    handleLogin,
    isPending,
    error,
  };
}

