import { IncomingMessage } from 'http';
import cookie from 'js-cookie';
import { ICmsCookieConsent } from 'epromo-lib-cms/types/payload-types';

const secure = false; //process.env.

export const setCookie = (
  key: string,
  value: string | Record<string, undefined>,
  expires?: number | Date | undefined
): void => {
  if (process.browser) {
    cookie.set(key, value, {
      expires,
      path: '/',
      secure,
    });
  }
};

export const removeCookie = (key: string): void => {
  if (process.browser) {
    cookie.remove(key);
  }
};

const getCookieFromBrowser = (key: string) => cookie.get(key);

const getCookieFromServer = (key: string, req?: IncomingMessage) => {
  if (!req) {
    return undefined;
  }
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c: string) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const getCookie = (
  key: string,
  req?: IncomingMessage
): string | undefined =>
  process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);

export function groupCookieConsent(cookieConsent: ICmsCookieConsent) {
  if (!cookieConsent) {
    return [];
  }
  const groups = Object.entries(cookieConsent.cookiesettings.groups || []).map(
    ([key, group], i) => ({ ...group, id: key })
  );
  return groups;
}
