import { useEffect, useState } from 'react';

import { SortItem, SortOptions } from 'ui/components/molecules/SortingPopOver';

import { userProfileSelector, useUserProfile } from '../store';

export function useSortOptions() {
  const userProfile = useUserProfile(userProfileSelector.userProfile);
  const isLoggedIn = !!userProfile;
  const [sortOptions, setSortOptions] = useState<SortItem[]>([]);
  useEffect(() => {
    if (isLoggedIn) {
      setSortOptions([...SortOptions]);
    } else {
      setSortOptions(SortOptions.filter((sO) => sO.type !== 'price'));
    }
  }, [isLoggedIn]);
  return {
    sortOptions,
  };
}
