import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useInfiniteQuery, InfiniteData } from '@tanstack/react-query';

import {
  CategoryTreeItem,
  SearchResponseContract,
} from 'epromo-types/Inventory';
import { useDeliveryAddress, useToaster } from 'epromo-lib/hooks';
import { ProductContract } from 'epromo-types';

import { handleError } from '../index';
import { getTokenFromCookie } from '../utils/token';
import { searchProducts } from '../Inventory';
import { mapLocalFiltersToReq, useLocalFilters } from '../store';
import { StaticLinks } from '../constants';

export const getSearchTerm = (router: NextRouter) => {
  return `${router.query?.term ?? ''}`;
};

export function isSearchPage(routerPath?: string) {
  return (
    routerPath && routerPath.toLowerCase().indexOf(StaticLinks.Search) >= 0
  );
}

export function useSearchProducts(
  term: string | undefined,
  enabled: boolean | undefined
) {
  const router = useRouter();
  const pageSize = isSearchPage(router.asPath) ? 100 : 5;
  const { getAddressId } = useDeliveryAddress();
  const [categories, setCategories] = useState<CategoryTreeItem[]>([]);
  const { setLocalFilters, selectedLocalFilters } = useLocalFilters();
  const { toast } = useToaster();
  const {
    data,
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
    refetch,
  } = useInfiniteQuery({
    queryKey: [
      'search-products',
      {
        search: term || getSearchTerm(router),
        count: pageSize,
        page: 0,
      },
      selectedLocalFilters,
      router.locale,
    ],
    enabled,
    queryFn: async ({ pageParam = 1 }) => {
      const searchTerm = term || getSearchTerm(router);
      if (!searchTerm) {
        return Promise.resolve<SearchResponseContract>({
          categories: [],
          currentPage: 1,
          pageCount: 0,
          products: [],
          filters: [],
          similarProducts: [],
          resultsCount: 0,
        });
      }

      let res: SearchResponseContract | undefined;

      try {
        res = await searchProducts({
          page: pageParam,
          term: searchTerm,
          pageSize,
          locale: router.locale,
          addressId: getAddressId(),
          token: getTokenFromCookie(),
          selectedLocalFilters,
        });
      } catch (error) {
        handleError({
          err: error,
          router,
          toast,
        });
      }

      return res;
    },

    select: (data) => {
      const placeholderCards = Array.from({ length: 30 }, (_, index) => ({
        id: `placeholder_${index}`,
        isPlaceholder: true,
      })) as ProductContract[];

      const placeholderPageRes = { products: placeholderCards };

      const dataWithPlaceholderItems = {
        ...data,
        pages: [
          ...data.pages.map((page) => ({
            ...page,
            products: page?.products.map((product) => ({
              ...product,
            })),
          })),
          placeholderPageRes,
        ],
      } as InfiniteData<SearchResponseContract | undefined, number>;

      return dataWithPlaceholderItems;
    },
    initialPageParam: 1,
    retry: 0,
    refetchOnWindowFocus: false,
    getPreviousPageParam: (firstPage) => firstPage?.currentPage ?? undefined,
    getNextPageParam: (lastPage) => {
      if (!lastPage) {
        return undefined;
      }
      if (lastPage.currentPage >= lastPage.pageCount) {
        return undefined;
      }
      return lastPage.currentPage + 1;
    },
  });

  const onFetchSuccess = (
    result?: InfiniteData<SearchResponseContract | undefined>
  ) => {
    if (!result) {
      return;
    }
    const [lastPage] = result.pages.slice(result.pages.length - 2);
    setCategories(
      !lastPage
        ? []
        : lastPage?.categories?.map((cat) => ({
            childCategories: [],
            parent: '',
            productCount: cat.productCount,
            icon: cat.icon,
            webIcon: cat.webIcon,
            headerImage: cat.headerImage,
            id: cat.id,
            name: cat.name,
            level: 2,
            urlSlugEn: cat.urlSlugEn,
            urlSlug: cat.urlSlug,
          }))
    );
    setLocalFilters(
      mapLocalFiltersToReq(lastPage?.filters || [], selectedLocalFilters)
    );
  };

  useEffect(() => {
    onFetchSuccess(data);
  }, [data]);

  return {
    isLoading: isFetching,
    isFirstLoad: isLoading,
    fetchNextPage,
    data,
    hasNextPage,
    isFetchingNextPage,
    categories,
    isFetched,
    refetch,
  };
}
