import { IncomingMessage } from 'http';

import { getCookie, removeCookie, setCookie } from '../utils/cookie';

export const COOKIE_DELIVERY_ADDRESS = 'DeliveryAddress';

export const removeAddressId = () => {
  removeCookie(COOKIE_DELIVERY_ADDRESS);
};

export const getAddressId = (req?: IncomingMessage) => {
  return getCookie(COOKIE_DELIVERY_ADDRESS, req);
};

export const getRawAddressId = (req?: IncomingMessage) => {
  return `${getAddressId(req)}`.replace('CashNCarry|', '');
};

/**
 * IMPORTANT! Do not bloat useAuth store because it is persisted
 * inside a cookie. Cookie limit is 4096 bytes.
 */
export function useDeliveryAddress() {
  const setAddressId = (addressId: string) => {
    setCookie(COOKIE_DELIVERY_ADDRESS, addressId);
  };

  return {
    setAddressId,
    getAddressId,
    removeAddressId,
  };
}
