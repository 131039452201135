import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';

import { StaticLinks } from '../constants';
import { useInternalLinks } from './useInternalLinks';

export const useIsPathAllowed = (allowedPaths?: string[]) => {
  const searchParams = useSearchParams();
  const { asPath, pathname } = useRouter();
  const { privacyPolicyUrlSlug, termsAndConditionsUrlSlug } =
    useInternalLinks(true);

  const defaultAllowedPaths = [
    StaticLinks.Home,
    StaticLinks.NoLoyaltyCard,
    StaticLinks.ProfileClientCard,
    StaticLinks.Login,
    StaticLinks.Register,
    StaticLinks.RegisterB2CCard,
    StaticLinks.RegisterNoCard,
    StaticLinks.RegisterWithCard,
    StaticLinks.ConfirmEmail,
    privacyPolicyUrlSlug,
    termsAndConditionsUrlSlug,
  ];

  const isConfirmEmailPath = pathname === StaticLinks.ConfirmEmail;
  const hasConfirmParams = searchParams.has('confirmState');

  const useOnlyPathname = isConfirmEmailPath || hasConfirmParams;

  if (!allowedPaths || !allowedPaths.length) {
    return defaultAllowedPaths.includes(useOnlyPathname ? pathname : asPath);
  }

  return allowedPaths.includes(asPath);
};

export default useIsPathAllowed;
