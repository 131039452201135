import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SortItem } from 'ui/components/molecules/SortingPopOver';

interface SortingData {
  selectedSort?: SortItem | null;
}

export interface SortingState extends SortingData {
  setSortingMethod: (data?: SortItem | null) => void;
  reset: () => void;
}

const initialState: SortingData = {
  selectedSort: null,
};

export const useSorting = create<SortingState>()(
  devtools(
    (set) => ({
      ...initialState,
      setSortingMethod: (selectedSort?: SortItem | null) => {
        if (selectedSort && selectedSort.type === 'default') {
          set({ selectedSort: null });
        } else {
          set({ selectedSort });
        }
      },
      reset: () => {
        set(initialState);
      },
    }),
    { name: 'sorting-method' }
  )
);
