import { useState } from 'react';

import { ProductContract } from 'epromo-types';

export function useAmountToOrder(defaultValue = 1) {
  const [amountToOrder, setAmountToOrder] = useState(defaultValue);
  const handleAmountChanged = (product: ProductContract, amount: number) => {
    setAmountToOrder(amount);
  };
  return {
    handleAmountChanged,
    amountToOrder,
  };
}
