import { IncomingMessage } from 'http';
import UAParser from 'ua-parser-js';

import { IDeviceInfo } from 'epromo-types';

const getUserAgentFromServer = (req?: IncomingMessage) => {
  if (!req) {
    return '';
  }
  return req.headers['user-agent'];
};

export function getDeviceInfo(req?: IncomingMessage): IDeviceInfo {
  const ua = getUserAgentFromServer(req);
  const parser = new UAParser(ua);
  const device = parser.getDevice();
  const deviceOs = parser.getOS();
  const isTablet = 'tablet'.includes(`${device.type}`.toLowerCase());
  const isMobile = 'mobile'.includes(`${device.type}`.toLowerCase());
  const isAndroid = 'android'.includes(`${deviceOs.name}`.toLowerCase());
  const isIos = 'ios'.includes(`${deviceOs.name}`.toLowerCase());

  return {
    isTablet,
    isMobile,
    deviceOs: deviceOs,
    device,
    isAndroid,
    isIos,
  };
}
