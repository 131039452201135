import { FieldValues } from 'react-hook-form';

import {
  ICmsFooter,
  IGlobalNotificationDoc,
} from 'epromo-lib-cms/types/payload-types';

import { DeliveryAddressContract } from './Users';

export type RuntimeConfig = {
  baseUrl: string;
  apiUrl: string;
  googleMapsApiKey: string;
  sentryDsn: string;
  sentryTracesSampleRate: string;
  googleId: string;
  googleSecret: string;
  facebookId: string;
  facebookSecret: string;
  appleId: string;
  appleSecret: string;
};

export type ApiValidationError = {
  fieldName: number;
  message: string;
};

export type ApiError = {
  errorCode: number;
  message: string;
  internalErrorCode: number;
  validationErrors: ApiValidationError[];
};

export type CountryEnum = 'default' | 'lt' | 'lv' | 'et';

export type CardTypeEnum = 'notSelect' | 'visa' | 'mastercard';

export type DeviceTypeEnum = 'notSpecified' | 'iOS' | 'android';

export interface IDeviceInfo {
  isTablet: boolean;
  isMobile: boolean;
  isIos: boolean;
  isAndroid: boolean;
  device: {
    model: string | undefined;
    type: string | undefined;
    vendor: string | undefined;
  };
  deviceOs: { name: string | undefined; version: string | undefined };
}

export enum NativeAppNotificationStatus {
  Closed = '0',
  Opened = '1',
}

export interface IBaseAppProps {
  nativeAppNotificationStatus?: string;
  deviceInfo?: IDeviceInfo;
  closedNotifications?: string[];
  notificationToDisplay?: IGlobalNotificationDoc;
}

export interface IEpromoPageProps extends IBaseAppProps {
  footerData?: ICmsFooter;
  tokenExists: boolean;
}

export enum AddressTypeEnum {
  cashNCarry = 'cashNCarry',
  foodService = 'foodService',
  pickUpDelivery = 'pickUpDelivery',
  none = 'none',
}

export enum LanguagesEnum {
  en = 'en',
  lt = 'lt',
  ee = 'ee',
  lv = 'lv',
}

export type Language = {
  name: string;
  flag: string;
  code: string;
};

export interface PersonalDataInput extends FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  birthDateTimestamp: number;
  password: string;
  phoneNumber: string;
}

export type CountryCodeItem = {
  cardNumber: string;
  id: string;
  code: string;
  countryName: string;
  nativeCountryName: string;
  countryImageName: string;
  isDefault: boolean;
};

export type VerificationInput = {
  code: string;
};

export type CompanyShortDetails = {
  clientName: string;
  registrationCode: string;
  vatCode: string;
  registrationAddress: string;
};

export type CompanyDataInput = {
  wantsToHaveInvoices?: boolean;
  emailForInvoices?: string;
};

export interface CompanyLookUp {
  code?: string;
  name?: string | null;
  address?: string | null;
  city?: string | null;
  postCode?: string | null;
  creditInfoId?: string | null;
  vatCode?: string | null;
  phoneNumber?: string | null;
}

export interface DeliveryAddressDataInput {
  address?: string;
  city?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  comment?: string;
  country: CountryEnum;
  longitude?: number;
  latitude?: number;
  zipCode?: string;
  phoneNumber?: string;
  acceptedTerms: boolean;
  agreedWithPolicy: boolean;
  agreedWithGdpr: boolean;
}

export interface AddDeliveryAddressReq
  extends Omit<
    DeliveryAddressDataInput,
    'acceptedTerms' | 'agreedWithPolicy' | 'agreedWithGdpr'
  > {
  name?: string;
  isBillingAddress?: boolean;
  forceSave?: boolean;
  deviceType: DeviceTypeEnum;
}

export interface AddDeliveryAddressResponse extends DeliveryAddressContract {}

export interface BaseBilling {
  id: string;
  title: string;
  description: string;
  isPrimary: boolean;
}

export type NewLoyaltyCardApplication = {
  companyName?: string | null;
  companyCode?: string;
  companyVAT?: string;
  companyAddress?: string | null;
  activity: string;
  invoiceEmail: string;
};

export type LoyaltyCard = {
  cardNumber: string;
  cardName: string;
  companyName?: string | null;
  pkPass: string;
  country: CountryEnum;
  pkPassUrl: string;
};

export interface Step {
  index: number;
  title: string;
}

export interface StepChildComponent {
  moveNext: () => void;
  moveToStep?: (index: number) => void;
  title?: string;
}

export enum HaveCardSignUpSteps {
  CardValidationStep = 1,
  PrivateDataStep,
  CompanyDataStep,
  DeliveryAddressStep,
  SignUpConfirmation,
}

export enum NoCardSignUpSteps {
  CompanyDataStep = 1,
  PrivateDataStep,
  DeliveryAddressStep,
  SignUpConfirmation,
}

export type Publication = {
  id: string;
  image: string;
  title: string;
  url: string;
  typeName: string;
  typeInfo: string;
  validFrom: string;
  validTo: string;
};

export type PublicationResponse = {
  publications: Publication[];
};

export const KNOWN_GLOBAL_FILTERS = ['ContractPricesFilterId'];

export type GlobalFilterItem = {
  filterId: string;
  id: string;
  selected?: boolean;
  filterLabel: string;
  filterValue: string;
  filterIconType:
    | 'eco'
    | 'vegetarian'
    | 'vegan'
    | 'glutenFree'
    | 'lactoseFree'
    | 'noSugar'
    | 'school'
    | 'stars';
};

export type GlobalFiltersResponse = {
  Globalfilter: {
    filters?: GlobalFilterItem[];
  };
};

interface Flag {
  flagKey: string;
  enabled: boolean;
  id: string;
}

export type FeatureFlagsResponse = {
  FeatureFlag: {
    flags?: Flag[];
  };
};

export type EmailVerificationContract = {
  code: string;
};

export enum CompanyTypesEnum {
  company = 'company',
  individualActivity = 'individualActivity',
}

export * from './Authentication';
export * from './Users';
export * from './Products';
export * from './ShoppingCart';
export * from './Orders';
export * from './Company';
export * from './More';
export * from './Versions';
export * from './Invoices';
export * from './SEB';
export * from './Employees';
