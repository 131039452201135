import axios from 'axios';
import getConfig from 'next/config';

import { IAllNews, Landingpage } from 'epromo-lib-cms/types/payload-types';

import { resolveHeaders } from '../index';
import { getErrorMessage } from '../utils/error';

const { publicRuntimeConfig } = getConfig();

export interface ILandingPage {
  Landingpage: Landingpage;
  allNews: IAllNews;
}

export const fetchLandingPageData = async (
  locale?: string,
  token?: string | null
) => {
  try {
    const headers = resolveHeaders(token, locale);

    const response = await axios.get<ILandingPage>(
      `${publicRuntimeConfig.baseUrl}/api/landing`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    console.log(getErrorMessage(error));
    return null;
  }
};
