import { useRouter } from 'next/router';
import { AxiosError } from 'axios';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';

import {
  AgeVerificationContract,
  B2BOrderContinueContract,
  B2BOrderContract,
  B2bShoppingCartConfirmRequest,
  CreateOrderContract,
  ExtendedB2BDeliveryContract,
  IExtendedShoppingCartContract,
  OrderContract,
  ShoppingCartItemModel,
  UpdateShoppingCartContract,
  B2CCompanyContract,
  B2BOrderPayContract,
  B2BOrderInfoContract,
  ApiError,
} from 'epromo-types';

import { useToaster, useCart } from 'epromo-lib/hooks';

import {
  findSelectedSlot,
  getAddressId,
  getRawAddressId,
  useCartState,
} from '../store';
import {
  Endpoints,
  useMutationQuery,
} from '../index';
import { isDeliveryAddressB2c } from '../utils/address';
import { checkIfLatvianDomain } from '../utils/language';

import { useRedirect } from './useRedirect';

const { publicRuntimeConfig } = getConfig();

const getIsAgeVerificationNeeded = (
  ageVerification?: AgeVerificationContract,
  billingAddress?: B2CCompanyContract,
  locale?: string,
  ageConfirmedWithSmartId?: boolean
) => {
  if (checkIfLatvianDomain()) {
    if (ageConfirmedWithSmartId) return false;
    return ageVerification && !billingAddress;
  }
  return false;
};

export function usePlaceOrder() {
  const { t } = useTranslation('common');
  const { toast } = useToaster();
  const { locale, asPath } = useRouter();
  const {
    b2bSelectedCart,
    b2cCart,
    billingAddress,
    b2bPaymentOption,
    setShowQtyChangedDialog,
    setShowOosDialog,
    setShowDeliveryInfoDialog,
    setShowAgeVerificationDialog,
    setCartContainsOosProducts,
    ageConfirmedWithSmartId,
    b2cSelectedDeliveryDate,
    setShowB2cSelectDeliveryDateDialog,
    setAgeVerificationNeeded,
    mapB2bCartResponse,
    mapB2cCartResponse,
    customerPoNumber,
    summary,
  } = useCartState();

  const { b2cCartQuery } = useCart(true);
  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);
  const { toPaymentResultPage } = useRedirect();

  const { mutate: createB2cOrder, isPending: createB2cCartLoading } =
    useMutationQuery<OrderContract, CreateOrderContract>({
      mutationKey: ['user-b2c-place-order'],
      endPoint: Endpoints.b2cOrderCreate,
      onSuccess: (result, payload) => {
        if (
          result &&
          result.paymentOptions &&
          result.paymentOptions.basePaymentLink
        ) {
          setShowDeliveryInfoDialog(false);
          window.location.href = result.paymentOptions.basePaymentLink;
        }
      },
      onError: (err) => {
        const axError = err as AxiosError;
        //@ts-ignore
        if (axError?.response?.data.validationErrors) {
          //TODO: do something with concrete error
        }
      },
    });

  const {
    mutate: b2bCreditPayment,
    isPending: isPayingWithCredit,
    error: isPayingWithCreditError,
  } = useMutationQuery<B2BOrderInfoContract, B2BOrderPayContract>({
    mutationKey: ['user-b2b-credit-payment'],
    endPoint: Endpoints.b2bOrderPayWithCredit,
    toastErrorGlobally: true,
    onSuccess: (result, payload) => {
      toPaymentResultPage(result.orderId);
      setShowDeliveryInfoDialog(false);
    },
    onError: (err) => {
      const axError = err as AxiosError;
      //@ts-ignore
      if (axError?.response?.data.validationErrors) {
        //TODO: do something with concrete error
      }
    },
  });

  const { mutate: createB2bOrder, isPending: createB2bCartLoading } =
    useMutationQuery<B2BOrderContract, B2BOrderContinueContract>({
      mutationKey: ['user-b2b-place-order'],
      endPoint: Endpoints.b2bOrderCreate,
      toastErrorGlobally: true,
      onSuccess: (result, payload) => {
        if (
          result &&
          result.paymentOptions &&
          b2bPaymentOption === 'bankLink'
        ) {
          if (result.paymentOptions.basePaymentLink) {
            window.location.href = result.paymentOptions.basePaymentLink;
          } else {
            toast({
              message: t('paymentOptionUnavailable'),
              type: 'error',
              toastId: 'basePaymentLink',
            });
          }
        } else {
          b2bCreditPayment({
            orderId: result.orderId,
            paymentType: 'credit',
          });
        }
      },
      onError: (err) => {
        const axError = err as AxiosError;
        //@ts-ignore
        if (axError?.response?.data.validationErrors) {
          //TODO: do something with concrete error
        }
      },
    });

  const strippedAddressId = getRawAddressId();

  const {
    mutate: confirmB2cCart,
    reset,
    isPending: confirmB2cCartLoading,
  } = useMutationQuery<
    IExtendedShoppingCartContract,
    UpdateShoppingCartContract
  >({
    mutationKey: ['user-b2c-confirm-cart'],
    endPoint: Endpoints.b2cCartConfirm,
    onSuccess: (result, payload) => {
      reset();
      const hasOos =
        result.deletedCartItems && result.deletedCartItems.length > 0;

      setCartContainsOosProducts(hasOos);
      if (hasOos) {
        const cartCopy = [...(b2cCart?.cartItems || [])];
        const itemsToAdd: ShoppingCartItemModel[] = [];
        result.deletedCartItems.forEach((deletedItem) => {
          let existingItemIdx = cartCopy.findIndex(
            (cartItem) => deletedItem.product.id === cartItem.product.id
          );
          if (existingItemIdx >= 0) {
            const newItem = { ...cartCopy[existingItemIdx] };
            newItem.product.inStock = false;
            itemsToAdd.push(newItem);
            cartCopy.splice(existingItemIdx, 1);
          }
        });
        mapB2cCartResponse({
          cartItems: [...itemsToAdd, ...cartCopy],
          freeDeliveryPrice: result.freeDeliveryPrice,
          ageVerification: result.ageVerification,
          expirationDateTimestamp: result.expirationDateTimestamp,
          slotExpirationTimestamp: result.slotExpirationTimestamp,
          minimumCartPrice: result.minimumCartPrice,
        });
        setShowOosDialog(true);
        return;
      }

      if (
        getIsAgeVerificationNeeded(
          result.ageVerification,
          billingAddress,
          locale,
          ageConfirmedWithSmartId
        )
      ) {
        setShowAgeVerificationDialog(true);
        setAgeVerificationNeeded(true);
        return;
      } else {
        setShowAgeVerificationDialog(false);
        setAgeVerificationNeeded(false);
      }

      if (
        !b2cSelectedDeliveryDate.date &&
        !b2cSelectedDeliveryDate.selectedOption
      ) {
        setShowB2cSelectDeliveryDateDialog(true);
        return;
      }

      if (result.changedProducts && result.changedProducts.length > 0) {
        b2cCartQuery.refetch();
        const cartCopy = [...(b2cCart?.cartItems || [])];
        const itemsToAdd: ShoppingCartItemModel[] = [];
        result.changedProducts.forEach((productId) => {
          let existingItemIdx = cartCopy.findIndex(
            (cartItem) => cartItem.product.id === productId
          );
          if (existingItemIdx >= 0) {
            const newCartItem = result.cartItems.find(
              (cartItem) => cartItem.product.id === productId
            );
            if (newCartItem) {
              itemsToAdd.push({ ...newCartItem, qtyChanged: true });
              cartCopy.splice(existingItemIdx, 1);
            }
          }
        });

        mapB2cCartResponse({
          ...result,
          cartItems: [...itemsToAdd, ...cartCopy],
        });
        setShowQtyChangedDialog(true);
      } else {
        setShowDeliveryInfoDialog(true);
      }
    },
    onError: (err) => {
      const axError = err as AxiosError<ApiError>;
      const errStrFromRes = axError.response?.data.message;

      toast({
        message: errStrFromRes ?? axError.message,
        type: 'error',
        toastId: errStrFromRes ?? axError.message,
      });
    },
  });

  const {
    mutate: confirmB2bCart,
    reset: resetB2bConfirmCart,
    isPending: confirmB2bCartLoading,
  } = useMutationQuery<
    ExtendedB2BDeliveryContract,
    B2bShoppingCartConfirmRequest
  >({
    mutationKey: ['user-b2b-confirm-cart'],
    endPoint: Endpoints.b2bCartConfirm,
    onSuccess: (result, payload) => {
      resetB2bConfirmCart();
      const slotIdx = result.slots.findIndex((slot) =>
        findSelectedSlot(slot, b2bSelectedCart)
      );

      const hasOos =
        result.deletedCartItems && result.deletedCartItems.length > 0;

      setCartContainsOosProducts(hasOos);
      if (hasOos) {
        if (slotIdx >= 0 && b2bSelectedCart) {
          result.deletedCartItems.forEach((deletedProduct) => {
            let existingItemIdx = result.slots[slotIdx].cartItems.findIndex(
              (cartItem) => cartItem.product.id === deletedProduct.product.id
            );

            if (existingItemIdx >= 0) {
              const newCartItem = {
                ...result.slots[slotIdx].cartItems[existingItemIdx],
              };
              newCartItem.product.inStock = false;
              result.slots[slotIdx].cartItems.splice(existingItemIdx, 1);
              result.slots[slotIdx].cartItems.unshift(newCartItem);
            }
          });
        }
        mapB2bCartResponse(
          {
            slots: result.slots,
          },
          undefined,
          true
        );
        setShowOosDialog(true);
        return;
      }

      if (
        result.changedProducts &&
        result.changedProducts.length > 0 &&
        b2bSelectedCart
      ) {
        if (slotIdx >= 0) {
          result.changedProducts.forEach((productId) => {
            let existingItemIdx = result.slots[slotIdx].cartItems.findIndex(
              (cartItem) => cartItem.product.id === productId
            );
            if (existingItemIdx >= 0) {
              const newCartItem = {
                ...result.slots[slotIdx].cartItems[existingItemIdx],
              };
              const newItem = { ...newCartItem, qtyChanged: true };
              result.slots[slotIdx].cartItems.splice(existingItemIdx, 1);
              result.slots[slotIdx].cartItems.unshift(newItem);
            }
          });
        }

        mapB2bCartResponse(
          {
            slots: result.slots,
          },
          undefined,
          true
        );
        setShowQtyChangedDialog(true);
      } else {
        mapB2bCartResponse(
          {
            slots: result.slots,
          },
          undefined,
          true
        );
        setShowDeliveryInfoDialog(true);
      }
    },
    onError: (err) => {
      const axError = err as AxiosError;
      //@ts-ignore
      if (axError?.response?.data.validationErrors) {
        //TODO: do something with concrete validation error
      }
    },
  });

  function confirmOrder() {
    if (isB2cAddress) {
      confirmB2cCart({
        cartItems: b2cCart?.cartItems,
      });
    } else {
      confirmB2bCart({
        shoppingCartIds: b2bSelectedCart ? [b2bSelectedCart?.id] : [],
      });
    }
  }

  function placeOrder() {
    // eslint-disable-next-line max-len
    const afterPaymentCbUrl = `${publicRuntimeConfig.baseUrl}/${locale}/checkout/payment-result`;
    if (isB2cAddress) {
      createB2cOrder({
        orderItems: b2cCart?.cartItems.map((item) => ({
          amount: item.amount,
          id: item.product.id,
          price: item.product.price,
          tare: item.product.tare,
        })),
        billingAddressId: billingAddress?.id,
        userAddressId: strippedAddressId,
        userVisiblePrice: summary.totalPrice,
        customerUrl: afterPaymentCbUrl,
      });
    } else {
      createB2bOrder({
        shoppingCartIds: b2bSelectedCart ? [b2bSelectedCart?.id] : [],
        customerUrl: afterPaymentCbUrl,
        customerOrderNo: customerPoNumber,
        paidByCredit: b2bPaymentOption === 'credit',
      });
    }
  }

  return {
    placeOrder,
    confirmOrder,
    isCreatingOrder: createB2bCartLoading || createB2cCartLoading,
    isConfirmingOrder: confirmB2bCartLoading || confirmB2cCartLoading,
    isPayingWithCredit,
    isPayingWithCreditError,
  };
}
