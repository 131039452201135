import axios from 'axios';
import getConfig from 'next/config';

import { ICatalogsResponse } from 'epromo-lib-cms/types/payload-types';

import { resolveHeaders } from '../index';
import { getErrorMessage } from '../utils/error';

const { publicRuntimeConfig } = getConfig();

export const fetchPublications = async (
  limit: number,
  locale?: string
): Promise<ICatalogsResponse | null> => {
  try {
    const response = await axios.get<ICatalogsResponse>(
      `${publicRuntimeConfig.baseUrl}/api/publications-catalogs`,
      {
        headers: resolveHeaders('', locale),
        params: {
          limit,
          locale,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(getErrorMessage(error));
    return null;
  }
};
