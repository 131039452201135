import { CreditCheckResponse } from 'epromo-types';

import { Endpoints, useGetQuery } from '../index';

export function useCreditCheck() {
  const { data, isLoading } = useGetQuery<CreditCheckResponse>({
    queryKey: ['credit-check'],
    endPoint: Endpoints.paymentV2,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
  };
}
