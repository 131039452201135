var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"q8Ms-8URtxuIfVd30meQg"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable max-len */
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: publicRuntimeConfig.sentryDsn,
  enabled: publicRuntimeConfig.sentryEnv !== 'development',
  environment: publicRuntimeConfig.sentryEnv,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  replaysSessionSampleRate: Number(
    publicRuntimeConfig.replaysSessionSampleRate || 0
  ),
  replaysOnErrorSampleRate: Number(
    publicRuntimeConfig.replaysOnErrorSampleRate || 0
  ),
  tracesSampleRate: Number(publicRuntimeConfig.sentryTracesSampleRate || 1.0),
  profilesSampleRate: 1.0,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'https://m-lt-epromo.sanitex.org/api/v2/shoppingCart/confirm',
        'https://m-lt-epromo.sanitex.org/api/v2/orders/create',
        'https://m-lt-epromo.sanitex.org/api/v2/shoppingCart/update',
        'https://m-lt-epromo.sanitex.org/api/v2.1/Orders/b2b/GetCheckoutSummary',
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  beforeSend(event, hint) {
    if (hint.originalException === 'DEBUG_ordering') {
      return event;
    }
    return null;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
