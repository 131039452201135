import { useCallback, useEffect, useRef, useState } from 'react';

export interface CooldownProps {
  cooldownDurationSeconds: number;
  startCooldownOnMount?: boolean;
  onCooldownStart?: () => void;
  onCooldownEnd?: () => void;
}

export const useCooldownCounter = ({
  cooldownDurationSeconds,
  onCooldownStart,
  onCooldownEnd,
  startCooldownOnMount,
}: CooldownProps) => {
  const isCooldown = useRef(startCooldownOnMount);
  const interval = useRef<NodeJS.Timeout | undefined>(undefined);
  const [cooldownTime, setCooldownTime] = useState(cooldownDurationSeconds);

  const startTimer = useCallback(() => {
    interval.current = setInterval(() => {
      setCooldownTime(cooldownTime - 1);
    }, 1000);
  }, [cooldownTime]);

  useEffect(() => {
    if (isCooldown.current === true) {
      if (cooldownTime > 0) {
        startTimer();
        onCooldownStart?.();
      } else if (cooldownTime < 1) {
        isCooldown.current = false;
        setCooldownTime(cooldownDurationSeconds);
        clearInterval(interval.current);
        onCooldownEnd?.();
      }
    }
    return () => clearInterval(interval.current);
  }, [
    cooldownTime,
    cooldownDurationSeconds,
    startTimer,
    onCooldownStart,
    onCooldownEnd,
  ]);

  return {
    isCooldown: isCooldown.current,
    cooldownTime,
    startCooldown: () => {
      isCooldown.current = true;
    },
  };
};
