import {
  B2bDeleteShoppingCartItemsReq,
  B2bDeliveryContract,
  ShoppingCartContract,
  UpdateShoppingCartContract,
} from 'epromo-types';

import { isDeliveryAddressB2c } from '../utils/address';
import { getAddressId, useCartState } from '../store';
import { Endpoints, useMutationQuery } from '../index';
import { useCart } from './useCart';

type RemoveFromCartProps = {
  productIds: string[];
  amount?: number;
};

export function useRemoveFromCart() {
  const { updateCart, fetchCheckoutData } = useCart();
  const { b2cCart, b2bSelectedCart } = useCartState();

  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);

  const {
    mutateAsync: b2bRemoveFromCart,
    isPending: isRemovingB2B,
    variables,
  } = useMutationQuery<B2bDeliveryContract, B2bDeleteShoppingCartItemsReq>({
    mutationKey: ['user-b2b-cart-remove'],
    endPoint: Endpoints.b2bRemoveProductFromCart,
    onSuccess: async (result) => {
      updateCart(result);
      fetchCheckoutData();
    },
  });

  const { mutateAsync: b2cRemoveFromCart, isPending: isRemovingB2C } =
    useMutationQuery<ShoppingCartContract, UpdateShoppingCartContract>(
      {
        mutationKey: ['user-b2c-cart-add'],
        endPoint: Endpoints.b2cUpdateProductInCart,
        onSuccess: async (result) => {
          updateCart(result);
          fetchCheckoutData();
        },
      },
      {
        method: 'put',
      }
    );

  async function removeFromCart({ productIds }: RemoveFromCartProps) {
    if (isB2cAddress) {
      if (!b2cCart) {
        return;
      }
      const itemsCopy = [...b2cCart.cartItems];
      productIds.forEach((id) => {
        const indexToRemove = itemsCopy.findIndex(
          (item) => item.product.id === id
        );
        if (indexToRemove !== -1) {
          itemsCopy.splice(indexToRemove, 1);
        }
      });
      await b2cRemoveFromCart({
        cartItems: itemsCopy,
      });
    } else {
      await b2bRemoveFromCart({ strings: productIds });
    }
  }

  function wipeOutCart() {
    if (isB2cAddress) {
      b2cRemoveFromCart({
        cartItems: [],
      });
    } else {
      const productCodes = b2bSelectedCart
        ? b2bSelectedCart.cartItems.reduce<string[]>(
            (codes: string[], current) => {
              return [...codes, current.product.id];
            },
            []
          )
        : [];
      b2bRemoveFromCart({ strings: productCodes });
    }
  }

  return {
    removeFromCart,
    isRemoving: isRemovingB2B || isRemovingB2C,
    wipeOutCart,
    variables,
  };
}
