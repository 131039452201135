import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/router';
import getConfig from 'next/config';

import { COOKIE_DELIVERY_ADDRESS, useToaster } from 'epromo-lib/hooks';
import {
  B2CCompanyContract,
  InvoicePaymentOptions,
  ProductContract,
  WishlistProductsContract,
} from 'epromo-types';

// eslint-disable-next-line max-len
import { BILLING_COMPANIES_QUERY_KEY } from 'ui/components/organisms/BillingCompaniesDropdown';
import {
  AddEmployeeContract,
  UpdateEmployeeContract,
} from 'ui/components/organisms/EmployeeDialog';
import { USER_AUTH_TOKEN } from './utils/token';
import { getCookie } from './utils/cookie';
import { downloadFromUrl } from './utils/helpers';

import {
  Endpoints,
  handleError,
  resolveHeaders,
} from './index';

export const REPLACE_CART_ITEM_MUTATION_KEY = 'replaceCartItem';

const { publicRuntimeConfig } = getConfig();

export const fetcher = (
  config: AxiosRequestConfig,
  locale: string | undefined
) =>
  axios.request({
    method: 'POST',
    headers: resolveHeaders(
      getCookie(USER_AUTH_TOKEN),
      locale,
      getCookie(COOKIE_DELIVERY_ADDRESS)
    ),
    ...config,
  });

export const useMutationUtils = () => {
  const router = useRouter();
  const { toast } = useToaster();
  const queryClient = useQueryClient();

  const errHandler = (
    err: AxiosError<unknown, any>,
    onError?: ((err: unknown) => void) | undefined
  ) =>
    handleError({
      err,
      onError,
      router,
      toast,
    });

  return { router, toast, errHandler, queryClient };
};

export const useSubscribeToNewsLetter = () => {
  const { router, toast } = useMutationUtils();
  const { t } = useTranslation('common');
  return useMutation<
    AxiosResponse,
    AxiosError,
    { email?: string; agreedToMarketing?: boolean }
  >({
    mutationFn: (req) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `/api/newsletter`,
          method: 'POST',
          data: req,
        },
        router.locale
      ),
    onError: (err) => {
      const errorMessage = t('internalErrorWithCode', { errorCode: 500 });
      toast({
        message: errorMessage,
        type: 'error',
        toastId: errorMessage,
      });
    },
  });
};

export const useDeleteAccount = () => {
  const { router, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { code?: string }>({
    mutationFn: ({ code }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.verifyDelete}/${code}`,
          method: 'POST',
        },
        router.locale
      ),
    onError: (err) => errHandler(err),
  });
};

export const useGetCompanyDetails = () => {
  const { router, errHandler } = useMutationUtils();

  return useMutation<
    AxiosResponse<B2CCompanyContract>,
    AxiosError,
    { companyCode?: string }
  >({
    mutationFn: ({ companyCode }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.companyDetails}/${companyCode}/details`,
          method: 'GET',
        },
        router.locale
      ),
    onError: (err) => errHandler(err),
  });
};

type BrowseWishlistProps = {
  locale?: string;
  pageSize?: number;
  page: number;
  categoryId: string;
};

export const browseWishlist = async ({
  locale,
  pageSize = 30,
  categoryId,
  page,
}: BrowseWishlistProps): Promise<AxiosResponse<WishlistProductsContract>> => {
  const qs = `?${new URLSearchParams({
    filterId: categoryId,
    page: `${page}`,
    count: `${pageSize}`,
  }).toString()}`;

  return fetcher(
    {
      // eslint-disable-next-line max-len
      url: `${publicRuntimeConfig.apiUrl}/${Endpoints.wishlistV21}/products/filteredWithPagination${qs}`,
      method: 'GET',
    },
    locale
  );
};

export const useDeleteAddress = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { addressId?: string }>({
    mutationFn: ({ addressId }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.address}/${addressId}`,
          method: 'delete',
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['user-addresses'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useDeleteVatRecipient = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { recipientId?: string }>({
    mutationFn: ({ recipientId }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.b2cCompanies}/${recipientId}`,
          method: 'delete',
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [BILLING_COMPANIES_QUERY_KEY],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useInitAddCard = () => {
  const { router, errHandler } = useMutationUtils();

  return useMutation<
    AxiosResponse<{ referenceId: string; url: string }>,
    AxiosError
  >({
    mutationFn: () =>
      fetcher(
        {
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.paymentSeb}`,
        },
        router.locale
      ),
    onError: (err) => errHandler(err),
  });
};

export const useDeletePaymentCard = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { cardId?: string }>({
    mutationFn: ({ cardId }) =>
      fetcher(
        {
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.payment}/${cardId}`,
          method: 'delete',
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['user-payment-methods'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useInitiatePayForInvoices = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<
    AxiosResponse<InvoicePaymentOptions>,
    AxiosError,
    { idList?: string[] }
  >({
    mutationFn: ({ idList }) =>
      fetcher(
        {
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.invoices}`,
          data: { idList },
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['invoicesData'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useCreateEmployee = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, AddEmployeeContract>({
    mutationFn: (data) =>
      fetcher(
        {
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.employees}`,
          data,
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useEditEmployee = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<
    AxiosResponse,
    AxiosError,
    UpdateEmployeeContract & { id: string }
  >({
    mutationFn: ({ id, ...rest }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.employees}/${id}/update`,
          data: rest,
          params: {
            employeeId: id,
          },
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useDeleteEmployee = () => {
  const { router, queryClient, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { id: string }>({
    mutationFn: ({ id }) =>
      fetcher(
        {
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.employees}/${id}`,
          method: 'DELETE',
          params: {
            employeeId: id,
          },
        },
        router.locale
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      }),
    onError: (err) => errHandler(err),
  });
};

export const useDownloadDocument = () => {
  const { router, errHandler } = useMutationUtils();

  return useMutation<AxiosResponse, AxiosError, { docName: string }>({
    mutationFn: ({ docName }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.invoiceDownload}?name=${docName}`,
          method: 'GET',
          responseType: 'blob',
        },
        router.locale
      ),
    onSuccess: (res, { docName }) => {
      const headersRes = res.headers as { 'content-type': string };

      const blob = new Blob([res.data], {
        type: headersRes['content-type'],
      });

      downloadFromUrl(blob, docName);
    },
    onError: (err) => errHandler(err),
  });
};

export const useReplaceCartItem = () => {
  const { router, errHandler, toast } = useMutationUtils();

  return useMutation<
    AxiosResponse,
    AxiosError,
    {
      productToReplaceId: string;
      newProductId: string;
      newProductAmount: number;
      invoiceNumber: number;
    }
  >({
    mutationKey: [REPLACE_CART_ITEM_MUTATION_KEY],
    mutationFn: ({
      newProductAmount,
      newProductId,
      productToReplaceId,
      invoiceNumber,
    }) =>
      fetcher(
        {
          // eslint-disable-next-line max-len
          url: `${publicRuntimeConfig.apiUrl}/${Endpoints.replaceCartItem}/${productToReplaceId}`,
          data: {
            productId: newProductId,
            invoiceNumber,
            amount: newProductAmount,
          },
        },
        router.locale
      ),
    onError: (err) => errHandler(err),
  });
};

export const downloadDocumentFromUrl = async (
  url: string | undefined,
  fallbackName: string,
  onError?: (err: any) => void
) => {
  try {
    if (!url) throw new Error();
    const res = await fetch(url);
    const data = await res.blob();

    downloadFromUrl(data, url.split('/').pop() || fallbackName);
  } catch (error) {
    onError?.(error);
  }
};
