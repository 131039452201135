import { useQuery } from '@tanstack/react-query';
import { fetchFeatureFlags } from '../FeatureFlags';

const FB_SOCIAL_ICON_FLAG = 'FB_SOCIAL';
const GOOGLE_ICON_FLAG = 'GOOGLE_SOCIAL';
const APPLE_SOCIAL_ICON_FLAG = 'APPLE_SOCIAL';

export const useSocialLoginIconsFlags = (locale?: string) => {
  const { data: flags } = useQuery({
    queryKey: ['feature-flags'],
    queryFn: () => {
      return fetchFeatureFlags(locale);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const fbEnabled = flags?.includes(FB_SOCIAL_ICON_FLAG);
  const googleEnabled = flags?.includes(GOOGLE_ICON_FLAG);
  const appleEnabled = flags?.includes(APPLE_SOCIAL_ICON_FLAG);

  return { fbEnabled, googleEnabled, appleEnabled };
};
