import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface UserSignInData {
  rememberMe: boolean;
}

export interface UserSignInState extends UserSignInData {
  setRememberMe: (rememberMe: boolean) => void;
}

const initialUserSignInState: UserSignInData = {
  rememberMe: false,
};
export const useUserSignIn = create<UserSignInState>()(
  devtools((set, get) => ({
    ...initialUserSignInState,
    setRememberMe: (rememberMe) => {
      set({ rememberMe });
    },
  }))
);
