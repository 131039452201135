import { useRef, useEffect } from 'react';
import SwiperCore from 'swiper';

import { useSwiperRef } from './useSwiperRef';

const updateNavStatus = (
  swiper: SwiperCore,
  prevEl: HTMLButtonElement,
  nextEl: HTMLButtonElement
) => {
  setControlStatus(prevEl, !!swiper.isBeginning);
  setControlStatus(nextEl, !!swiper.isEnd);
};

const setControlStatus = (element: HTMLElement | null, disabled: boolean) => {
  const button = element?.querySelector('button');
  if (button) {
    button.disabled = disabled;
  }
};

export const useSwiperNavigation = () => {
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const swiperRef = useRef<SwiperCore>();

  useEffect(() => {
    if (swiperRef?.current && prevEl && nextEl) {
      updateNavStatus(swiperRef?.current, prevEl, nextEl);
    }
  }, [swiperRef, prevEl, nextEl]);

  return { nextEl, prevEl, nextElRef, prevElRef };
};
