import { useEffect, useState } from 'react';
import * as Bowser from 'bowser';

type BrowserNames = 'safari' | 'chrome';

export const useBrowser = () => {
  const [browser, setBrowser] = useState<Bowser.Parser.Parser>();

  useEffect(() => {
    if (window) {
      const browser = Bowser.getParser(window.navigator.userAgent);
      setBrowser(browser);
    }
  }, []);

  const browserName = browser?.getBrowserName(true) as BrowserNames;

  return { browserName, isSafari: browserName === 'safari' };
};
