import { clsx } from 'clsx';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { usePopper } from 'react-popper';

import { useSorting } from 'epromo-lib/store/products/sorting';
import { useSortOptions } from 'epromo-lib/hooks/useSortOptions';

import { Button, ButtonAppearance } from '@components/atoms/Button';

import SortIcon from '../../atoms/assets/icons/sort.svg';

export type SortItem = {
  type: 'name' | 'price' | 'default';
  direction: 'ascending' | 'descending' | 'none';
  id: string;
};

export const SortOptions: SortItem[] = [
  //TODO: temp turning off sort by price, not possible to sort in backend
  /*
  { type: 'price', direction: 'ascending', id: '1' },
  { type: 'price', direction: 'descending', id: '2' },
  */
  { type: 'default', direction: 'none', id: '0' },
  { type: 'name', direction: 'ascending', id: '3' },
  { type: 'name', direction: 'descending', id: '4' },
];

export interface SortingPopOverProps {
  disabled?: boolean;
}

export function SortingPopOver({ disabled = false }: SortingPopOverProps) {
  const { sortOptions } = useSortOptions();
  const { t } = useTranslation('common');
  const { selectedSort, setSortingMethod } = useSorting();
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            // @ts-ignore
            ref={setReferenceElement}
            className={clsx(
              open ? 'text-gray-900' : 'text-secondary-500',
              'gap-x-2 px-4 py-1.5',
              'group inline-flex items-center',
              'rounded-3xl bg-white',
              'hover:text-filter-button-base focus:outline-none',
              'focus:ring-2 focus:ring-black focus:ring-offset-2',
              'truncate disabled:text-neutral-400'
            )}
            disabled={disabled}
          >
            <SortIcon className="h-4 w-4 fill-current" aria-hidden="true" />
            <span className="text-xs font-bold">
              {t(
                !selectedSort
                  ? 'sort'
                  : `${selectedSort.type}_${selectedSort.direction}`
              )}
            </span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={clsx('absolute z-10')}
              // @ts-ignore
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <div
                className={clsx(
                  'overflow-hidden rounded-lg',
                  'shadow-lg ring-1 ring-black ring-opacity-5'
                )}
              >
                <div className="relative flex flex-col bg-white py-2">
                  {sortOptions.map((item) => (
                    <Button
                      key={`${item.type}_${item.direction}`}
                      appearance={ButtonAppearance.SMALL_TEXT}
                      type="button"
                      onClick={() => {
                        setSortingMethod(item);
                        close();
                      }}
                      className={clsx(
                        'rounded-none px-4 text-sm',
                        'h-auto min-w-full py-2 font-normal text-black',
                        selectedSort &&
                          selectedSort.type === item.type &&
                          selectedSort.direction === item.direction &&
                          'bg-gray-100 font-bold',
                        'hover:text-filter-button-base justify-start',
                        'whitespace-nowrap'
                      )}
                    >
                      {t(`${item.type}_${item.direction}`)}
                    </Button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
