import debounce from 'lodash.debounce';
import { useEffect, useState, useCallback } from 'react';

interface WindowDimensions {
  width: number;
  height: number;
}

export const useWindowDimensions = (triggerOnMount: boolean = false) => {
  const [screenSize, setScreenSize] = useState<WindowDimensions>();

  const setScreenSizes = useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    if (triggerOnMount) {
      setScreenSizes();
    }
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', debounce(updateDimension, 100));

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return {
    width: screenSize?.width,
    height: screenSize?.height,
  } as unknown as WindowDimensions;
};
