import { IncomingMessage } from 'http';
import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

import {
  IGlobalNotificationDoc,
  IGlobalNotificationsResponse,
} from 'epromo-lib-cms/types/payload-types';
// eslint-disable-next-line max-len
import { CLOSED_NOTIFICATIONS_COOKIE_KEY } from 'ui/components/organisms/GlobalNotificationsBar';

import { resolveHeaders } from '../index';
import { getErrorMessage } from '../utils/error';

import { getCookie } from '../utils/cookie';

export const isValidEnvironmentForNotification = (
  // eslint-disable-next-line max-len
  notification: IGlobalNotificationsResponse['GlobalNotifications']['docs'][number],
  loggedIn?: boolean,
  isB2cAddress?: boolean
) => {
  if (notification.environment === 'all') {
    return true;
  }
  if (loggedIn && isB2cAddress && notification.environment === 'b2c') {
    return true;
  }
  return !!(loggedIn && !isB2cAddress && notification.environment === 'b2b');
};

export function filterNotificationsToDisplay(
  closedNotificationsLocal: string[],
  notifications: IGlobalNotificationDoc[],
  loggedIn?: boolean,
  isB2cAddress?: boolean
) {
  const list = notifications || [];
  const notificationToDisplay = list.find(
    (notification) =>
      !(closedNotificationsLocal ?? [])?.find((x) => x === notification.id) &&
      isValidEnvironmentForNotification(notification, loggedIn, isB2cAddress)
  );
  return notificationToDisplay;
}

export const fetchGlobalNotifications = async (locale?: string) => {
  try {
    // eslint-disable-next-line max-len
    const response = await axios.get<IGlobalNotificationsResponse>(
      `${publicRuntimeConfig.baseUrl}/api/global-notifications`,
      {
        headers: resolveHeaders('', locale),
        params: {
          locale,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(getErrorMessage(error));
    return null;
  }
};

export function getClosedNotifications(req?: IncomingMessage) {
  if (!req) {
    return [];
  }
  const decoded = decodeURIComponent(
    `${getCookie(CLOSED_NOTIFICATIONS_COOKIE_KEY, req)}`
  );
  if (decoded && decoded !== 'undefined') {
    return JSON.parse(decoded) as string[];
  }
  return [];
}

// eslint-disable-next-line max-len
export function getNotificationsToDisplay(
  allNotifications: IGlobalNotificationDoc[],
  isLoggedIn: boolean,
  isB2cAddress: boolean,
  req?: IncomingMessage
) {
  const notifications = getClosedNotifications(req);
  // eslint-disable-next-line max-len
  return filterNotificationsToDisplay(
    notifications,
    allNotifications,
    isLoggedIn,
    isB2cAddress
  );
}
