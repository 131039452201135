import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  B2bDeliveryContract,
  B2bUpdateCartReq,
  ShoppingCartContract,
  UpdateShoppingCartContract,
} from 'epromo-types';
import {
  B2BOrderContinueContract,
  WebB2BGetCheckoutSummaryContract,
  WebB2CGetCheckoutSummaryContract,
} from 'epromo-types/Checkout';
import { isDeliveryAddressB2c } from '../utils/address';
import { useDeliveryAddress, useCartStateTrackedStore } from '../store';
import {
  Endpoints,
  handleError,
  useGetQuery,
  useMutationQuery,
} from '../index';

import {
  B2B_CART_KEY, B2C_CART_KEY,
  B2C_CART_KEY_REFETCHED,
  StaticLinks,
} from '../constants';

import { useToaster } from './useToaster';

export function isCheckoutPage(routerPath?: string) {
  return routerPath && routerPath === StaticLinks.ShoppingCart;
}

export function useCart(doNotMapB2CData?: boolean) {
  const router = useRouter();
  const routerPath = router.asPath;
  const {
    totalPrice,
    totalItems,
    b2cCart,
    b2bCarts,
    b2bSelectedCart,
    resetCarts,
    setSummary,
    setB2cDeliverySlots,
    mapB2cCartResponse,
    mapB2bCartResponse,
    isCheckoutFetched,
    setCheckoutIsFetched,
    mapB2bCartCheckoutResponse,
    setCheckoutLoading,
    customerPoNumber,
    setCartItemsDictionary,
    b2bPaymentOption,
  } = useCartStateTrackedStore();
  const { getAddressId } = useDeliveryAddress();
  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);
  const { toast } = useToaster();

  const handleQueryError = (err: any) => {
    /* TODO: temp solution till backend fix their side
    after that refactor back to resetCarts fn only
    and enable toastErrorGlobally */
    handleError({
      err,
      onError: resetCarts,
      router,
      toast:
        err instanceof AxiosError &&
        (err as AxiosError).response?.status !== 500
          ? toast
          : undefined,
    });
  };

  const b2bCartQuery = useMutationQuery<
    WebB2BGetCheckoutSummaryContract[],
    B2BOrderContinueContract
  >({
    mutationKey: [B2B_CART_KEY],
    endPoint: Endpoints.b2bCheckoutSummaryV2,
    onSuccess: (data: WebB2BGetCheckoutSummaryContract[], req) => {
      mapB2bCartCheckoutResponse(data, req, !!isCheckoutPage(routerPath));
      if (b2bSelectedCart) {
        data?.forEach((cart) => {
          cart.slots.find(({ deliveryDate }) => {
            if (
              deliveryDate === b2bSelectedCart.deliveryDate &&
              cart.message.length
            ) {
              toast({
                toastId: cart.message,
                type: 'error',
                message: cart.message,
              });
            }
          });
        });
      }
    },
    onError: handleQueryError,
    onSettled: () => setCheckoutIsFetched(true),
  });

  const useB2cCartQuery = (doNotMapB2CData?: boolean) => {
    const b2cCartQuery = useGetQuery<WebB2CGetCheckoutSummaryContract>(
      {
        queryKey: doNotMapB2CData ? [B2C_CART_KEY_REFETCHED] : [B2C_CART_KEY],
        endPoint: Endpoints.b2cCheckoutSummaryV2,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: handleQueryError,
        toastErrorGlobally: false,
      },
      undefined,
      (res) => {
        if (!!res) {
          const { checkoutSummary, deliverySlots, shoppingCart } = res;
          if (!doNotMapB2CData) {
            mapB2cCartResponse(
              shoppingCart,
              undefined,
              !!isCheckoutPage(routerPath)
            );
          }

          if (checkoutSummary) {
            const {
              itemsPrice,
              totalPrice,
              tareFee,
              reservationFee,
              deliveryFee,
              availableCredit,
              packagingFee,
            } = checkoutSummary;

            setSummary({
              itemsPrice,
              totalPrice,
              tareFee,
              reservationFee,
              deliveryFee,
              availableCredit,
              packagingFee,
            });
          }

          setB2cDeliverySlots(deliverySlots);
        }
      }
    );

    useEffect(() => {
      if (b2cCartQuery.isFetched) {
        setCheckoutIsFetched(true);
      }
    }, [b2cCartQuery.isFetched]);

    return { b2cCartQuery };
  };

  const { b2cCartQuery } = useB2cCartQuery(doNotMapB2CData);

  useEffect(() => {
    setCheckoutLoading(b2bCartQuery.isPending || b2cCartQuery.isLoading);
  }, [b2bCartQuery.isPending, b2cCartQuery.isLoading]);

  const fetchCheckoutData = async (
    req?: B2bUpdateCartReq[] | UpdateShoppingCartContract | undefined,
    isB2c = isB2cAddress
  ) => {
    const addressId = getAddressId();
    if (!addressId) {
      return;
    }
    if (isB2c) {
      await b2cCartQuery.refetch();
    } else {
      let productIds: string[] = [];
      if (req) {
        productIds = (req as B2bUpdateCartReq[]).map((x) => x.productId);
      }
      await b2bCartQuery.mutateAsync({
        shoppingCartIds: [],
        productIds,
        customerOrderNo: customerPoNumber ?? '',
        paidByCredit: b2bPaymentOption === 'credit',
        // TODO: EW-700 include path
        // prettier-ignore
        customerUrl:
          '',
      });
    }
  };

  const resetCartItemsDictionary = () => setCartItemsDictionary({});

  const hasCart = isB2cAddress
    ? b2cCart && b2cCart.cartItems.length > 0
    : b2bSelectedCart;

  const updateCart = (
    newData: B2bDeliveryContract | ShoppingCartContract,
    request?: B2bUpdateCartReq[] | UpdateShoppingCartContract
  ) => {
    if (!isCheckoutPage(routerPath)) {
      isB2cAddress
        ? mapB2cCartResponse(
            newData as ShoppingCartContract,
            request as UpdateShoppingCartContract
          )
        : mapB2bCartResponse(
            newData as B2bDeliveryContract,
            request as B2bUpdateCartReq[]
          );
    }
  };

  return {
    totalItems,
    totalPrice,
    isB2cAddress,
    b2bCarts,
    b2cCart,
    b2bSelectedCart,
    isCartFetching: b2bCartQuery.isPending || b2cCartQuery.isLoading,
    hasCart,
    b2cCartQuery,
    isFetched: b2cCartQuery.isFetched || isCheckoutFetched,
    resetCarts,
    addressId,
    fetchCheckoutData,
    updateCart,
    resetCartItemsDictionary,
  };
}
