import { useRouter } from 'next/router';

import { Endpoints, useGetQuery, useMutationQuery } from '../index';

type SmartIdReq = { string: string };

export type SmartIdSession = {
  sessionId: string;
  authenticationCode: string;
};

type SmartIdSessionResponse = {
  birthDate: string;
  success: boolean;
  message: string;
};

export function useSmartId() {
  const { locale } = useRouter();

  const smartIdInitiateQuery = useMutationQuery<SmartIdSession, SmartIdReq>({
    mutationKey: [`initiate-smartId-session`],
    endPoint: `${Endpoints.smartId}/${locale}/Initiate`,
  });

  const getSmartIdSessionStatusQuery = useGetQuery<SmartIdSessionResponse>({
    queryKey: [
      'smartId-session-response',
      smartIdInitiateQuery.data?.sessionId,
    ],
    endPoint: Endpoints.smartIdStatus,
    qs: {
      sessionId: smartIdInitiateQuery.data?.sessionId ?? '',
    },
    enabled: !!smartIdInitiateQuery.data?.sessionId,
    refetchOnWindowFocus: false,
    onError() {
      clearSession();
    },
  });

  const clearSession = () => {
    smartIdInitiateQuery.reset();
  };

  const initiateSession = (personalCode: string) => {
    smartIdInitiateQuery.mutate({ string: personalCode });
  };

  return {
    initiateSession,
    initiating: smartIdInitiateQuery.isPending,
    waitingForResponse: getSmartIdSessionStatusQuery.isLoading,
    session: smartIdInitiateQuery.data,
    response: getSmartIdSessionStatusQuery.data,
    isResponseFailed:
      (getSmartIdSessionStatusQuery.data &&
        !getSmartIdSessionStatusQuery.data.success) ||
      getSmartIdSessionStatusQuery.error,
    clearSession,
  };
}
