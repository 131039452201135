import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { SearchResponseContract } from 'epromo-types/Inventory';
import { useDeliveryAddress } from 'epromo-lib/hooks';
import { ProductCardContract, ProductContract } from 'epromo-types';

import { getTokenFromCookie } from '../utils/token';
import { useSorting } from '../store/products/sorting';
import {
  mapLocalFiltersToReq,
  useGlobalFilters,
  useLocalFilters,
} from '../store';
import { browseCategory } from '../Inventory';

export function useBrowseCategory(categoryId: string) {
  const pageSize = 30;

  const { globalFilters } = useGlobalFilters();

  const { getAddressId } = useDeliveryAddress();
  const { selectedSort, reset: resetSort } = useSorting();
  const { selectedLocalFilters, setLocalFilters, clearLocalFilters } =
    useLocalFilters();
  const router = useRouter();
  const { locale } = router;

  const addressId = getAddressId();

  const {
    data,
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    isLoadingError,
    isRefetchError,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [
      'cat-products',
      categoryId,
      selectedSort,
      selectedLocalFilters,
      globalFilters,
      addressId,
      locale,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await browseCategory({
        page: pageParam,
        id: categoryId,
        pageSize,
        selectedLocalFilters,
        selectedGlobalFilters: globalFilters,
        selectedSort,
        locale,
        addressId,
        token: getTokenFromCookie(),
      });

      const selectedData = {
        ...data,
        products: data?.products.map(
          ({
            id,
            name,
            image,
            price,
            basePrice,
            incrementalValue,
            priceCoefficient,
            tare,
            packagingAmount,
            minimumAmount,
            storageType,
            measureUnit,
            inStock,
            inStockAmount,
            descriptionItems,
            productPrices,
            isSaleOut,
            urlSlug,
            productDeliveryException,
            urlSlugEn,
          }) => {
            return {
              id,
              name,
              image,
              price,
              basePrice,
              inStockAmount,
              incrementalValue,
              priceCoefficient,
              tare,
              packagingAmount,
              minimumAmount,
              storageType,
              measureUnit,
              inStock,
              descriptionItems,
              productPrices,
              isSaleOut,
              productDeliveryException,
              urlSlug,
              urlSlugEn,
            } as ProductCardContract;
          }
        ),
      } as SearchResponseContract;
      return selectedData;
    },

    staleTime: 60000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    getPreviousPageParam: (firstPage) => firstPage?.currentPage ?? undefined,
    getNextPageParam: (lastPage) => {
      if (!lastPage) {
        return undefined;
      }
      if (lastPage.currentPage >= lastPage.pageCount) {
        return undefined;
      }
      return lastPage.currentPage + 1;
    },
    enabled: !!globalFilters?.length,
    retry: 3,
    select: (data) => {
      const placeholderCards = Array.from({ length: 30 }, (_, index) => ({
        id: `placeholder_${index}`,
        isPlaceholder: true,
      })) as ProductContract[];

      const placeholderPageRes: Partial<
        InfiniteData<SearchResponseContract>['pages'][number]
      > = { products: placeholderCards };

      const dataWithPlaceholderItems = {
        ...data,
        pages: [...data.pages, placeholderPageRes],
      };

      return dataWithPlaceholderItems as InfiniteData<SearchResponseContract>;
    },
  });

  useEffect(() => {
    clearLocalFilters();
    setLocalFilters([]);
  }, [categoryId]);

  const onQuerySuccess = useCallback(
    (result: InfiniteData<SearchResponseContract> | undefined) => {
      if (!result) return;

      const [lastPage] = result.pages.slice(result.pages.length - 2);

      if (lastPage) {
        setLocalFilters(
          mapLocalFiltersToReq(lastPage.filters, selectedLocalFilters)
        );
      }
    },
    [selectedLocalFilters]
  );

  useEffect(() => {
    onQuerySuccess(data);
  }, [data]);

  return {
    isFetching,
    isLoading,
    fetchNextPage,
    data,
    hasNextPage,
    isFetchingNextPage,
    isFetched,
    isError,
    isLoadingError,
    isRefetchError,
  };
}
