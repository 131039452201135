import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import {
  B2bUpdateCartReq,
  ProductContract,
  ShoppingCartItemModel,
} from 'epromo-types';

import { useCartStateTrackedStore } from '../store';
import { useAddToCart } from './useAddToCart';
import { isCheckoutPage, useCart } from './useCart';
import { pageLeaveSentOutQueue, updateFn, updateQueue } from './helpers';

export function useCartQueue(mutationKey?: string) {
  const router = useRouter();
  const routerPath = router.asPath;
  const itemsQueue = useRef<Record<string, ShoppingCartItemModel>>({});
  const sendQueue = useRef<Record<string, ShoppingCartItemModel>>({});
  const addingTm = useRef<NodeJS.Timeout | undefined>(undefined);
  const refetchCartTm = useRef<NodeJS.Timeout | undefined>(undefined);
  const { updateCartFromCatalog } = useAddToCart(undefined, mutationKey);
  const { updateCartQueueV2, setIsQueueActive, b2cCart } =
    useCartStateTrackedStore();
  const { fetchCheckoutData } = useCart();

  const onPageLeave = () => {
    pageLeaveSentOutQueue(
      sendQueue,
      itemsQueue,
      addingTm,
      refetchCartTm,
      routerPath,
      (payload) => updateCartFromCatalog(payload),
      (payload) => updateCartQueueV2(payload)
    );
    setIsQueueActive(false);
  };

  const pageChangeHandler = () => onPageLeave();

  const beforeUnloadhandler = () => onPageLeave();

  useEffect(() => {
    router.events.on('routeChangeStart', pageChangeHandler);
    window.addEventListener('beforeunload', beforeUnloadhandler);
    return () => {
      router.events.off('routeChangeStart', pageChangeHandler);
      window.removeEventListener('beforeunload', beforeUnloadhandler);
    };
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(addingTm.current);
      clearTimeout(refetchCartTm.current);
    };
  }, []);

  const addOrUpdateToCartDebounced = useCallback(
    (product: ProductContract, amount: number, invoiceNumber?: number) => {
      setIsQueueActive(true);
      updateQueue(itemsQueue, product, amount, invoiceNumber);
      clearTimeout(addingTm.current);
      clearTimeout(refetchCartTm.current);

      addingTm.current = setTimeout(() => {
        sendQueue.current = { ...itemsQueue.current };
        itemsQueue.current = {};
        clearTimeout(addingTm.current);
        updateFn(
          addingTm,
          updateCartFromCatalog,
          refetchCartTm,
          sendQueue,
          routerPath,
          (payload) => updateCartQueueV2(payload),
          (payload) => {
            refetchCartTm.current = setTimeout(async () => {
              clearTimeout(refetchCartTm.current);
              if (isCheckoutPage(routerPath)) {
                /*
                         this needed in checkout summary response to know
                         what product quantities to map
                      */
                await fetchCheckoutData(
                  payload.map((queueItem) => ({
                    productId: queueItem.product.id,
                  })) as B2bUpdateCartReq[]
                );
                setIsQueueActive(false);
              }
            }, 1000);
          }
        ).then();
      }, 1000);
    },
    [routerPath, b2cCart?.cartItems]
  );

  return {
    itemsQueue,
    sendQueue,
    addOrUpdateToCartDebounced,
  };
}
