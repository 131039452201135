import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';

import {
  ProductContract,
  ShoppingCartTemplateContract,
  ShoppingCartTemplateListItem,
} from 'epromo-types';
import { storage } from '../helpers';

export interface FavoritesData {
  productsWishlistMap: Record<string, ProductContract>;
  shoppingCartTemplates: ShoppingCartTemplateContract[] | [];
  selectedShoppingCartTemplate: ShoppingCartTemplateContract | null | undefined;
  isDeletingWishlistValue: boolean;
}

export interface FavoritesState extends FavoritesData {
  setWishlist: (data: ProductContract[]) => void;
  addToWishlist: (data: ProductContract) => void;
  removeFromWishlist: (data: ProductContract) => void;
  setShoppingCartTemplates: (data: ShoppingCartTemplateContract[]) => void;
  setNewCartTemplate: (data: ShoppingCartTemplateContract) => void;
  setIsDeletingWishlistItem: (val: boolean) => void;
  getShoppingCartTemplates: (
    product: ProductContract
  ) => ShoppingCartTemplateListItem[];
  setUpdatedCartTemplate: (data: ShoppingCartTemplateContract) => void;
  clearFavorites: () => void;
  setSelectedShoppingCartTemplate: (
    selectedTemplate: ShoppingCartTemplateContract | null | undefined
  ) => void;
}

const initialState: FavoritesData = {
  productsWishlistMap: {},
  shoppingCartTemplates: [],
  selectedShoppingCartTemplate: null,
  isDeletingWishlistValue: false,
};

export const FAVORITES_KEY = 'favorites';

export const useFavoritesStore = create<FavoritesState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setIsDeletingWishlistItem: (val) => {
          set({
            isDeletingWishlistValue: val,
          });
        },
        setWishlist: (productsWishlist: ProductContract[]) => {
          const mappedProducts = productsWishlist.reduce(
            (acc, currentProduct) => {
              return { ...acc, [currentProduct.id]: 1 };
            },
            {}
          );
          set({ productsWishlistMap: mappedProducts });
        },
        addToWishlist: (data: ProductContract) => {
          set({
            productsWishlistMap: {
              ...get().productsWishlistMap,
              [data.id]: data,
            },
          });
        },
        removeFromWishlist: (data: ProductContract) => {
          const prevWishList = get().productsWishlistMap;
          if (prevWishList && prevWishList[data.id]) {
            const { [data.id]: old, ...rest } = prevWishList;
            set({
              productsWishlistMap: {
                ...rest,
              },
            });
          }
        },

        setShoppingCartTemplates: (
          shoppingCartTemplates: ShoppingCartTemplateContract[]
        ) => {
          set({ shoppingCartTemplates });
        },
        setNewCartTemplate: (newCartTemplate: ShoppingCartTemplateContract) => {
          const templates = [...get().shoppingCartTemplates];
          const existingTemplate = templates.find(
            (tpl) => tpl.id === newCartTemplate.id
          );
          if (existingTemplate) {
            existingTemplate.products = newCartTemplate.products;
            existingTemplate.name = newCartTemplate.name;
          } else {
            templates.push(newCartTemplate);
          }
          set({
            shoppingCartTemplates: templates,
          });
        },
        getShoppingCartTemplates: (product: ProductContract) => {
          return get().shoppingCartTemplates.map((cartTemplate) => ({
            ...cartTemplate,
            isProductInCartTemplate: Boolean(
              cartTemplate.products?.find(
                (cartProduct) => cartProduct.id === product.id
              )
            ),
          }));
        },
        setUpdatedCartTemplate: (
          updatedCartTemplate: ShoppingCartTemplateContract
        ) => {
          set({
            shoppingCartTemplates: get().shoppingCartTemplates.map(
              (cartTemplate) =>
                cartTemplate.id === updatedCartTemplate.id
                  ? updatedCartTemplate
                  : cartTemplate
            ),
          });
        },
        clearFavorites: () => {
          set({ ...initialState });
        },
        setSelectedShoppingCartTemplate: (
          selectedShoppingCartTemplate:
            | ShoppingCartTemplateContract
            | null
            | undefined
        ) => {
          set({ selectedShoppingCartTemplate });
        },
      }),
      {
        name: FAVORITES_KEY,
        storage,
      }
    )
  )
);

export const useFavoritesStateTrackedStore =
  createTrackedSelector(useFavoritesStore);
