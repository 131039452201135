import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

import { ProductContract, ShoppingCartItemModel } from 'epromo-types';

import { useCartStateTrackedStore } from '../store/cart';
import { useAddToCart } from './useAddToCart';
import { pageLeaveSentOutQueue, updateFn, updateQueue } from './helpers';

export function useCartQueueGrid(mutationKey?: string) {
  const router = useRouter();
  const routerPath = router.asPath;
  const itemsQueue = useRef<Record<string, ShoppingCartItemModel>>({});
  const sendQueue = useRef<Record<string, ShoppingCartItemModel>>({});
  const addingTm = useRef<NodeJS.Timeout | undefined>(undefined);
  const refetchCartTm = useRef<NodeJS.Timeout | undefined>(undefined);
  const { updateCartFromCatalog } = useAddToCart(undefined, mutationKey);

  const { b2cCart } = useCartStateTrackedStore();

  const onPageLeave = () =>
    pageLeaveSentOutQueue(
      sendQueue,
      itemsQueue,
      addingTm,
      refetchCartTm,
      routerPath,
      (payload) => updateCartFromCatalog(payload)
    );

  const pageChangeHandler = () => onPageLeave();

  const beforeUnloadhandler = () => onPageLeave();

  useEffect(() => {
    router.events.on('routeChangeStart', pageChangeHandler);
    window.addEventListener('beforeunload', beforeUnloadhandler);
    return () => {
      router.events.off('routeChangeStart', pageChangeHandler);
      window.removeEventListener('beforeunload', beforeUnloadhandler);
    };
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(addingTm.current);
      clearTimeout(refetchCartTm.current);
    };
  }, []);

  const addOrUpdateToCartDebounced = useCallback(
    (product: ProductContract, amount: number, invoiceNumber?: number) => {
      updateQueue(itemsQueue, product, amount, invoiceNumber);
      clearTimeout(addingTm.current);
      clearTimeout(refetchCartTm.current);

      addingTm.current = setTimeout(() => {
        sendQueue.current = { ...itemsQueue.current };
        itemsQueue.current = {};
        clearTimeout(addingTm.current);
        updateFn(
          addingTm,
          updateCartFromCatalog,
          refetchCartTm,
          sendQueue
        ).then();
      }, 1000);
    },
    [routerPath, b2cCart?.cartItems]
  );

  return {
    addOrUpdateToCartDebounced,
  };
}
