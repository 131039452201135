import { createJSONStorage } from 'zustand/middleware';

export const isStoreSupported = (getStorage: Storage) => {
  try {
    const key = 'static_key_to_test';
    getStorage.setItem(key, key);
    getStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const storage = createJSONStorage(() =>
  isStoreSupported(localStorage) ? localStorage : sessionStorage
);
