import { HTMLAttributes, CSSProperties, ReactNode, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

const styleVariants = {
  message: {
    sm: 'text-base font-bold',
    md: 'text-xl font-black',
    lg: 'text-2xl font-black',
  },
  description: {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-sm',
  },
  gap: {
    sm: 'gap-4',
    md: 'gap-10',
    lg: 'gap-8',
  },
};

export interface ActionPanelProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'sm' | 'md' | 'lg';
  message?: string;
  description?: string | ReactNode;
  descriptionClassname?: string;
  titleStyle?: CSSProperties | undefined;
  image?: ReactElement | null;
}

export const ActionPanel = ({
  message = '',
  description = '',
  image = null,
  children,
  className,
  variant = 'md',
  descriptionClassname,
  titleStyle,
}: ActionPanelProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-center text-center',
        styleVariants.gap[variant],
        className
      )}
    >
      {image}
      <div
        className={twMerge(
          'flex flex-col items-center justify-center',
          'w-full gap-4 whitespace-pre-wrap text-center'
        )}
      >
        <h4
          className={twMerge(styleVariants.message[variant])}
          style={titleStyle}
        >
          {message}
        </h4>
        <p
          className={twMerge(
            'text-center',
            'w-full break-words font-normal text-neutral-700 md:break-normal',
            styleVariants.description[variant],
            descriptionClassname
          )}
        >
          {description}
        </p>
      </div>
      {children}
    </div>
  );
};

ActionPanel.displayName = 'ActionPanel';
