import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { clsx } from 'clsx';
import { PlusIcon } from '@heroicons/react/24/solid';
import { SingleValue } from 'react-select';

import { Endpoints, useGetQuery } from 'epromo-lib';
import { B2CCompanyContract } from 'epromo-types/Company';

import { DropDown, DropDownProps } from '@components/molecules/DropDown';

export interface BillingAutoCompleteProps
  extends Omit<
    DropDownProps<B2CCompanyContract, B2CCompanyContract>,
    'onChange'
  > {
  onAddNew: () => void;
  onChange: (item: B2CCompanyContract) => void;
  value?: B2CCompanyContract;
}

export const BILLING_COMPANIES_QUERY_KEY = 'get-billing-companies';

const NewBillingOption = ({
  innerProps,
  data,
}: {
  innerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  data: B2CCompanyContract;
}) => {
  const { name, id } = data;

  if (id === 'new') {
    return (
      <div
        {...innerProps}
        className={clsx(
          'inline-flex px-2 text-sm',
          'items-center gap-x-2',
          'hover:text-primary-500'
        )}
      >
        <PlusIcon className="h-6 w-6" /> {name}
      </div>
    );
  }
  return (
    <div
      {...innerProps}
      className={clsx(
        'cursor-pointer px-2 py-2 text-sm',
        'hover:text-primary-500'
      )}
    >
      {name}
    </div>
  );
};

export function BillingCompaniesDropdown({
  onChange,
  onAddNew,
  name,
  value,
  ...rest
}: BillingAutoCompleteProps) {
  const { t } = useTranslation('common');
  const newBaseBillingContract = {
    id: 'new',
    name: t('createNew'),
  } as B2CCompanyContract;

  const { data } = useGetQuery<B2CCompanyContract[]>({
    queryKey: [BILLING_COMPANIES_QUERY_KEY],
    endPoint: Endpoints.billingUsers,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  return (
    <DropDown
      {...rest}
      isMulti={false}
      value={value}
      placeholder={t('choose')}
      noOptionsMessage={t('noSrchRes')}
      onChange={(item) => {
        if (!item) {
          return;
        }
        const singleItem = item as SingleValue<B2CCompanyContract>;
        if (singleItem && singleItem.id !== 'new') {
          onChange(singleItem);
        } else if (singleItem && singleItem.id === 'new') {
          onAddNew();
        }
      }}
      defaultOptions={[newBaseBillingContract, ...(data ?? [])]}
      name={name}
      getOptionLabel={(opt) => opt.name}
      getOptionValue={(opt) => opt.id}
      components={{
        Option: NewBillingOption,
      }}
    />
  );
}
