import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { COOKIE_NATIVE_APP_KEY } from '../../constants';
import { storage } from '../helpers';

export interface NativeAppNotificationData {
  nativeAppNotificationIsClosed: boolean;
}

export interface NativeAppNotificationState extends NativeAppNotificationData {
  closeNativeAppNotification: () => void;
  openNativeAppNotification: () => void;
}

const initialAppState: NativeAppNotificationData = {
  nativeAppNotificationIsClosed: false,
};

export const useNativeAppState = create<NativeAppNotificationState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialAppState,
        closeNativeAppNotification: () => {
          set({
            nativeAppNotificationIsClosed: true,
          });
        },
        openNativeAppNotification: () => {
          set({
            nativeAppNotificationIsClosed: false,
          });
        },
      }),
      {
        name: COOKIE_NATIVE_APP_KEY,
        storage,
      }
    )
  )
);
