import { compareDesc } from 'date-fns';

import { PublicationResponse } from 'epromo-types';

import { fetchPublications } from '../Publications';

import { Endpoints, useGetQuery } from '../index';

export function useGetPublications() {
  return useGetQuery<unknown, PublicationResponse>({
    queryKey: ['publications'],
    endPoint: Endpoints.news,
    refetchOnWindowFocus: false,
    select: (data) => {
      const { publications } = data as PublicationResponse;
      const sorted = publications.sort((a, b) => {
        const aDate = a.validFrom
          .replace(' -', '')
          .split('.')
          .map((x) => parseInt(x)) as [number, number, number];
        const bDate = b.validFrom
          .replace(' -', '')
          .split('.')
          .map((x) => parseInt(x)) as [number, number, number];

        return compareDesc(new Date(...aDate), new Date(...bDate));
      });
      return {
        publications: sorted,
      };
    },
  });
}

export async function getPublicationCatalogs(
  locale?: string,
  catalogsToFetch?: number
) {
  let toFetch = typeof catalogsToFetch === 'undefined' ? 300 : catalogsToFetch;
  if (toFetch <= 0) {
    toFetch = -1;
  }
  return await fetchPublications(toFetch, locale);
}
