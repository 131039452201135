import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { UserProfile } from 'epromo-types';

import { useUserProfile } from '../store';
import { useUserSignIn } from '../store/auth/user-signin';
import { setTokenToCookie } from '../utils/token';
import { Endpoints, useGetQuery } from '../index';
import { PROFILE_QUERY_KEY } from '../constants';

import { useRedirect } from './useRedirect';
import { getAuthCookieExp } from './useCredentialsLogin';

export const useAuthTokenLogin = (redirectToUrlAfterLogin?: string) => {
  const router = useRouter();
  const { rememberMe } = useUserSignIn();
  const { data: userProfile, refetch } = useGetQuery<UserProfile>({
    queryKey: [PROFILE_QUERY_KEY],
    endPoint: Endpoints.profile,
    enabled: false,
  });

  const { setUserProfile } = useUserProfile();
  const { toHomePage } = useRedirect();

  useEffect(() => {
    if (router.query.token) {
      setTokenToCookie(
        `${router.query.token}`,
        getAuthCookieExp(`${router.query.token}`, rememberMe)
      );
      refetch();
    }
  }, [router]);

  useEffect(() => {
    if (userProfile) {
      setUserProfile(userProfile);
      if (redirectToUrlAfterLogin) {
        router.replace(redirectToUrlAfterLogin);
      } else {
        if (router.query.confirmState) {
          router.push({
            pathname: '/auth/login',
            // eslint-disable-next-line max-len
            query: {
              confirmState: encodeURIComponent(`${router.query.confirmState}`),
            },
          });
        } else {
          toHomePage();
        }
      }
    }
  }, [userProfile]);
};
