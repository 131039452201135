import {
  AddDeliveryAddressReq,
  AddDeliveryAddressResponse,
  CountryEnum,
  DeliveryAddressDataInput,
} from 'epromo-types';

import { Endpoints, useMutationQuery } from '../index';

type UseAddAddressProps = {
  onSuccess?: (response: AddDeliveryAddressResponse) => void;
};

export function useAddAddress({ onSuccess }: UseAddAddressProps) {
  const { mutate, isPending, error } = useMutationQuery<
    AddDeliveryAddressResponse,
    AddDeliveryAddressReq
  >({
    endPoint: Endpoints.addDeliveryAddress,
    mutationKey: ['add-address'],
    onSuccess: (response: AddDeliveryAddressResponse) => {
      onSuccess?.(response);
    },
  });

  const submit = (data: DeliveryAddressDataInput) => {
    mutate({
      phoneNumber: data.phoneNumber,
      address: `${data.address} ${data.houseNumber}`,
      apartmentNumber: data.apartmentNumber,
      zipCode: data.zipCode,
      deviceType: 'notSpecified',
      city: data.city,
      comment: data.comment,
      country: data.country as CountryEnum,
      name: 'delivery address',
      isBillingAddress: true,
      forceSave: true,
      latitude: data.latitude,
      longitude: data.longitude,
    });
  };
  return {
    submitAddress: submit,
    isPending,
    error,
  };
}
