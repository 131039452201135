import { IncomingMessage } from 'http';
import ReactGA from 'react-ga4';
import getConfig from 'next/config';

import { ConsentValues } from 'epromo-types';
import { COOKIE_CONSENT_KEY } from '../constants';
import { getCookie, removeCookie, setCookie } from './cookie';

const { publicRuntimeConfig } = getConfig();

export const setConsent = (
  data: ConsentValues,
  expires?: number | Date | undefined
) => {
  setCookie(COOKIE_CONSENT_KEY, JSON.stringify(data), expires);
};

export const getConsent = (req?: IncomingMessage): ConsentValues | null => {
  try {
    const cookieItself = getCookie(COOKIE_CONSENT_KEY, req);
    return JSON.parse(decodeURIComponent(cookieItself || ''));
  } catch (err) {
    return null;
  }
};

export const initGA = () => {
  if (!publicRuntimeConfig.isDevMode && publicRuntimeConfig.googleAnalyticsId) {
    ReactGA.initialize(publicRuntimeConfig.googleAnalyticsId);
  }
};

export const disableGA = () => {
  if (window) {
    // @ts-ignore
    window[`ga-disable-${publicRuntimeConfig.googleAnalyticsId}`] = true;
  }
  removeCookie('_ga');
  removeCookie(
    `_ga_${publicRuntimeConfig.googleAnalyticsId?.replace('G-', '')}`
  );
};
