import {
  CountryEnum,
  ProductContract,
  StorageType,
  TareContract,
} from '../index';
import { ShoppingCartItemModel } from '../ShoppingCart';
import { DeliveryAddressContract, UserAddress } from '../Users';
import {
  PaymentCardContract,
  SEBPaymentOptions,
  VatContract,
} from '../Checkout';

export interface OrderHistoryModel {
  id: string;
  createdAt: number;
  products: ShoppingCartItemModel[];
  address: UserAddress;
  deliveryAddress: DeliveryAddressContract;
  deliveryFee: number;
  totalPrice: number;
  price: number;
  vat: VatModel[];
  reservationFee: number;
  deliveryDate: string;
  paymentCard: UserPaymentCardModel;
  status: OrderStatus;
  orderNumber: string;
}

export interface CreateOrderItemContract {
  id: string;
  price: number;
  amount: number;
  tare: TareContract;
}

export interface OrderItemContract {
  id: string;
  orderId: string;
  name: string;
  amount: number;
  price: number;
  vat: number;
  vatAmount: number;
  barCode: string;
  measureUnit: string;
  image: string;
  tare: TareContract;
  storageType?: StorageType;
  noLongerInStock?: boolean;
  inStock?: boolean;
}

export interface WebOrderContract {
  orderId: string;
  orderName: string;
  deliveryAddress: string;
  deliveryDate: string;
  user: string;
  orderItems: OrderItemContract[];
  itemsPrice: number;
  tareFee: number;
  deliveryFee: number;
  totalPrice: number;
  reservationFee: number;
  vat: VatModel[];
  orderStatus: OrderStatus;
  paymentStatus: PaymentStatus;
  debtCoverFee?: number;
}

export type VatModel = {
  vatAmount: number;
  vat: number;
  vatName: string;
};

export type UserPaymentCardModel = {
  id: string;
  userId: string;
  externalId: string;
  type: number;
  displayName: string;
  isDisabled: boolean;
  isDefault: boolean;
  country: CountryEnum;
  expiryDate: number;
  createdAt: string;
  expired: boolean;
};

export enum OrderStatus {
  initiated = 'initiated',
  created = 'created',
  ready = 'ready',
  onTheWay = 'onTheWay',
  delivered = 'delivered',
}

export enum PaymentStatus {
  unpaid = 'unpaid',
  inProgress = 'inProgress',
  paid = 'paid',
  refunded = 'refunded',
  processing = 'processing',
}

export interface B2BOrderContinueContract {
  shoppingCartIds?: string[];
  productIds?: string[];
  customerUrl?: string;
  customerOrderNo?: string;
  paidByCredit: boolean;
}

export interface B2BOrderPayContract {
  orderId: string;
  paymentType: string;
}

export interface CreateOrderContract {
  paymentCardId?: string;
  userAddressId?: string;
  message?: string;
  userVisiblePrice?: number;
  billingAddressId?: string;
  orderItems?: CreateOrderItemContract[];
  customerUrl?: string;
}

export interface OrderContract {
  id: string;
  paymentCardId: string;
  userAddressId: string;
  message: string;
  orderNumber: string;
  totalPrice: number;
  status: OrderStatus;
  paymentOptions: SEBPaymentOptions;
  paymentStatus: PaymentStatus;
}

export interface B2BOrderContract {
  orderId: string;
  deliveryAddress: DeliveryAddressContract;
  deliveryDate: number;
  deliveryTime: string;
  itemsPrice: number;
  tareFee: number;
  deliveryFee: number;
  totalPrice: number;
  vat: VatContract[];
  reservationFee: number;
  availableCredit: number;
  paymentOptions: SEBPaymentOptions;
  paymentCards: PaymentCardContract[];
}

export interface B2BOrderInfoContract {
  orderId: string;
  orderName: string;
  orderStatus: OrderStatus;
  deliveryDate: number;
  deliveryTime: string;
  paymentStatus: PaymentStatus;
}
