import { CategoryTreeItem } from 'epromo-types/Inventory';
import { SlugItem } from 'epromo-types';

export type UrlSlugProp = 'urlSlug' | 'urlSlugEn';

export function slugSegmentsToPath(paths: string | string[] | undefined) {
  const segments: string[] = [];
  if (paths && typeof paths === 'string') {
    segments.push(paths);
  } else if (paths) {
    segments.splice(0, 0, ...(paths as string[]));
  }
  return {
    segments,
    path: segments.join('/'),
  };
}

export function toPathWithLocale(locale: string | undefined, paths: string[]) {
  return `/${locale}/${paths.join('/')}`;
}

export function addLeadingSlash(url?: string | null) {
  if (!url) {
    return null;
  }
  return `${url}`.indexOf('/') === 0 ? url : `/${url}`;
}

export function traverseInventoryTree(
  tree: CategoryTreeItem[] = [],
  result: CategoryTreeItem[],
  level: number
) {
  tree.forEach((cat) => {
    result.push({
      ...cat,
      urlSlug: addLeadingSlash(cat.urlSlug),
      urlSlugEn: addLeadingSlash(cat.urlSlugEn),
      level,
    });
    traverseInventoryTree(cat.childCategories || [], result, level + 1);
  });
}

export function flattenInventoryTree(
  tree: CategoryTreeItem[]
): CategoryTreeItem[] {
  let initialItems: CategoryTreeItem[] = [];
  traverseInventoryTree(tree, initialItems, 1);
  return initialItems;
}

export function resolveLocaleProp(locale: string | undefined): UrlSlugProp {
  return locale === 'en' ? 'urlSlugEn' : 'urlSlug';
}

export function resolveUrl(
  locale: string | undefined,
  item: SlugItem
): string | null {
  return item[resolveLocaleProp(locale)];
}

export function parseBreadCrumbs(
  flatCategories: CategoryTreeItem[],
  locale: string | undefined,
  paths: string | string[] | undefined
) {
  const { segments } = slugSegmentsToPath(paths);
  const result = [];
  let path = toPathWithLocale(locale, segments);
  const localeProp = resolveLocaleProp(locale);
  const pathsToSplit = [...segments];

  while (pathsToSplit.length > 0) {
    const cat = flatCategories.find(
      (category) =>
        `${
          `${category[localeProp]}`[0] === '/'
            ? `${category[localeProp]}`
            : `/${category[localeProp]}`
        }`.toLowerCase() === path.toLowerCase()
    );
    if (cat) {
      result.unshift(cat);
    }
    pathsToSplit.pop();
    path = toPathWithLocale(locale, pathsToSplit);
  }
  return result;
}

export const resolveFirstCategoryUrl = (
  locale: string | undefined,
  flatInventoryList: CategoryTreeItem[]
): string => {
  let firstCategory = flatInventoryList.filter(({ level }) => level === 1)[0];
  if (firstCategory)
    return (
      resolveUrl(locale, {
        urlSlug: addLeadingSlash(firstCategory.urlSlug),
        urlSlugEn: addLeadingSlash(firstCategory.urlSlugEn),
      }) ?? '/'
    );
  return '/';
};
