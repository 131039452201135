import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { GlobalFilterItem, KNOWN_GLOBAL_FILTERS } from 'epromo-types';

export interface FiltersData {
  globalFilters?: GlobalFilterItem[] | [];
  selectedGlobalFilters: GlobalFilterItem[];
}

export interface FiltersState extends FiltersData {
  setGlobalFilters: (data: GlobalFilterItem[]) => void;
  setSelectedGlobalFilters: (data: GlobalFilterItem[]) => void;
  clearFilters: () => void;
}

const initialState: FiltersData = {
  globalFilters: [],
  selectedGlobalFilters: [],
};

export const GLOBAL_FILTERS_KEY = 'global-filters';

export const mapFilterId = (filterId: string, locale?: string) => {
  const isKnownFilter = KNOWN_GLOBAL_FILTERS.includes(filterId);
  if (isKnownFilter) {
    return filterId;
  }

  return `${filterId}.${locale?.toUpperCase()}.attribute_value`;
};

export const useGlobalFilters = create<FiltersState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setGlobalFilters: (globalFilters: GlobalFilterItem[]) => {
        set({ globalFilters });
      },
      setSelectedGlobalFilters: (selectedGlobalFilters: GlobalFilterItem[]) => {
        set({ selectedGlobalFilters });
      },
      clearFilters: () => {
        const globalFilters = get().globalFilters || [];
        set({
          globalFilters: globalFilters.map((filter) => ({
            ...filter,
            selected: false,
          })),
        });
      },
    }),
    { name: GLOBAL_FILTERS_KEY }
  )
);
