import { AddressTypeEnum, UserAddress, UserAddressB2b } from 'epromo-types';

import { AddressesState } from 'ui/components/organisms/AutoSelectDeliveryAddressDialog';

import { Endpoints, useGetQuery } from '../index';

export function isB2bAddressesGuard(
  item: UserAddressB2b | UserAddress
): item is UserAddressB2b {
  return (item as UserAddressB2b).addresses !== undefined;
}

export function moveActiveAddressToFirstPosition(
  addressesArr: UserAddressB2b[] | UserAddress[],
  selectedAddressId?: string
) {
  if (!selectedAddressId) return addressesArr;

  let targetIdx = -1;

  for (let index = 0; index < addressesArr.length; index++) {
    const addressItem = addressesArr[index];

    let isSelected = false;
    if (isB2bAddressesGuard(addressItem)) {
      isSelected = !!addressItem.addresses.find(
        (address) => address.id === selectedAddressId
      );

      const selectedInnerAddressIndex = addressItem.addresses.findIndex(
        (innerAddress) => innerAddress.id === selectedAddressId
      );

      if (selectedInnerAddressIndex !== -1) {
        const selectedInnerAddress =
          addressItem.addresses[selectedInnerAddressIndex];
        addressItem.addresses.splice(selectedInnerAddressIndex, 1);
        addressItem.addresses.unshift(selectedInnerAddress);
      }
    } else {
      isSelected = addressItem.id === selectedAddressId;
    }

    if (isSelected) targetIdx = index;
  }

  let addressesToReturn: (UserAddressB2b | UserAddress)[] = [];
  const targetAddress = addressesArr.splice(targetIdx, 1)[0];
  addressesToReturn =
    targetIdx !== -1 ? [targetAddress, ...addressesArr] : addressesArr;

  return addressesToReturn;
}

export const useAddresses = (enabled = false, addressId?: string) =>
  useGetQuery<UserAddressB2b[], AddressesState>({
    queryKey: ['user-addresses'],
    endPoint: Endpoints.addressList,
    refetchOnWindowFocus: false,
    enabled,
    select: (data) => {
      const newData = data.reduce(
        (prev, curr) => {
          switch (curr.type) {
            // b2c addresses
            case AddressTypeEnum.cashNCarry:
              return { ...prev, b2c: [...prev.b2c, ...curr.addresses] };
            // pick up delivery addresses goes to b2c for now
            case AddressTypeEnum.pickUpDelivery:
              return { ...prev, b2c: [...prev.b2c, ...curr.addresses] };
            // all remaining are b2b
            default:
              return { ...prev, b2b: [...prev.b2b, curr] };
          }
        },
        { b2b: [], b2c: [] } as AddressesState
      );

      return newData;
    },
  });
