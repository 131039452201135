import { ReactNode, ReactElement, ButtonHTMLAttributes } from 'react';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'next-i18next';

import { Icon } from '@components/atoms/Icon';

export const BUTTON_BASE = clsx(
  'inline-flex justify-center items-center',
  'space-x-3.5 px-6 py-3 w-96 h-12',
  'border-transparent rounded-3xl',
  'font-bold text-sm'
);

const DISABLED_BUTTON = `disabled:bg-neutral-300 disabled:text-neutral-500
  disabled:border-transparent`;
const DISABLED_TEXT_BUTTON = `disabled:text-neutral-300`;

export enum ButtonAppearance {
  PRIMARY,
  NEUTRAL,
  SECONDARY,
  TEXT,
  SMALL_TEXT,
  NEGATIVE,
  NEGATIVE_CTA,
  CLEAR,
}

export const APPEARANCE_MAPS: Record<ButtonAppearance, string> = {
  [ButtonAppearance.PRIMARY]: clsx(
    'bg-skin-button-default text-white',
    'hover:bg-skin-button-hover',
    'focus:bg-skin-button-focus outline-secondary-500',
    DISABLED_BUTTON
  ),
  [ButtonAppearance.SECONDARY]: clsx(
    'bg-white text-secondary-500 border-secondary-500 border',
    'hover:bg-neutral-100',
    'focus:outline-2 focus:bg-neutral-100 outline-secondary-500',
    DISABLED_BUTTON
  ),
  [ButtonAppearance.TEXT]: clsx(
    'text-secondary-500 font-medium text-sm',
    'outline-transparent',
    'py-3 px-4 hover:text-default-primary',
    DISABLED_TEXT_BUTTON
  ),
  [ButtonAppearance.SMALL_TEXT]: clsx(
    'bg-white text-secondary-500 font-bold font-bold text-xs',
    'outline-transparent',
    DISABLED_TEXT_BUTTON
  ),
  [ButtonAppearance.NEGATIVE]: clsx(
    'bg-white text-negative-500 font-medium text-sm',
    'hover:font-bold focus:font-bold outline-transparent',
    DISABLED_TEXT_BUTTON
  ),
  [ButtonAppearance.NEGATIVE_CTA]: clsx(
    'bg-white text-negative-500 font-medium text-sm',
    'hover:font-bold focus:font-bold outline-transparent',
    'border border-negative-500'
  ),
  [ButtonAppearance.NEUTRAL]: clsx(
    'bg-neutral-100 font-medium text-sm',
    'hover:bg-neutral-200  outline-transparent',
    'transition-all ease-in'
  ),
  [ButtonAppearance.CLEAR]: clsx(
    'bg-white font-medium text-sm',
    'transition-all ease-in'
  ),
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit';
  appearance?: keyof typeof APPEARANCE_MAPS;
  loading?: boolean;
  fluid?: boolean;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  loadingMessage?: string;
  loadingIndicator?: 'icon' | 'text';
  extraText?: string;
}

export function Button({
  children,
  type = 'button',
  loading,
  disabled,
  className,
  appearance = ButtonAppearance.PRIMARY,
  fluid = false,
  iconBefore,
  iconAfter,
  loadingMessage,
  loadingIndicator = 'text',
  extraText = '',
  ...rest
}: ButtonProps): ReactElement {
  const { t } = useTranslation('common');
  const loaderOption = {
    text: loadingMessage ? loadingMessage : t('loading'),
    icon: <Icon name="threeDots" className={clsx('h-auto w-8')} />,
  };

  return (
    <button
      disabled={loading || disabled}
      className={twMerge(
        clsx(BUTTON_BASE, APPEARANCE_MAPS[appearance], {
          'w-full': fluid,
          'w-auto': !fluid,
          'min-w-min': !fluid,
          'max-w-max': !fluid,
        }),
        className
      )}
      type={type}
      {...rest}
    >
      {iconBefore}
      {loading && (
        <span className="flex">{loaderOption[loadingIndicator]}</span>
      )}
      {!loading && (
        <div className="flex space-x-2">
          <span>{children}</span>
          {extraText && <p className="font-normal">{extraText}</p>}
        </div>
      )}
      {iconAfter}
    </button>
  );
}
