import { useRouter } from 'next/router';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function useProductAttributeValues() {
  const { locale } = useRouter();

  const isEnVersion = `${locale}`.toLowerCase() === 'en';
  return {
    attrGoodForSchool: isEnVersion
      ? publicRuntimeConfig.attrGoodForSchoolTruthyValEn
      : publicRuntimeConfig.attrGoodForSchoolTruthyVal,
    attrEco: isEnVersion
      ? publicRuntimeConfig.attrEcoTruthyValEn
      : publicRuntimeConfig.attrEcoTruthyVal,
    attrVegan: isEnVersion
      ? publicRuntimeConfig.attrVeganTruthyValEn
      : publicRuntimeConfig.attrVeganTruthyVal,
  };
}
