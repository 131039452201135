import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ProductContract } from 'epromo-types';

import { storage } from '../helpers';

export interface UserSearchData {
  products: ProductContract[];
}

export interface UserSearchState extends UserSearchData {
  setProducts: (products: ProductContract[]) => void;
}

const initialUserSearchState: UserSearchData = {
  products: [],
};

export const USER_SEARCH_KEY = 'user-search';

export const useUserSearch = create<UserSearchState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialUserSearchState,
        setProducts: (products: ProductContract[]) => {
          set({ products });
        },
      }),
      {
        name: USER_SEARCH_KEY,
        storage,
      }
    )
  )
);
