import { useState } from 'react';
import axios from 'axios';
import getConfig from 'next/config';
import { useQueryClient } from '@tanstack/react-query';

const { publicRuntimeConfig } = getConfig();

import { RegisterUserResponse } from 'epromo-types';

import { useUserProfile } from '../store';
import { useUserSignIn } from '../store/auth/user-signin';
import { setTokenToCookie } from '../utils/token';
import { getErrorMessage } from '../utils/error';
import { clearUserData } from '../utils/userData';
import { AUTH_COOKIE_EXP, PROFILE_QUERY_KEY, StaticLinks } from '../constants';

type UseCredentialsLoginProp = {
  login: (email: string, password: string) => Promise<void>;
  loading: boolean;
  error: string | null;
  logout: (destination?: string) => Promise<void>;
  setError: (error: string) => void;
};

export const getAuthCookieExp = (token: string, rememberMe?: boolean) => {
  return rememberMe ? AUTH_COOKIE_EXP : undefined;
};

export const useCredentialsLogin = (
  redirectToUrlAfterLogin?: string
): UseCredentialsLoginProp => {
  const { rememberMe } = useUserSignIn();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { setUserProfile } = useUserProfile();
  const queryClient = useQueryClient();

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError('');

      const response = await axios.post<RegisterUserResponse>(
        `${publicRuntimeConfig.apiUrl}/users/authenticate`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.token) {
        setTokenToCookie(
          response.data.token,
          getAuthCookieExp(response.data.token, rememberMe)
        );
        setUserProfile(response.data.userProfile);
        queryClient.setQueryData([PROFILE_QUERY_KEY], () => {
          return response.data.userProfile
        })
      }
    } catch (e) {
      setLoading(false);
      setError(getErrorMessage(e));
    }
  };

  const logout = async (destination?: string) => {
    const url = destination ?? StaticLinks.Home;
    clearUserData();
    typeof window !== 'undefined' && window.location.assign(url);
  };

  return {
    login,
    loading,
    logout,
    error,
    setError,
  };
};
