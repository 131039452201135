import { ReactElement, ReactNode, useState } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'next-themes';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import getConfig from 'next/config';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  dehydrate,
} from '@tanstack/react-query';
import { Inter } from 'next/font/google';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import NextNProgress from 'nextjs-progressbar';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'ui/styles/global.css';

import { IntercomWrapper } from 'ui/components/organisms/IntercomWrapper';
import {
  COOKIE_NATIVE_APP_NOTIFICATION_KEY,
  THEMES,
} from 'epromo-lib/constants';
import { getCookie } from 'epromo-lib/utils/cookie';
import { getDeviceInfo } from 'epromo-lib/utils/device';
import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';
import { StaticLinks } from 'epromo-lib/constants';

import { fetchGlobalFilters } from 'epromo-lib/GlobalFilters';
import { resolveDomainLangForCms } from 'epromo-lib/utils/language';
import { COOKIE_DELIVERY_ADDRESS } from 'epromo-lib/hooks/useDeliveryAddress';
import { getAppData } from 'epromo-lib-cms/services/cms-requests';
import {
  ICmsCookieConsentResponse,
  IGlobalNotificationsResponse,
} from 'epromo-lib-cms/types/payload-types';
import {
  getClosedNotifications,
  getNotificationsToDisplay,
} from 'epromo-lib/GlobalNotifications';

import { shouldShowCookieConsent } from '@components/organisms/CookieConsent';

const CookieConsent = dynamic(
  () =>
    import('@components/organisms/CookieConsent').then(
      (mod) => mod.CookieConsent
    ),
  {
    ssr: true,
    loading: () => <div></div>,
  }
);

const LoyaltyCardsCheck = dynamic(
  () =>
    import('@components/organisms/LoyaltyCardsCheck').then(
      (mod) => mod.LoyaltyCardsCheck
    ),
  {
    ssr: false,
    loading: () => <div></div>,
  }
);

const VerificationDialog = dynamic(
  () =>
    import('@components/organisms/VerificationDialog').then(
      (mod) => mod.VerificationDialog
    ),
  {
    ssr: true,
    loading: () => <div></div>,
  }
);

const AutoSelectDeliveryAddressDialog = dynamic(
  () =>
    import('@components/organisms/AutoSelectDeliveryAddressDialog').then(
      (mod) => mod.AutoSelectDeliveryAddressDialog
    ),
  {
    ssr: false,
    loading: () => <div></div>,
  }
);

import { fetchFeatureFlags } from 'epromo-lib/FeatureFlags';
import { IDeviceInfo } from 'epromo-types';

const { publicRuntimeConfig } = getConfig();

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface MyAppProps extends AppPropsWithLayout {
  defaultTheme: string;
}

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

function MyApp({ Component, pageProps, defaultTheme, router }: MyAppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const getLayout = Component.getLayout ?? ((page) => page);

  const deviceInfo = pageProps.deviceInfo as IDeviceInfo;

  const isInLogin =
    router.asPath.includes(StaticLinks.Login) ||
    router.asPath.includes(StaticLinks.ConfirmEmail);

  return (
    <LiveChatLoaderProvider
      providerKey={publicRuntimeConfig.intercomAppId}
      provider="intercom"
      baseUrl={publicRuntimeConfig.intercomApiBase}
    >
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <ThemeProvider
            themes={[THEMES.B2B, THEMES.B2C]}
            forcedTheme={defaultTheme}
          >
            {pageProps.tokenExists && <LoyaltyCardsCheck />}
            <IntercomWrapper>
              <style jsx global>{`
                html {
                  font-family: ${inter.style.fontFamily};
                }
              `}</style>
              <NextNProgress
                options={{
                  showSpinner: false,
                }}
                color="#e4263c"
              />
              {getLayout(<Component {...pageProps} />)}
            </IntercomWrapper>
            <ReactQueryDevtools initialIsOpen={false} />

            {!isInLogin && pageProps.tokenExists && (
              <AutoSelectDeliveryAddressDialog />
            )}

            {pageProps.cookieConsent &&
              pageProps.cookieConsent.Cookiesconsent && (
                <CookieConsent
                  cookieConsent={pageProps.cookieConsent.Cookiesconsent}
                  showConsentInitially={pageProps.consentCookieDataInitial}
                />
              )}
            <ToastContainer />
            {pageProps.tokenExists && <VerificationDialog />}
            <Tooltip anchorSelect=".tipped" className="epromo-tooltip" />
            <Intercom color="#0c3b7b" containerClass="intercom-temp" />
          </ThemeProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </LiveChatLoaderProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const queryClient = new QueryClient();
  const locale = appContext?.ctx?.locale;

  const appProps = await App.getInitialProps(appContext);

  const [footerResponse, cookieConsent, notifications] = await getAppData(
    resolveDomainLangForCms(locale)
  );

  const deviceInfo = getDeviceInfo(appContext.ctx.req);

  const deliveryAddressId = getCookie(
    COOKIE_DELIVERY_ADDRESS,
    appContext.ctx.req
  );

  const defaultTheme = isDeliveryAddressB2c(deliveryAddressId)
    ? THEMES.B2C
    : THEMES.B2B;

  await queryClient.prefetchQuery({
    queryKey: ['global-filters'],
    queryFn: () => fetchGlobalFilters(locale),
  });

  const isB2cAddress = isDeliveryAddressB2c(deliveryAddressId);
  const tokenExists = getCookie('token', appContext.ctx?.req);
  const notificationsResponse = notifications as IGlobalNotificationsResponse;
  const allNotifications = notificationsResponse?.GlobalNotifications?.docs;
  const notificationToDisplay = getNotificationsToDisplay(
    allNotifications,
    !!tokenExists,
    isB2cAddress
  );
  const closedNotifications = getClosedNotifications(appContext.ctx?.req);
  const consentCookieDataInitial = !!shouldShowCookieConsent(
    new Date().getTime(),
    cookieConsent
      ? (cookieConsent as ICmsCookieConsentResponse).Cookiesconsent
      : null,
    appContext.ctx.req
  );

  const nativeAppNotificationStatus = getCookie(
    COOKIE_NATIVE_APP_NOTIFICATION_KEY,
    appContext.ctx?.req
  );

  await queryClient.prefetchQuery({
    queryKey: ['feature-flags'],
    queryFn: () => fetchFeatureFlags(locale),
  });

  return {
    ...appProps,
    pageProps: {
      footerData: footerResponse,
      consentCookieDataInitial,
      deviceInfo,
      cookieConsent: cookieConsent,
      tokenExists,
      notificationToDisplay,
      closedNotifications,
      nativeAppNotificationStatus,
      dehydratedState: dehydrate(queryClient),
    },
    defaultTheme,
  };
};

// @ts-ignore
export default appWithTranslation(MyApp);
