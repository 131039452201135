import {
  FAVORITES_KEY,
  GLOBAL_FILTERS_KEY,
  LOCAL_FILTERS_KEY,
  PROFILE_KEY,
  APP_STORE_KEY,
  USER_SEARCH_KEY,
} from '../store';
import { removeAddressId } from '../hooks';

import { removeTokenCookie } from './token';

export const clearUserData = () => {
  removeAddressId();
  removeTokenCookie();
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(FAVORITES_KEY);
    window.localStorage.removeItem(GLOBAL_FILTERS_KEY);
    window.localStorage.removeItem(LOCAL_FILTERS_KEY);
    window.localStorage.removeItem(PROFILE_KEY);
    window.localStorage.removeItem(APP_STORE_KEY);
    window.localStorage.removeItem(USER_SEARCH_KEY);
  }
};
