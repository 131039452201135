import { Step } from 'epromo-types';
import { useState } from 'react';

export const useStepper = (stepperConfig: Step[], initialStep?: number) => {
  const [activeStep, setActiveStep] = useState(initialStep || 1);
  const hasNext = activeStep < stepperConfig.length;

  const moveNext = () => {
    hasNext && setActiveStep(activeStep + 1);
  };

  const moveToStep = (nextStep: number) => {
    stepperConfig.find((step) => step.index === nextStep) &&
      setActiveStep(nextStep);
  };

  return {
    activeStep,
    moveNext,
    moveToStep,
  };
};
