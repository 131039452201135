import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import { Endpoints, useGetQuery } from 'epromo-lib';
import { useAppState } from 'epromo-lib/store/app';
import { SEBPaymentInfo } from 'epromo-types';
import { useToaster } from 'epromo-lib/hooks';

export const usePaymentInfo = (
  payment_reference: string | undefined,
  showToast?: boolean
) => {
  const { t } = useTranslation('common');
  const { toast } = useToaster();

  const usedPaymentReferences = useAppState(
    (state) => state.usedPaymentReferences
  );
  const setUsedPaymentReferences = useAppState(
    (state) => state.setUsedPaymentReferences
  );

  const {
    data: paymentInfo,
    isFetched,
    isLoading,
  } = useGetQuery<SEBPaymentInfo>({
    queryKey: ['paymentInfo'],
    endPoint: `${Endpoints.sebInfo}?paymentId=${payment_reference}`,
    enabled: payment_reference != null,
  });

  const isAlreadyChecked = !!usedPaymentReferences.find(
    (x) => payment_reference === x
  );

  const displayToast =
    !isLoading && isFetched && !isAlreadyChecked && showToast;

  useEffect(() => {
    if (isFetched) {
      setUsedPaymentReferences(payment_reference);

      const msgString =
        paymentInfo?.status !== 'settled'
          ? paymentInfo?.processingError?.message
          : t('invoicePaid');

      displayToast &&
        toast({
          type: paymentInfo?.status === 'settled' ? 'success' : 'error',
          message: msgString ?? '',
          toastId: 'veryUniqueIdToPreventMultipleToasts',
        });
    }
  }, [
    displayToast,
    isFetched,
    paymentInfo?.processingError?.message,
    paymentInfo?.status,
    payment_reference,
    setUsedPaymentReferences,
    t,
    toast,
  ]);

  return { paymentInfo, isAlreadyChecked, isChecking: isLoading };
};
