import { useLayoutEffect, useRef, useState } from 'react';
import { B2BShoppingCartItemModel, ShoppingCartItemModel } from 'epromo-types';

import { useCartState } from '../store';

export type UseToggleAllProps = {
  cartListItems: B2BShoppingCartItemModel[] | ShoppingCartItemModel[];
  invoiceNumber: number;
  isB2cAddress: boolean;
};
export function useToggleAll({
  cartListItems,
  invoiceNumber,
  isB2cAddress,
}: UseToggleAllProps) {
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const {
    b2bSelectedCartItems,
    setSelectedB2bCartItems,
    b2cSelectedCartItemsIds,
    setSelectedB2cCartItemsIds,
  } = useCartState();

  useLayoutEffect(() => {
    if (!isB2cAddress) {
      const selectedCartItemIds = b2bSelectedCartItems[invoiceNumber] || [];
      const isIndeterminate =
        selectedCartItemIds.length > 0 &&
        selectedCartItemIds.length < cartListItems.length;
      setChecked(selectedCartItemIds.length === cartListItems.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [b2bSelectedCartItems, isB2cAddress]);

  useLayoutEffect(() => {
    if (isB2cAddress) {
      const isIndeterminate =
        b2cSelectedCartItemsIds.length > 0 &&
        b2cSelectedCartItemsIds.length < cartListItems.length;
      setChecked(b2cSelectedCartItemsIds.length === cartListItems.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) {
        checkbox.current.indeterminate = isIndeterminate;
      }
    }
  }, [b2cSelectedCartItemsIds, isB2cAddress]);

  function toggleAll() {
    if (isB2cAddress) {
      setSelectedB2cCartItemsIds(
        checked || indeterminate
          ? []
          : cartListItems.map(({ product: { id } }) => id)
      );
    } else {
      const ids =
        checked || indeterminate
          ? []
          : cartListItems.map((cartListItem) => cartListItem);
      const b2bSelectedCartItemsCopy = { ...b2bSelectedCartItems };
      if (!b2bSelectedCartItemsCopy[invoiceNumber]) {
        b2bSelectedCartItemsCopy[invoiceNumber] = [];
      }
      // @ts-ignore
      b2bSelectedCartItemsCopy[invoiceNumber] = ids;
      setSelectedB2bCartItems(b2bSelectedCartItemsCopy);
    }
  }
  return {
    checkbox,
    toggleAll,
    checked,
  };
}
