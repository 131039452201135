import { IncomingMessage } from 'http';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import {
  disableGA,
  getConsent,
  initGA,
  setConsent,
} from 'epromo-lib/utils/consentHelpers';
import { CONSENT_COOKIE_EXP } from 'epromo-lib/constants';

import { ConsentValues, CookieCategoryIdEnum } from 'epromo-types';
import {
  CookiePolicy,
  CookieSettings,
  ICmsCookieConsent,
} from 'epromo-lib-cms/types/payload-types';

import { CookieConsentFooter } from '@components/molecules/CookieConsentFooter';

const CookiePolicySettingsDialog = dynamic(
  () =>
    import('@components/organisms/CookiePolicySettingsDialog').then(
      (mod) => mod.CookiePolicySettingsDialog
    ),
  {
    loading: () => <div></div>,
  }
);

export type CookieDialogTabId = 'policy' | 'settings';

const checkToShowContents = (cookieConsent: ICmsCookieConsent) =>
  cookieConsent?.cookiepolicy?.validFrom !== null &&
  cookieConsent?.cookiesettings?.validFrom !== null;

const checkIsSettingsExpired = (
  now: number,
  consentCookieData: ConsentValues | null,
  cookiesettings: CookieSettings | null | undefined
) => {
  if (!cookiesettings) {
    return false;
  }
  if (!cookiesettings.validFrom) {
    return false;
  }
  const cookieSettingsValidFrom = new Date(cookiesettings.validFrom).getTime();
  return (
    now >= cookieSettingsValidFrom &&
    consentCookieData?.consentTimestamp &&
    consentCookieData.consentTimestamp < cookieSettingsValidFrom
  );
};

const checkIsPolicyExpired = (
  now: number,
  consentCookieData: ConsentValues | null,
  cookiepolicy: CookiePolicy | null | undefined
) => {
  if (!cookiepolicy || cookiepolicy.validFrom === null) return false;
  const cookiePolicyValidFrom = new Date(cookiepolicy.validFrom).getTime();
  return (
    now >= cookiePolicyValidFrom &&
    consentCookieData?.consentTimestamp &&
    consentCookieData.consentTimestamp < cookiePolicyValidFrom
  );
};

export interface CookieConsentProps {
  cookieConsent: ICmsCookieConsent;
  showConsentInitially: boolean;
}

export function shouldShowCookieConsent(
  nowTimestamp: number,
  cookieConsent?: ICmsCookieConsent | null | undefined,
  req?: IncomingMessage
) {
  const consentCookieData = getConsent(req);
  const expiredPolicyConsent = checkIsPolicyExpired(
    nowTimestamp,
    consentCookieData,
    cookieConsent?.cookiepolicy
  );

  const expiredSettingsConsent = checkIsSettingsExpired(
    nowTimestamp,
    consentCookieData,
    cookieConsent?.cookiesettings
  );

  return (
    !consentCookieData ||
    !consentCookieData.consentTimestamp ||
    expiredSettingsConsent ||
    expiredPolicyConsent
  );
}

export function CookieConsent({
  cookieConsent,
  showConsentInitially,
}: CookieConsentProps) {
  const consentCookieData = getConsent();
  const { locale } = useRouter();
  const [isOpen, setIsOpen] = useState(showConsentInitially);

  const [isOpenDetails, setIsOpenDetails] = useState<
    boolean | CookieDialogTabId
  >(false);

  useEffect(() => {
    const policyLink = document.getElementById('cookie-policy');
    let listener = policyLink?.addEventListener('click', (e) => {
      e.preventDefault();
      setIsOpenDetails(true);
    });
    return () => {
      listener && policyLink?.removeEventListener('click', listener);
    };
  }, [locale]);

  useEffect(() => {
    if (consentCookieData) {
      if (consentCookieData.statistics) {
        initGA();
      }
    }
  }, []);

  const openCookiePolicySettingDialog = (tabId?: CookieDialogTabId) => {
    setIsOpenDetails(tabId ? tabId : true);
  };

  if (!checkToShowContents(cookieConsent)) {
    return null;
  }

  const submit = (data: Record<CookieCategoryIdEnum, boolean>) => {
    if (data.statistics) {
      initGA();
    } else {
      disableGA();
    }
    setConsent(
      { ...data, consentTimestamp: new Date().getTime() },
      CONSENT_COOKIE_EXP
    );
    setIsOpen(false);
  };

  const submitEssential = () => {
    const result = {} as Record<CookieCategoryIdEnum, boolean>;
    for (const key in CookieCategoryIdEnum) {
      // @ts-ignore
      result[CookieCategoryIdEnum[key]] =
        key === CookieCategoryIdEnum.mandatory;
    }
    submit(result);
  };

  const submitAll = () => {
    const result = {} as Record<CookieCategoryIdEnum, boolean>;
    for (const key in CookieCategoryIdEnum) {
      // @ts-ignore
      result[CookieCategoryIdEnum[key]] = true;
    }
    submit(result);
  };

  return (
    <>
      {isOpenDetails && (
        <CookiePolicySettingsDialog
          isOpened={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
          setIsOpen={setIsOpen}
          cookiePolicyData={cookieConsent.cookiepolicy}
          cookieSettingsData={cookieConsent.cookiesettings}
          cookieConsent={cookieConsent}
          consentCookieData={consentCookieData}
        />
      )}
      <CookieConsentFooter
        openCookiePolicyDialog={openCookiePolicySettingDialog}
        onSubmitAll={() => {
          submitAll();
        }}
        onSubmitEssential={() => {
          submitEssential();
        }}
        isOpen={isOpen}
      />
    </>
  );
}

CookieConsent.displayName = 'CookieConsent';
