import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Language, ProductContract } from 'epromo-types';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { IInnerPage } from 'epromo-lib-cms/types/payload-types';

import { useAppState } from '../store';
import { addLeadingSlash, resolveLocaleProp } from '../utils/slug';

export function useApp() {
  const router = useRouter();
  const routerPath = router.asPath;

  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    categoriesMenuOpen,
    setCategoriesMenuOpen,
    setCategoriesDropMenuOpen,
  } = useAppState();

  useEffect(() => {
    setCategoriesMenuOpen(false);
    //setMobileMenuOpen(false);
  }, [routerPath]);

  const handleLanguageSwitch = (
    lang: Language,
    category?: CategoryTreeItem,
    product?: ProductContract,
    page?: IInnerPage,
    altPage?: IInnerPage
  ) => {
    const localeProp = resolveLocaleProp(lang.code);
    if (category) {
      const catUrl = `${addLeadingSlash(category[localeProp])}`;
      router.replace(catUrl, undefined, {
        locale: lang.code,
      });
    } else if (product) {
      const productUrl = `${addLeadingSlash(product[localeProp])}`;
      router.replace(productUrl, undefined, {
        locale: lang.code,
      });
    } else if (page && altPage && altPage.slug) {
      const altPageUrl = `${addLeadingSlash(altPage.slug)}`;
      router.replace(altPageUrl, undefined, { locale: lang.code });
    } else {
      router.replace(router.asPath, undefined, { locale: lang.code });
    }
  };

  return {
    mobileMenuOpen,
    setMobileMenuOpen,
    handleLanguageSwitch,
    categoriesMenuOpen,
    setCategoriesMenuOpen,
    setCategoriesDropMenuOpen,
  };
}
