import { useTranslation } from 'react-i18next';
//import ReactMarkdown from 'react-markdown';
import { useState } from 'react';
import clsx from 'clsx';

// eslint-disable-next-line max-len
import { IGlobalNotificationDoc } from 'epromo-lib-cms/types/payload-types';
import { setCookie } from 'epromo-lib/utils/cookie';

import { Button, ButtonAppearance } from '@components/atoms/Button';
import { Icon } from '@components/atoms/Icon/Icon';
import { ActionPanel } from '@components/molecules/ActionPanel';

import { Dialog } from '../Dialog';

const MAX_TITLE_CHARS_COUNT = 110;
export const CLOSED_NOTIFICATIONS_COOKIE_KEY = 'closedNotifications';

interface Props {
  notificationToDisplay?: IGlobalNotificationDoc;
  closedNotifications: string[];
}

export const GLOBAL_NOTIFICATIONS_ID = 'global-notifications';

export const GlobalNotificationsBar = ({
  notificationToDisplay,
  closedNotifications,
}: Props) => {
  const [modalOpened, setModalOpened] = useState(false);

  const [closedNotificationsLocal, setClosedNotificationsLocal] =
    useState(closedNotifications);

  const { t } = useTranslation('common');

  if (!notificationToDisplay) {
    return null;
  }

  const tooManyTitleCharacters =
    (notificationToDisplay.content ?? '-').trim().length >
    MAX_TITLE_CHARS_COUNT;

  const formatContent = (content: string) => {
    if (!tooManyTitleCharacters) return content;

    const contentMaxCharsStr = content
      .split('')
      .splice(0, MAX_TITLE_CHARS_COUNT)
      .join('');
    const contentSplit = `${contentMaxCharsStr
      .split(' ')
      .slice(0, -1)
      .join(' ')}...`;

    return contentSplit;
  };

  const closeNotification = (id: string) => {
    setCookie(
      CLOSED_NOTIFICATIONS_COOKIE_KEY,
      JSON.stringify([...closedNotifications, id])
    );
    setClosedNotificationsLocal([...closedNotificationsLocal, id]);
  };

  return (
    <>
      <div
        id={GLOBAL_NOTIFICATIONS_ID}
        style={{
          backgroundColor: notificationToDisplay.bgColor,
        }}
        // eslint-disable-next-line max-len
        className="relative flex min-h-[48px] flex-col items-center justify-center pl-4 pr-10 lg:flex-row"
      >
        {/* eslint-disable-next-line max-len */}
        <div className=" flex w-full max-w-[800px] flex-col items-center justify-center py-2 text-xs font-medium text-white md:flex-row">
          <div className="flex items-center self-start md:self-center">
            <Icon className="mr-2" name={notificationToDisplay.icon} />
            <div
              className={clsx('text-base')}
              dangerouslySetInnerHTML={{
                __html: formatContent(notificationToDisplay.content ?? '-'),
              }}
            />
          </div>

          {tooManyTitleCharacters && (
            // eslint-disable-next-line max-len
            <div className="ml-[30px] self-start pr-4 pt-[10px] md:ml-0 md:pt-0">
              <Button
                appearance={ButtonAppearance.SECONDARY}
                className={clsx(
                  'ml-0 h-7 space-x-1 border-white px-3  text-xs md:ml-4',
                  `font-normal text-white bg-${notificationToDisplay.bgColor}`,
                  `hover:bg-white hover:text-red-400`,
                  // eslint-disable-next-line max-len
                  `focus:bg-[${notificationToDisplay.bgColor}] focus:outline-none`
                )}
                iconAfter={
                  <Icon name="chevronRight" className="mx-0 h-6 w-6 px-0" />
                }
                onClick={() => setModalOpened(true)}
              >
                {t('more')}
              </Button>
            </div>
          )}
        </div>

        <Icon
          className="absolute right-4 top-[12px] h-6 w-6 cursor-pointer"
          color="white"
          name="close"
          onClick={() => closeNotification(notificationToDisplay.id)}
        />
      </div>

      <Dialog
        isOpened={modalOpened}
        isClosable
        onClose={() => setModalOpened(false)}
      >
        <ActionPanel
          titleStyle={{
            wordBreak: 'break-word',
          }}
          className="w-full rounded-2xl bg-white px-8 py-8"
          descriptionClassname="md:break-words"
          variant="lg"
          message={notificationToDisplay.title}
          description={
            <div
              dangerouslySetInnerHTML={{
                __html: notificationToDisplay.content ?? '-',
              }}
            />
          }
        >
          <Button
            appearance={ButtonAppearance.PRIMARY}
            className="px-16"
            loadingIndicator="icon"
            onClick={() => {
              setModalOpened(false);
              closeNotification(notificationToDisplay.id);
            }}
          >
            {t('okGotIt')}
          </Button>
        </ActionPanel>
      </Dialog>
    </>
  );
};
