import { AxiosError } from 'axios';
import { ApiError } from 'epromo-types';
import { headers } from 'next/headers';

export type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (maybeError instanceof AxiosError) {
    const axiosErr = maybeError as AxiosError<ApiError>;
    console.log(`Api error metadata: \n
      Headers: \n
      * Host: ${axiosErr?.config?.url}
      * Language: ${axiosErr?.config?.headers.Languages}
      * Address: ${axiosErr?.config?.headers.AddressId} \n
      Payload: ${axiosErr?.config?.data} \n
      Path: ${axiosErr.request?.path}
    `);
    if (axiosErr.response?.data?.message) {
      // eslint-disable-next-line max-len
      return new Error(String(`${axiosErr.response?.data?.message}`));
    }
    // eslint-disable-next-line max-len
    return new Error(String(`${axiosErr.message}`));
  }

  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}
