import { AxiosError } from 'axios';

import {
  ResendCodeResponse,
  ResendPhoneCodeReq,
  VerificationCodeReq,
  VerificationCodeResponse,
  VerificationInput,
} from 'epromo-types';

import { Endpoints, useMutationQuery } from '../index';

type UsePhoneVerificationProps = {
  onPhoneVerified?: (data: VerificationCodeResponse) => void;
  onCodeResent?: (data: ResendCodeResponse) => void;
  onVerificationError?: (data: AxiosError) => void;
};

const formatVerificationBody = (data: { code: number | string }) => ({
  code: Number(data.code),
});
const formatResendCodeBody = (phoneNumber?: string | undefined | null) => ({
  deviceType: 'notSpecified',
  string: phoneNumber || '',
});

export function usePhoneVerification({
  onPhoneVerified,
  onCodeResent,
  onVerificationError,
}: UsePhoneVerificationProps) {
  const { mutate, error, isPending, isError, reset } = useMutationQuery<
    VerificationCodeResponse,
    VerificationCodeReq
  >({
    endPoint: Endpoints.verifyNumber,
    mutationKey: ['verify-number'],
    onSuccess: (data: VerificationCodeResponse) => {
      onPhoneVerified?.(data);
    },
    toastErrorGlobally: false,
    onError: (err: AxiosError) => {
      onVerificationError?.(err);
    },
  });

  const {
    mutate: resendCodeMutation,
    error: resendCodeError,
    isPending: resendCodeInProgress,
  } = useMutationQuery<ResendCodeResponse, ResendPhoneCodeReq>({
    endPoint: Endpoints.resendUserPhoneVerification,
    mutationKey: ['resend-number-verification-code'],
    onSuccess: (data: ResendCodeResponse) => {
      onCodeResent?.(data);
    },
    toastErrorGlobally: true,
  });

  const submitVerifyPhone = (data: VerificationInput) => {
    mutate(formatVerificationBody(data));
  };

  const submitResendCode = (phoneNumber?: string | undefined | null) => {
    resendCodeMutation(formatResendCodeBody(phoneNumber) as ResendPhoneCodeReq);
  };

  return {
    submitVerifyPhone,
    verificationError: error,
    verificationInProgress: isPending,
    resetVerification: reset,
    verificationIsError: isError,
    resendCodeError,
    resendCodeInProgress,
    submitResendCode,
  };
}
