import { useState, RefObject, useEffect, ReactNode, useRef } from 'react';
import { SwiperRef } from 'swiper/react';

export interface SlideObj {
  slideId: number;
  content: React.ReactNode;
}

export const useSwiperMenu = (
  swiperRef: RefObject<SwiperRef>,
  baseSlide: ReactNode
) => {
  const [slides, setSlides] = useState<SlideObj[]>([
    {
      slideId: 0,
      content: baseSlide,
    },
  ]);

  const activeSlideNo = useRef(0);

  const slideNext = (nextSlideContent: ReactNode) => {
    setSlides((prevState) => {
      activeSlideNo.current = prevState.length;
      return [
        ...prevState,
        {
          content: nextSlideContent,
          slideId: prevState.length,
        },
      ];
    });
  };

  const slideBack = () => {
    swiperRef.current?.swiper?.slideTo(activeSlideNo.current - 1);

    setSlides((prevState) => {
      const newState = prevState.filter(
        (x) => x.slideId !== prevState.length - 1
      );

      return newState;
    });
  };

  useEffect(() => {
    swiperRef.current?.swiper?.slideTo(slides.length);
  }, [slides, swiperRef]);

  return { slideNext, slideBack, slides };
};
