import { IncomingMessage } from 'http';
import axios from 'axios';
import getConfig from 'next/config';

import {
  BreadCrumbsItem,
  BrowseCategoryProductsContract,
  CategoryTree,
  CategoryTreeItem,
  SearchResponseContract,
} from 'epromo-types/Inventory';
import {
  FilterItemOption,
  ReqFilterItem,
} from 'epromo-types/Filters/local-filter';
import { GlobalFilterItem } from 'epromo-types';
import { SortItem } from 'ui/components/molecules/SortingPopOver';

import { Endpoints, resolveHeaders } from '../index';
import { getTokenFromCookie } from '../utils/token';
import { getAddressId, mapFilterId } from '../store';

const { publicRuntimeConfig } = getConfig();

type BrowseCategoryProps = {
  id: string | undefined;
  locale?: string;
  pageSize?: number;
  page: number;
  selectedLocalFilters?: FilterItemOption[] | undefined;
  selectedGlobalFilters?: GlobalFilterItem[] | undefined;
  selectedSort?: SortItem | null | undefined;
  addressId: string | undefined;
  token?: string | undefined;
};

export const browseCategory = async ({
  id,
  locale,
  pageSize = 30,
  selectedLocalFilters,
  selectedGlobalFilters,
  selectedSort,
  page,
  addressId,
  token,
}: BrowseCategoryProps) => {
  const mappedLocalFilters = (selectedLocalFilters || []).reduce(
    (prevItem: ReqFilterItem[], current) => {
      let existing = prevItem.find((filter) => filter.id === current.parentId);
      if (!existing) {
        existing = {
          id: current.parentId,
          values: [current.title.value],
        };
        return [...prevItem, existing];
      } else {
        existing.values = [...existing.values, current.title.value];
      }
      return [...prevItem];
    },
    []
  );

  const mappedGlobalFilters = (selectedGlobalFilters || [])
    .filter((f) => f.selected)
    .map((globalFilter) => ({
      // eslint-disable-next-line max-len
      id: mapFilterId(globalFilter.filterId, locale),
      values: [globalFilter.filterValue],
    }));
  const distinctFilters = [
    ...mappedLocalFilters,
    ...mappedGlobalFilters,
  ].reduce((acc: ReqFilterItem[], currentValue) => {
    const existing = acc.find((filter) => filter.id === currentValue.id);
    if (!existing) {
      return [...acc, currentValue];
    }
    return acc;
  }, []);
  const req: BrowseCategoryProductsContract = {
    page,
    count: pageSize,
    id: `${id}`,
    filters: distinctFilters,
  };
  if (selectedSort) {
    req.sorting = {
      type: selectedSort?.type,
      direction: selectedSort?.direction,
    };
  }
  const response = await axios.post<SearchResponseContract>(
    `${publicRuntimeConfig.apiUrl}/${Endpoints.category}`,
    req,
    {
      headers: resolveHeaders(token, locale, addressId),
    }
  );
  return response.data;
};

type SearchProps = {
  locale?: string;
  pageSize?: number;
  page: number;
  term: string | undefined;
  addressId: string | undefined;
  token?: string | undefined;
  selectedLocalFilters?: FilterItemOption[] | undefined;
};
export const searchProducts = async ({
  locale,
  pageSize = 30,
  page,
  addressId,
  token,
  term,
  selectedLocalFilters,
}: SearchProps) => {
  const mappedLocalFilters = (selectedLocalFilters || []).reduce(
    (prevItem: ReqFilterItem[], current) => {
      let existing = prevItem.find((filter) => filter.id === current.parentId);
      if (!existing) {
        existing = {
          id: current.parentId,
          values: [current.title.value],
        };
        return [...prevItem, existing];
      } else {
        existing.values = [...existing.values, current.title.value];
      }
      return [...prevItem];
    },
    []
  );

  const qs = {
    search: term || '',
    count: `${pageSize}`,
    page: `${page}`,
    filters: mappedLocalFilters,
  };
  const response = await axios.post<SearchResponseContract>(
    `${publicRuntimeConfig.apiUrl}/${Endpoints.searchProducts}`,
    qs,
    {
      headers: resolveHeaders(token, locale, addressId),
    }
  );
  return response.data;
};

export const fetchCategoryBySlug = async (
  slug: string,
  locale?: string,
  req?: IncomingMessage
) => {
  try {
    const addressId = getAddressId(req);
    const token = getTokenFromCookie(req);
    const response = await axios.get<CategoryTreeItem>(
      `${publicRuntimeConfig.apiUrl}/${Endpoints.categoryBySlug}`,
      {
        headers: resolveHeaders(token, locale, addressId),
        params: {
          urlSlug: `${slug}`.replace('/', '').toLowerCase(),
        },
      }
    );
    return response.data;
  } catch (error) {
    //console.log(getErrorMessage(error));
    return null;
  }
};

export const fetchCategoriesTree = async (
  locale?: string,
  req?: IncomingMessage
): Promise<CategoryTree> => {
  const addressId = getAddressId(req);
  const token = getTokenFromCookie(req);
  try {
    const response = await axios.get<CategoryTree>(
      `${publicRuntimeConfig.apiUrl}/${Endpoints.categoriesTree}`,
      {
        headers: resolveHeaders(token, locale, addressId),
      }
    );
    return response.data;
  } catch (error) {
    //console.log(getErrorMessage(error));
    return { categories: [] };
  }
};

export const fetchBreadCrumbs = async (
  locale?: string,
  code?: string,
  req?: IncomingMessage
): Promise<BreadCrumbsItem[]> => {
  try {
    const addressId = getAddressId(req);
    const token = getTokenFromCookie(req);
    const response = await axios.get<BreadCrumbsItem[]>(
      `${publicRuntimeConfig.apiUrl}/${Endpoints.inventoryBreadCrumb}`,
      {
        headers: resolveHeaders(token, locale, addressId),
        params: {
          code: `${code}`,
        },
      }
    );
    return response.data || [];
  } catch (error) {
    //console.log(getErrorMessage(error));
    return [];
  }
};
