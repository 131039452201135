import { AddressTypeEnum, DeliveryAddressContract } from 'epromo-types';

export function isDeliveryAddressB2c(
  item: string | DeliveryAddressContract | AddressTypeEnum | undefined
) {
  if (!item) {
    return true;
  }
  const key = 'cashNCarry';

  if (item && typeof item === 'string') {
    return item.toLowerCase().includes(key.toLowerCase());
  }
  if ((item as DeliveryAddressContract).type) {
    return (item as DeliveryAddressContract).type === key;
  }
  return (item as AddressTypeEnum) === AddressTypeEnum.cashNCarry;
}
