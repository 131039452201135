import React from 'react';
import { twMerge } from 'tailwind-merge';
import dynamic from 'next/dynamic';

const emailRed = dynamic(
  () => import('../assets/icons/emailRed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const emailBlue = dynamic(
  () => import('../assets/icons/emailBlue.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const check = dynamic(
  () => import('../assets/images/check.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const genericImg = dynamic(
  () => import('../assets/icons/genericImg.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const eco = dynamic(
  () => import('../assets/icons/eco.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const glutenFree = dynamic(
  () => import('../assets/icons/glutenFree.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const lactoseFree = dynamic(
  () => import('../assets/icons/lactoseFree.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const noSugar = dynamic(
  () => import('../assets/icons/noSugar.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const school = dynamic(
  () => import('../assets/icons/school.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const vegetarian = dynamic(
  () => import('../assets/icons/vegetarian.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const vegan = dynamic(
  () => import('../assets/icons/vegan.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cart = dynamic(
  () => import('../assets/icons/cart.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const heartFilled = dynamic(
  () => import('../assets/icons/heartFilled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const list = dynamic(
  () => import('../assets/icons/list.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const reorder = dynamic(
  () => import('../assets/icons/reorder.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const addToList = dynamic(
  () => import('../assets/icons/addToList.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const addedToList = dynamic(
  () => import('../assets/icons/addedToList.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const pack = dynamic(
  () => import('../assets/icons/pack.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const saleFlagVertical = dynamic(
  () => import('../assets/icons/saleFlagVertical.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const thermo = dynamic(
  () => import('../assets/icons/thermo.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const frozen = dynamic(
  () => import('../assets/icons/frozen.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);

const saleFlagHorizontal = dynamic(
  () => import('../assets/icons/saleFlagHorizontal.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);

const copy = dynamic(
  () => import('../assets/icons/copy.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const favoritesB2B = dynamic(
  () => import('../assets/icons/favoritesB2B.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const favoritesB2C = dynamic(
  () => import('../assets/icons/favoritesB2C.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cartSolid = dynamic(
  () => import('../assets/icons/cart_solid.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const trashBin = dynamic(
  () => import('../assets/icons/trashbin.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const trashBinSolid = dynamic(
  () => import('../assets/icons/trashbin_solid.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const checkMark = dynamic(
  () => import('../assets/icons/checkmark.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const chevronRight = dynamic(
  () => import('../assets/icons/chevronRight.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const expand = dynamic(
  () => import('../assets/icons/expand.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const truckOutline = dynamic(
  () => import('../assets/icons/truck_outline.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const truckSolid = dynamic(
  () => import('../assets/icons/truck_solid.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const alcohol = dynamic(
  () => import('../assets/icons/alcohol.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);

const bars = dynamic(
  () => import('../assets/icons/bars.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const invoice = dynamic(
  () => import('../assets/icons/invoice.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const calendar = dynamic(
  () => import('../assets/icons/calendar.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const arrowUp = dynamic(
  () => import('../assets/icons/arrow_up.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const payWithDefferal = dynamic(
  () => import('../assets/icons/pay_with_defferal.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const payWithBankLink = dynamic(
  () => import('../assets/icons/pay_with_banklink.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const exclamation = dynamic(
  () => import('../assets/icons/exclamation.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const bigCartB2b = dynamic(
  () => import('../assets/icons/bigCartB2b.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const bigCartB2c = dynamic(
  () => import('../assets/icons/bigCartB2c.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const next = dynamic(
  () => import('../assets/icons/next.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const back = dynamic(
  () => import('../assets/icons/back.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const locationFilled = dynamic(
  () => import('../assets/icons/locationFilled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cookie = dynamic(
  () => import('../assets/icons/cookie.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const clock = dynamic(
  () => import('../assets/icons/clock.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const checkmark = dynamic(
  () => import('../assets/icons/checkmark.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const successFilled = dynamic(
  () => import('../assets/icons/success_filled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cartCheck = dynamic(
  () => import('../assets/icons/cart_check2.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const help = dynamic(
  () => import('../assets/icons/help.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const threeDots = dynamic(
  () => import('../assets/icons/three-dots.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const attention = dynamic(
  () => import('../assets/icons/attention.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const attentionFilled = dynamic(
  () => import('../assets/icons/attention_filled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const warningFilled = dynamic(
  () => import('../assets/icons/warning_filled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const infoFilled = dynamic(
  () => import('../assets/icons/info_filled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const headSetMic = dynamic(
  () => import('../assets/icons/headset_mic.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const footerFb = dynamic(
  () => import('../assets/icons/soc_fb.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const footerInsta = dynamic(
  () => import('../assets/icons/soc_insta.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const footerYt = dynamic(
  () => import('../assets/icons/soc_youtube.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const burger = dynamic(
  () => import('../assets/icons/burger.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const userPlus = dynamic(
  () => import('../assets/icons/userPlus.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const categories = dynamic(
  () => import('../assets/icons/categories.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const chatOutlined = dynamic(
  () => import('../assets/icons/chat_outlined.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const chevronDown = dynamic(
  () => import('../assets/icons/chevronDown.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const logout = dynamic(
  () => import('../assets/icons/logout.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const mapOutline = dynamic(
  () => import('../assets/icons/mapOutline.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const user = dynamic(
  () => import('../assets/icons/user.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const arrowLeft = dynamic(
  () => import('../assets/icons/arrowLeft.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cookingHat = dynamic(
  () => import('../assets/icons/cooking_hat.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const youtube = dynamic(
  () => import('../assets/icons/youtube.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cardClient = dynamic(
  () => import('../assets/icons/cardClient.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const shoppingBasket = dynamic(
  () => import('../assets/icons/shoppingBasket.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const cardPayment = dynamic(
  () => import('../assets/icons/cardPayment.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const info = dynamic(
  () => import('../assets/icons/info.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const users = dynamic(
  () => import('../assets/icons/users.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const edit = dynamic(
  () => import('../assets/icons/edit.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const locationBgBlue = dynamic(
  () => import('../assets/icons/locationBgBlue.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const locationBgRed = dynamic(
  () => import('../assets/icons/locationBgRed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const blankCardRed = dynamic(
  () => import('../assets/icons/blankCardRed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const phone = dynamic(
  () => import('../assets/icons/phone.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const circlePhone = dynamic(
  () => import('../assets/icons/circlePhone.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const storeMapPin = dynamic(
  () => import('../assets/icons/storeMapPin.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const mail = dynamic(
  () => import('../assets/icons/mail.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const deliveryBox = dynamic(
  () => import('../assets/icons/deliveryBox.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const deliveryBoxCheckmark = dynamic(
  () => import('../assets/icons/deliveryBoxCheckmark.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const businessOffice = dynamic(
  () => import('../assets/icons/business_office.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const invoiceBlankRed = dynamic(
  () => import('../assets/icons/invoiceBlankRed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const invoiceBlankBlue = dynamic(
  () => import('../assets/icons/invoiceBlankBlue.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const allCategories = dynamic(
  () => import('../assets/icons/allCategories.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const grain = dynamic(
  () => import('../assets/icons/grain.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const eye = dynamic(
  () => import('../assets/icons/eye.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const eyeSlashed = dynamic(
  () => import('../assets/icons/eyeSlashed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const close = dynamic(
  () => import('../assets/icons/close.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const fax = dynamic(
  () => import('../assets/icons/fax.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const smartphone = dynamic(
  () => import('../assets/icons/smartphone.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const home = dynamic(
  () => import('../assets/icons/home.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const download = dynamic(
  () => import('../assets/icons/downloadIcon.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const document = dynamic(
  () => import('../assets/icons/document.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const documentPdf = dynamic(
  () => import('../assets/icons/documentPdf.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const balance = dynamic(
  () => import('../assets/icons/balance.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const tobacco = dynamic(
  () => import('../assets/icons/tobacco.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const stars = dynamic(
  () => import('../assets/icons/stars.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const chatFilled = dynamic(
  () => import('../assets/icons/chat_filled.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const noTemplatesB2b = dynamic(
  () => import('../assets/icons/no_templates_b2b.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const noTemplatesB2c = dynamic(
  () => import('../assets/icons/no_templates_b2c.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const invoiceChecked = dynamic(
  () => import('../assets/icons/invoiceChecked.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const emailConfirmed = dynamic(
  () => import('../assets/icons/email_confirmed.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const replaceItemsNoItems = dynamic(
  () => import('../assets/icons/replaceItemsNoItems.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);
const heart = dynamic(
  () => import('../assets/icons/heart.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);

const deliveredBoxCheckmark = dynamic(
  () => import('../assets/icons/deliveredBoxCheckmark.svg').then((mod) => mod),
  {
    loading: () => <span></span>,
  }
);

export const icons = {
  emailRed,
  emailBlue,
  check,
  genericImg,
  eco,
  glutenFree,
  lactoseFree,
  noSugar,
  school,
  vegetarian,
  vegan,
  cart,
  heart,
  heartFilled,
  list,
  reorder,
  addToList,
  addedToList,
  pack,
  saleFlagVertical,
  thermo,
  frozen,
  saleFlagHorizontal,
  copy,
  favoritesB2B,
  favoritesB2C,
  cartSolid,
  trashBin,
  checkMark,
  chevronRight,
  expand,
  truckOutline,
  truckSolid,
  alcohol,
  bars,
  invoice,
  calendar,
  arrowUp,
  trashBinSolid,
  payWithDefferal,
  payWithBankLink,
  exclamation,
  bigCartB2b,
  bigCartB2c,
  next,
  back,
  locationFilled,
  cookie,
  clock,
  checkmark,
  successFilled,
  cartCheck,
  help,
  threeDots,
  attention,
  attentionFilled,
  warningFilled,
  infoFilled,
  headSetMic,
  footerFb,
  footerInsta,
  footerYt,
  burger,
  userPlus,
  categories,
  chatOutlined,
  chevronDown,
  logout,
  mapOutline,
  user,
  arrowLeft,
  cookingHat,
  youtube,
  cardClient,
  shoppingBasket,
  cardPayment,
  info,
  users,
  edit,
  locationBgBlue,
  locationBgRed,
  blankCardRed,
  phone,
  circlePhone,
  storeMapPin,
  mail,
  deliveryBox,
  deliveryBoxCheckmark,
  businessOffice,
  invoiceBlankBlue,
  invoiceBlankRed,
  allCategories,
  grain,
  eye,
  eyeSlashed,
  close,
  fax,
  smartphone,
  home,
  download,
  document,
  documentPdf,
  balance,
  tobacco,
  stars,
  chatFilled,
  noTemplatesB2b,
  noTemplatesB2c,
  invoiceChecked,
  emailConfirmed,
  replaceItemsNoItems,
  deliveredBoxCheckmark,
};

export type IconName = keyof typeof icons;

export interface IconProps extends React.SVGProps<SVGElement> {
  name?: IconName;
}

export const Icon = ({ name, className, ...rest }: IconProps) => {
  if (!name) return null;

  const icon = icons[name];

  const iconWithProps = React.createElement(icon ?? icons['infoFilled'], {
    // @ts-ignore
    className: twMerge('size-6 fill-current shrink-0', className),
    ...rest,
  });

  return iconWithProps;
};
