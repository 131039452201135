import getConfig from 'next/config';

import { Language, LanguagesEnum } from 'epromo-types';

import { I18 } from 'ui/utils';

const { publicRuntimeConfig } = getConfig();

export const getGreetingName = (locale: LanguagesEnum, name?: string) => {
  if (!name) return '';

  if (locale !== LanguagesEnum.lt) return name;

  const NAME_ENDS_MAPPER: Record<string, string> = {
    a: 'a',
    as: 'ai',
    ė: 'e',
    is: 'i',
    us: 'au',
    ys: 'y',
  };

  const NAME_ENDS = Object.keys(NAME_ENDS_MAPPER);

  const index = NAME_ENDS.findIndex((end) => name.endsWith(end));

  if (index > -1) {
    const currentEnding = NAME_ENDS[index];
    const targetEnding = NAME_ENDS_MAPPER[currentEnding];
    const regex = new RegExp(`${currentEnding}$`);

    return name.replace(regex, targetEnding);
  }
  return name;
};

export const SupportedLanguages: Array<Language> = [
  {
    name: 'Lithuanian',
    flag: '/flags/lt.svg',
    code: LanguagesEnum.lt,
  },
  { name: 'English', flag: '/flags/gb.svg', code: LanguagesEnum.en },
  { name: 'Latvian', flag: '/flags/lv.svg', code: LanguagesEnum.lv },
  { name: 'Estonian', flag: '/flags/et.svg', code: LanguagesEnum.ee },
];

export function checkIfLatvianDomain() {
  return publicRuntimeConfig.apiUrl?.includes('m-lv-');
}

export function checkIfEstonianDomain() {
  return publicRuntimeConfig.apiUrl?.includes('m-ee-');
}

export function resolveLocaleForPriceFormatter(locale?: string) {
  if (checkIfLatvianDomain()) {
    return 'lv-LV';
  }
  if (checkIfEstonianDomain()) {
    return 'et-EE';
  }
  return 'lt-LT';
}

export function resolveDomainLangForCms(locale?: string) {
  const localeToUse = locale ? locale : publicRuntimeConfig.defaultLocale;
  if (checkIfLatvianDomain()) {
    if (localeToUse.includes('en')) {
      return 'lv_en';
    }
    return localeToUse;
  }
  if (checkIfEstonianDomain()) {
    if (localeToUse.includes('en')) {
      return 'ee_en';
    }
    return 'ee';
  }
  //if reach here means we are on lt
  if (localeToUse.includes('en')) {
    return 'lt_en';
  }
  return localeToUse;
}

export function resolveAltLangForCms(currentLocale?: string) {
  const localeToUse = currentLocale
    ? currentLocale
    : publicRuntimeConfig.defaultLocale;
  const altLangCode =
    `${publicRuntimeConfig.enabledLanguages}`
      .split(';')
      .filter((locale) => locale !== currentLocale)
      .shift() ?? localeToUse;

  return resolveDomainLangForCms(altLangCode);
}

export function getSupportedLanguages(t: I18): Array<Language> {
  const langCodes = `${publicRuntimeConfig.enabledLanguages}`.split(';');
  return SupportedLanguages.filter((lang) => langCodes.includes(lang.code)).map(
    (lang) => ({ ...lang, name: t(lang.name) })
  );
}

export function getPostalCodePrefix() {
  if (checkIfLatvianDomain()) {
    return 'LV -';
  }
  if (checkIfEstonianDomain()) {
    return '';
  }
  return 'LT -';
}

export function getFindPostalCodeUrl() {
  if (checkIfLatvianDomain()) {
    return 'https://pasts.lv/en/Category/Postal_Code_Look-up/';
  }
  if (checkIfEstonianDomain()) {
    return 'https://www.omniva.ee/private/zip_code_search';
  }
  return 'https://post.lt/pasto-kodu-ir-adresu-paieska';
}
