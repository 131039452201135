import {
  B2bDeliveryContract,
  B2bUpdateCartReq,
  ProductContract,
  ShoppingCartContract,
  UpdateCartReq,
  UpdateShoppingCartContract,
} from 'epromo-types';
import { useRouter } from 'next/router';

import { isDeliveryAddressB2c } from '../utils/address';
import { getAddressId, useCartStateTrackedStore } from '../store';
import { Endpoints, useMutationQuery } from '../index';
import { isCheckoutPage } from '../hooks';

export type AddToCartProps = {
  product: ProductContract;
  amount: number;
};

export const ADD_TO_CART_MUTATION_KEY = 'user-cart-add';
export const MUTATION_KEY_SUMMARY_SUFFIX = 'from-summary';

export function useAddToCart(onSuccess?: () => void, mutationKey?: string) {
  const { asPath } = useRouter();
  const {
    b2cCart,
    updateCartQueue,
    mapB2bCartResponse,
    mapB2cCartResponse,
    setTemporaryB2bAmounts,
  } = useCartStateTrackedStore();

  const updateCart = (
    newData: B2bDeliveryContract | ShoppingCartContract,
    request?: B2bUpdateCartReq[] | UpdateShoppingCartContract
  ) => {
    if (Array.isArray(request)) {
      setTemporaryB2bAmounts(request);
    }

    if (isCheckoutPage(asPath)) return;
    if (isB2cAddress) {
      mapB2cCartResponse(
        newData as ShoppingCartContract,
        request as UpdateShoppingCartContract
      );
      return;
    }

    mapB2bCartResponse(
      newData as B2bDeliveryContract,
      request as B2bUpdateCartReq[]
    );
  };

  const addressId = getAddressId();
  const isB2cAddress = isDeliveryAddressB2c(addressId);

  const keySuffix = mutationKey ? `-${mutationKey}` : '';
  const key = `${ADD_TO_CART_MUTATION_KEY}${keySuffix}`;

  const {
    mutate: b2bUpdateCart,
    mutateAsync: b2bUpdateCartAsync,
    isPending: b2bIsAdding,
  } = useMutationQuery<B2bDeliveryContract, B2bUpdateCartReq[]>({
    mutationKey: [key],
    endPoint: Endpoints.b2bUpdateProductInCart,
    onSuccess: (result, req) => {
      updateCart(result, req as B2bUpdateCartReq[]);
      onSuccess?.();
    },
  });

  const {
    mutate: b2cUpdateCart,
    mutateAsync: b2cUpdateCartAsync,
    isPending: b2cIsAdding,
  } = useMutationQuery<ShoppingCartContract, UpdateShoppingCartContract>(
    {
      mutationKey: [key],
      endPoint: Endpoints.b2cUpdateProductInCart,
      onSuccess: (result, req) => {
        updateCart(result, req as UpdateShoppingCartContract);
        onSuccess?.();
      },
    },
    {
      method: 'put',
    }
  );

  const updateCartFromCatalog = async (payload: UpdateCartReq[]) => {
    if (!isB2cAddress) {
      await b2bUpdateCartAsync(
        payload.map(({ product, amount, invoiceNumber, slotId }) => ({
          amount,
          productId: product.id,
          invoiceNumber,
        }))
      );
    } else {
      const cartCopy = [...(b2cCart?.cartItems || [])];
      payload.forEach((cartItem) => {
        const currentProductIdx = cartCopy.findIndex(
          (prod) => prod.product.id === cartItem.product.id
        );
        if (currentProductIdx >= 0) {
          cartCopy[currentProductIdx].amount = cartItem.amount;
        } else {
          cartCopy.push({
            amount: cartItem.amount,
            product: {
              ...cartItem.product,
            },
          });
        }
      });
      b2cUpdateCart({
        cartItems: cartCopy.filter((item) => item.amount > 0),
      });
    }
  };

  const addToCart = ({ product, amount }: AddToCartProps) => {
    if (!isB2cAddress) {
      updateCartQueue(product, amount);
    } else {
      const cartCopy = [...(b2cCart?.cartItems || [])];
      const currentProductIdx = cartCopy.findIndex(
        (prod) => prod.product.id === product.id
      );
      if (currentProductIdx >= 0) {
        cartCopy[currentProductIdx].amount = amount;
      } else {
        cartCopy.push({
          amount,
          product: {
            ...product,
          },
        });
      }
      b2cUpdateCart({
        cartItems: cartCopy,
      });
    }
  };

  const addToCartMultiple = async (
    items: AddToCartProps[],
    isAsync?: boolean
  ) => {
    if (!isB2cAddress) {
      if (isAsync) {
        await b2bUpdateCartAsync(
          items.map(({ product, amount }) => ({
            amount,
            productId: product.id,
            invoiceNumber: 0,
          }))
        );
        return;
      }

      b2bUpdateCart(
        items.map(({ product, amount }) => ({
          amount,
          productId: product.id,
          invoiceNumber: 0,
        }))
      );
      return;
    }
    const cartCopy = [...(b2cCart?.cartItems || [])];
    items.forEach(({ product, amount }) => {
      const currentProductIdx = cartCopy.findIndex(
        (prod) => prod.product.id === product.id
      );
      if (currentProductIdx >= 0) {
        cartCopy[currentProductIdx].amount = amount;
      } else {
        cartCopy.push({
          amount,
          product,
        });
      }
    });

    if (isAsync) {
      await b2cUpdateCartAsync({
        cartItems: cartCopy.filter((item) => item.amount > 0),
      });
      return;
    }
    b2cUpdateCart({
      cartItems: cartCopy.filter((item) => item.amount > 0),
    });
  };

  return {
    addToCart,
    addToCartMultiple,
    isAddingToCart: b2bIsAdding || b2cIsAdding,
    updateCartFromCatalog,
  };
}
