import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';

import { storage } from '../helpers';

type VerificationProps = {
  isSent: boolean;
  sentAt: number;
};

export interface AppData {
  mobileMenuOpen: boolean;
  categoriesMenuOpen: boolean;
  emailVerificationLink: VerificationProps;
  phoneVerificationCode: VerificationProps;
  searchInputHasFocus: boolean;
  searchIsOpen: boolean;
  searchInput?: string;
  categoriesDropMenuOpen: boolean;
  usedPaymentReferences: string[];
  multicartPopupCartIds?: string;
}

export interface AppState extends AppData {
  setMobileMenuOpen: (mobileMenuOpen: boolean) => void;
  setCategoriesMenuOpen: (categoriesMenuOpen: boolean) => void;
  setEmailVerificationSent: (isSent?: boolean) => void;
  setPhoneVerificationSent: (isSent?: boolean) => void;
  setCategoriesDropMenuOpen: (categoriesDropMenuOpen: boolean) => void;
  setSearchInputHasFocus: (searchInputHasFocus: boolean) => void;
  setSearchInput: (searchInput: string | undefined) => void;
  setUsedPaymentReferences: (value?: string) => void;
  openSearch: () => void;
  closeSearch: () => void;
  setMulticartPopupCartIds: (ids: string) => void;
}

const initialAppState: AppData = {
  mobileMenuOpen: false,
  categoriesMenuOpen: false,
  emailVerificationLink: {
    isSent: false,
    sentAt: 0,
  },
  phoneVerificationCode: {
    isSent: false,
    sentAt: 0,
  },
  searchInputHasFocus: false,
  searchInput: '',
  searchIsOpen: false,
  categoriesDropMenuOpen: false,
  usedPaymentReferences: [],
};

export const APP_STORE_KEY = 'app';
export const useAppState = create<AppState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialAppState,
        openSearch: () => {
          set({
            searchIsOpen: true,
          });
          document.body.classList.add('overflow-hidden');
        },
        setCategoriesDropMenuOpen: (categoriesDropMenuOpen: boolean) => {
          set({
            categoriesDropMenuOpen,
          });
        },
        closeSearch: () => {
          set({
            searchIsOpen: false,
            searchInputHasFocus: false,
            searchInput: '',
          });
          document.body.classList.remove('overflow-hidden');
        },
        setSearchInput: (searchInput: string | undefined) => {
          set({
            searchInput,
          });
        },
        setMobileMenuOpen: (mobileMenuOpen: boolean) => {
          set({
            mobileMenuOpen,
          });
        },
        setSearchInputHasFocus: (searchInputHasFocus: boolean) => {
          set({
            searchInputHasFocus,
          });
        },
        setCategoriesMenuOpen: (categoriesMenuOpen: boolean) => {
          set({
            categoriesMenuOpen,
          });
        },
        setEmailVerificationSent: (isSent: boolean = true) => {
          set({
            emailVerificationLink: {
              isSent,
              sentAt: isSent ? Date.now() : 0,
            },
          });
        },
        setPhoneVerificationSent: (isSent: boolean = true) => {
          set({
            phoneVerificationCode: {
              isSent,
              sentAt: isSent ? Date.now() : 0,
            },
          });
        },
        setUsedPaymentReferences: (value?: string) => {
          if (!value) return;
          const prevState = get().usedPaymentReferences;
          const exists = !!prevState.find((x) => x === value);

          if (!exists)
            return set({ usedPaymentReferences: [...prevState, value] });

          return;
        },
        setMulticartPopupCartIds: (multicartPopupCartIds: string) => {
          set({ multicartPopupCartIds });
        },
      }),
      {
        name: APP_STORE_KEY,
        storage,
      }
    )
  )
);
export const useAppStateTrackedStore = createTrackedSelector(useAppState);
