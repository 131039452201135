/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import {
  CategoryContract,
  SearchResponseContract,
} from 'epromo-types/Inventory';
import { ProductContract } from 'epromo-types';

import { Endpoints, useGetQuery } from '../index';
import { useUserSearch } from '../store';

const SHOW_CATEGORIES_COUNT = 5;
const SHOW_PRODUCTS_COUNT = 5;

export function useQuickSearchProducts(searchInput: string | undefined) {
  const [debouncedSearch, setDebouncedSearch] = useState<string | undefined>(
    searchInput
  );
  const { setProducts } = useUserSearch();
  const [data, setData] = useState<(CategoryContract | ProductContract)[]>([]);
  const [showAll, setShowAll] = useState(false);

  const {
    isFetched,
    isLoading,
    refetch,
    data: searchResData,
  } = useGetQuery<SearchResponseContract>({
    queryKey: [
      'quick-search-products',
      {
        search: debouncedSearch,
        count: SHOW_PRODUCTS_COUNT,
        page: 1,
      },
    ],
    endPoint: Endpoints.searchProducts,
    enabled: false,
    onError: () => {
      setData([]);
    },
  });

  useEffect(() => {
    if (isFetched) {
      let options: (CategoryContract | ProductContract)[] = [];

      if (!!searchResData?.products.length) {
        setProducts(searchResData.products);
        options = [
          ...searchResData.categories.slice(0, SHOW_CATEGORIES_COUNT),
          ...searchResData.products,
        ];

        setShowAll(searchResData.products.length > 1);
      }

      setData(options);
    }
  }, [isFetched, searchResData, setProducts]);

  useEffect(() => {
    if (debouncedSearch) {
      refetch();
    } else {
      setData([]);
    }
  }, [debouncedSearch]);

  return {
    isLoading,
    isFetched,
    setDebouncedSearch,
    refetch,
    setData,
    debouncedSearch,
    data,
    showAll,
  };
}
