import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@heroicons/react/24/outline';

export function CloseDialogButton({
  onClick,
  className,
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className={twMerge(
        'rounded-full bg-black text-white',
        'px-1 py-1 focus:outline-none focus:ring-2',
        'focus:ring-black focus:ring-offset-2',
        className
      )}
      onClick={onClick}
    >
      <span className="sr-only">Close</span>
      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
}
