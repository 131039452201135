import { TFunction } from 'next-i18next';
import { GetServerSidePropsContext } from 'next/types';
import getConfig from 'next/config';

import { EmployeeContract, ProductContract } from 'epromo-types';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { IInnerPage } from 'epromo-lib-cms/types/payload-types';

import {
  b2bUserMenuLinks,
  b2cUserMenuLinks,
  baseUserMenuLinks,
} from '../constants';

export const getUserMenuLinks = (isB2cAddress: boolean) => {
  const extraLinks = isB2cAddress ? b2cUserMenuLinks : b2bUserMenuLinks;

  return [...baseUserMenuLinks, ...extraLinks];
};

export const parseCardExpiryDate = (expiresStr: string) => {
  const month = expiresStr.slice(-2);

  const years = Number(expiresStr.replace(month, ''));
  const currentDecade = 2000;

  const yearsAdded = currentDecade + years;

  return `${month}/${yearsAdded}`;
};

export const buildPermissionsString = (
  employee: EmployeeContract,
  t: TFunction
) => {
  let firstWordPushed = false;

  const permissionsArray = Object.keys(employee).reduce(
    (acc: string[], val: string) => {
      if (employee[val as keyof EmployeeContract] === true) {
        if (firstWordPushed === false) {
          acc.push(`${t('can')} `);
        }

        firstWordPushed = true;

        acc.push(t(`${val}`));
        acc.push(', ');
      }

      return acc;
    },
    []
  );

  permissionsArray.pop();

  return permissionsArray;
};

export const resolveOverpayment = (creditBalance?: number | undefined) => {
  if (!creditBalance || creditBalance > 0) return null;

  return creditBalance * -1;
};

export type AppTheme = 'b2b' | 'b2c';

export const changeTheme = (theme: AppTheme) =>
  document.getElementsByTagName('html')[0].setAttribute('data-theme', theme);

export function isObject(value: unknown) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

type ResolvePageTitleProps = {
  category?: CategoryTreeItem;
  product?: ProductContract;
  page?: IInnerPage;
};

export function resolvePageTitle({
  product,
  category,
  page,
}: ResolvePageTitleProps) {
  if (page) {
    return page.title;
  }
  if (product) {
    return `${product.name} | ePromo`;
  }
  if (category) {
    return `${category.name} | ePromo`;
  }
  return 'ePromo';
}

// Flags not returned by flagsapi.com
const unavailableFlags = [
  'BQ',
  'BV',
  'IO',
  'GP',
  'GF',
  'UM',
  'HM',
  'XK',
  'BL',
  'SX',
  'PM',
  'SJ',
];

const FLAGS_API_URL = 'https://flagsapi.com/COUNTRY_CODE/flat/48.png';

export const resolveCountryFlagUrl = (countryImageName: string) => {
  const codeStr = countryImageName.split('/').pop() ?? '';
  const upperCased = codeStr?.toUpperCase();

  if (unavailableFlags.includes(upperCased)) {
    return 'NO_FLAG';
  }

  const imgUrl = FLAGS_API_URL.replace('COUNTRY_CODE', upperCased);
  return imgUrl;
};

export const getServerSideHelpers = (
  locale: string | undefined,
  req: GetServerSidePropsContext['req']
) => {
  const { publicRuntimeConfig } = getConfig();

  return {
    tokenExists: !!req.cookies.token,
    localeToUse: locale ?? (publicRuntimeConfig.defaultLocale as string),
  };
};

export function downloadFromUrl(data: Blob, fileName: string) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(data);
  a.setAttribute('download', fileName);
  a.click();
  a.remove();
}

export const shouldSentryCapture = (idToDebug: string): boolean => {
  const { publicRuntimeConfig } = getConfig();

  const debugIds = (
    publicRuntimeConfig.sentryDebugIds as string | undefined
  )?.split(';');

  return !!debugIds?.find((id) => id === idToDebug);
};
