import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';

import {
  B2bDeliveryContract,
  B2BDeliveryDatesContract,
  B2BOrderContinueContract,
  B2BOrderContract,
  B2BShoppingCartItemModel,
  B2bSlotContract,
  B2bUpdateCartReq,
  B2CSelectedDeliverySlot,
  DeliverySlotListContract,
  ProductContract,
  ShoppingCartContract,
  ShoppingCartItemModel,
  UpdateShoppingCartContract,
} from 'epromo-types';
import { B2CCompanyContract } from 'epromo-types/Company';
import {
  CheckoutSummaryContract,
  PaymentOption,
  WebB2BGetCheckoutSummaryContract,
} from 'epromo-types/Checkout';
import { FieldErrors } from 'react-hook-form';

import { checkIfLatvianDomain } from '../../utils/language';
import { calculateB2cTotalPrice } from '../../utils/priceHelpers';

const flattenCartItems = (carts: B2bSlotContract[]) =>
  carts.reduce(
    (prevSlot: B2BShoppingCartItemModel[], nextSlot) => [
      ...prevSlot,
      ...nextSlot.cartItems,
    ],
    []
  );

const buildProductsDictionary = (
  cartItems: B2BShoppingCartItemModel[]
): Record<string, ShoppingCartItemModel> =>
  cartItems.reduce((prev: Record<string, ShoppingCartItemModel>, current) => {
    if (!prev[current.product.id]) {
      prev[current.product.id] = {
        product: current.product,
        amount: current.amount,
        measureUnit: current.product.measureUnit,
      };
    } else {
      prev[current.product.id] = {
        product: current.product,
        amount: current.amount,
        measureUnit: current.product.measureUnit,
      };
    }
    return prev;
  }, {});

const getB2BTotalPrice = (slots: B2bSlotContract[]) => {
  return slots.reduce((prev, curr) => {
    return prev + curr.priceWithoutVat;
  }, 0);
};

export interface CartData {
  totalPrice: number;
  totalItems: number;
  cartItemsDictionary: Record<string, ShoppingCartItemModel>;
  b2bCarts?: B2bDeliveryContract | undefined | null;
  b2bSelectedCart?: B2bSlotContract | undefined | null;
  b2cCart?: ShoppingCartContract | undefined | null;
  b2bSelectedCartItems: Record<number, B2BShoppingCartItemModel[]>;
  b2cSelectedCartItemsIds: string[];
  complyToAge: boolean;
  b2cSelectedDeliveryDate: B2CSelectedDeliverySlot;
  billingAddress?: B2CCompanyContract;
  showQuantityChangedDialog?: boolean;
  showOosDialog?: boolean;
  showDeliveryInfoDialog?: boolean;
  showAgeVerificationDialog?: boolean;
  b2bPaymentOption: PaymentOption;
  leftToFreeDelivery: number;
  leftToMinOrder: number;
  minAmount: number;
  freeDeliveryFee: number;
  customerPoNumber: string;
  agreedToTerms: boolean;
  cartContainsOosProducts: boolean;
  ageConfirmedWithSmartId: boolean;
  temporaryB2bAmounts?: Record<string, number>;
  ageVerificationNeeded: boolean;
  showB2cSelectDeliveryDateDialog?: boolean;
  isOver20CheckNeeded: boolean;
  isOver20Verified: boolean;
  placeOrderErrors?: FieldErrors<{ agreedToTerms: boolean }>;
  summary: CheckoutSummaryContract;
  b2bSummaries: B2BOrderContract[];
  b2bAvailableDeliveryDates: B2BDeliveryDatesContract[][];
  b2cDeliverySlots: DeliverySlotListContract[];
  b2bDeliverySlots: B2BDeliveryDatesContract[];
  isCheckoutFetched: boolean;
  isCheckoutLoading: boolean;
  isQueueActive: boolean;
}

export interface CartState extends CartData {
  setCustomerPoNumber: (customerPoNumber: string) => void;
  setAgreedToTerms: (agreedToTerms: boolean) => void;
  setAgeVerificationNeeded: (val: boolean) => void;
  setIsOver20Verified: (isOver20Verified: boolean) => void;
  setIsOver20CheckNeeded: (val: boolean) => void;
  setTotalItems: (totalItems: number) => void;
  setB2bCart: (data: B2bDeliveryContract) => void;
  selectB2bCart: (
    data?: B2bSlotContract | null | undefined,
    idx?: number
  ) => void;
  setB2cCart: (data: ShoppingCartContract) => void;
  setTotalPrice: (totalPrice: number) => void;
  setSelectedB2bCartItems: (
    items: Record<number, B2BShoppingCartItemModel[]>
  ) => void;
  setSelectedB2cCartItemsIds: (items: string[]) => void;
  setCartItemsDictionary: (
    items: Record<string, ShoppingCartItemModel>
  ) => void;
  setB2cDeliveryDate: (
    b2cSelectedDeliveryDate: B2CSelectedDeliverySlot
  ) => void;
  setBillingAddress: (billingAddress?: B2CCompanyContract) => void;
  setShowQtyChangedDialog: (showYn: boolean) => void;
  setShowOosDialog: (showYn: boolean) => void;
  setShowDeliveryInfoDialog: (showYn: boolean) => void;
  setShowAgeVerificationDialog: (showYn: boolean) => void;
  setB2bPaymentOption: (option: PaymentOption) => void;
  setLeftToFreeDelivery: (leftToFreeDelivery: number) => void;
  setLeftToMinOrder: (leftToMinOrder: number) => void;
  setMinOrderAmount: (minAmount: number) => void;
  setFreeDeliveryFee: (freeDeliveryFee: number) => void;
  setCartContainsOosProducts: (cartContainsOosProducts: boolean) => void;
  setAgeConfirmedWithSmartId: (isConfirmed: boolean) => void;
  setShowB2cSelectDeliveryDateDialog: (showYn: boolean) => void;
  setPlaceOrderErrors: (
    placeOrderErrors?: FieldErrors<{ agreedToTerms: boolean }>
  ) => void;
  resetCarts: () => void;
  setSummary: (summary?: CheckoutSummaryContract) => void;
  updateCartQueue: (
    product: ProductContract,
    amount: number,
    invoiceNumber?: number
  ) => void;
  setB2bDeliverySlots: (data: B2BDeliveryDatesContract[]) => void;
  setB2cDeliverySlots: (data: DeliverySlotListContract[]) => void;
  setCheckoutLoading: (data: boolean) => void;
  setCheckoutIsFetched: (data: boolean) => void;
  mapB2bCartResponse: (
    data: B2bDeliveryContract,
    request?: B2bUpdateCartReq[],
    isCheckoutPage?: boolean
  ) => void;
  mapB2bCartCheckoutResponse: (
    data: WebB2BGetCheckoutSummaryContract[],
    req?: B2BOrderContinueContract,
    isCheckoutPage?: boolean
  ) => void;
  setTemporaryB2bAmounts: (
    data: { productId: string; amount: number }[]
  ) => void;
  mapB2cCartResponse: (
    data: ShoppingCartContract,
    req?: UpdateShoppingCartContract,
    isCheckoutPage?: boolean
  ) => void;
  updateCartQueueV2: (
    items: {
      product: ProductContract;
      amount: number;
      invoiceNumber?: number;
    }[]
  ) => void;
  setIsQueueActive: (val: boolean) => void;
  resetIsQtyChanged: (productId: string) => void;
}

const defaultSummaryState: CheckoutSummaryContract = {
  availableCredit: 0,
  deliveryFee: 0,
  itemsPrice: 0,
  vat: [],
  paymentCards: [],
  reservationFee: 0,
  tareFee: 0,
  totalPrice: 0,
};

const initialAuthState: CartData = {
  totalPrice: 0,
  totalItems: 0,
  cartItemsDictionary: {},
  b2bCarts: null,
  b2cCart: null,
  b2bSelectedCartItems: [],
  b2cSelectedCartItemsIds: [],
  complyToAge: false,
  b2cSelectedDeliveryDate: { date: 0, selectedOption: null },
  billingAddress: undefined,
  b2bPaymentOption: 'credit',
  leftToFreeDelivery: 0,
  leftToMinOrder: 0,
  minAmount: 0,
  freeDeliveryFee: 0,
  agreedToTerms: false,
  customerPoNumber: '',
  cartContainsOosProducts: false,
  ageConfirmedWithSmartId: false,
  ageVerificationNeeded: false,
  isOver20CheckNeeded: false,
  isOver20Verified: false,
  summary: { ...defaultSummaryState },
  b2cDeliverySlots: [],
  b2bDeliverySlots: [],
  isCheckoutFetched: false,
  isCheckoutLoading: false,
  b2bSummaries: [],
  b2bAvailableDeliveryDates: [],
  isQueueActive: false,
};

export function findSelectedSlot(
  slot: B2bSlotContract,
  selectedSlot?: B2bSlotContract | null | undefined
) {
  return `${slot.deliveryDate}` === `${selectedSlot?.deliveryDate}`;
}

export const useCartState = create<CartState>()(
  devtools(
    (set, get) => ({
      ...initialAuthState,
      resetCarts: () => {
        set({
          ...initialAuthState,
        });
      },
      setIsQueueActive: (val) => {
        set({
          isQueueActive: val,
        });
      },
      setSummary: (summary?: CheckoutSummaryContract) => {
        set({
          summary: summary || { ...defaultSummaryState },
        });
      },
      setPlaceOrderErrors: (
        placeOrderErrors?: FieldErrors<{ agreedToTerms: boolean }>
      ) => {
        set({
          placeOrderErrors,
        });
      },
      setIsOver20Verified: (isOver20Verified: boolean) => {
        set({
          isOver20Verified,
        });
      },
      setIsOver20CheckNeeded: (isOver20CheckNeeded: boolean) => {
        set({
          isOver20CheckNeeded,
        });
      },
      setAgeVerificationNeeded: (ageVerificationNeeded: boolean) => {
        set({
          ageVerificationNeeded,
        });
      },
      setB2bPaymentOption: (b2bPaymentOption: PaymentOption) => {
        set({
          b2bPaymentOption,
        });
      },
      setCustomerPoNumber: (customerPoNumber: string) => {
        set({
          customerPoNumber,
        });
      },
      setCartContainsOosProducts: (cartContainsOosProducts: boolean) => {
        set({
          cartContainsOosProducts,
        });
      },
      setAgreedToTerms: (agreedToTerms: boolean) => {
        set({
          agreedToTerms,
        });
      },
      setB2bCart: (b2bCarts: B2bDeliveryContract) => {
        set({
          b2bCarts,
        });
      },
      setShowDeliveryInfoDialog: (showDeliveryInfoDialog: boolean) => {
        set({
          showDeliveryInfoDialog,
        });
      },
      setShowAgeVerificationDialog: (showAgeVerificationDialog: boolean) => {
        set({
          showAgeVerificationDialog,
        });
      },
      setFreeDeliveryFee: (freeDeliveryFee: number) => {
        set({
          freeDeliveryFee,
        });
      },
      setMinOrderAmount: (minAmount: number) => {
        set({
          minAmount,
        });
      },
      setLeftToFreeDelivery: (leftToFreeDelivery: number) => {
        set({
          leftToFreeDelivery,
        });
      },
      setLeftToMinOrder: (leftToMinOrder: number) => {
        set({
          leftToMinOrder,
        });
      },
      setShowOosDialog: (showOosDialog: boolean) => {
        set({
          showOosDialog,
        });
      },
      setShowQtyChangedDialog: (showQuantityChangedDialog: boolean) => {
        set({
          showQuantityChangedDialog,
        });
      },
      setBillingAddress: (billingAddress?: B2CCompanyContract) => {
        set({
          billingAddress,
        });
      },
      setSelectedB2bCartItems: (
        b2bSelectedCartItems: Record<number, B2BShoppingCartItemModel[]>
      ) => {
        set({
          b2bSelectedCartItems,
        });
      },
      setSelectedB2cCartItemsIds: (b2cSelectedCartItemsIds: string[]) => {
        set({
          b2cSelectedCartItemsIds,
        });
      },
      selectB2bCart: (
        b2bSelectedCart: B2bSlotContract | null | undefined,
        idx?: number
      ) => {
        if (idx !== undefined && !isNaN(idx)) {
          set({
            summary: get().b2bSummaries[idx],
            b2bDeliverySlots: get().b2bAvailableDeliveryDates[idx],
          });
        }
        set({
          b2bSelectedCart,
          leftToFreeDelivery: b2bSelectedCart?.leftToFreeDelivery,
          freeDeliveryFee: b2bSelectedCart?.freeDelivery,
          leftToMinOrder: b2bSelectedCart
            ? b2bSelectedCart.priceWithoutVat - b2bSelectedCart.minAmount
            : 0,
          minAmount: b2bSelectedCart?.minAmount,
          complyToAge: false,
          isOver20CheckNeeded: false,
        });
      },
      setB2cCart: (b2cCart: ShoppingCartContract) => {
        const hasComplyToAge = b2cCart.cartItems.find(
          (item) => item.product.ageToComply
        );
        set({
          b2cCart,
          complyToAge: !!hasComplyToAge,
          isOver20CheckNeeded:
            b2cCart.ageVerification && !checkIfLatvianDomain(),
        });
      },
      setTotalItems: (totalItems: number) => {
        set({
          totalItems,
        });
      },
      setTotalPrice: (totalPrice: number) => {
        set({
          totalPrice,
        });
      },
      setCartItemsDictionary: (
        cartItemsDictionary: Record<string, ShoppingCartItemModel>
      ) => {
        set({
          cartItemsDictionary,
        });
      },
      setB2cDeliveryDate: (
        b2cSelectedDeliveryDate: B2CSelectedDeliverySlot
      ) => {
        set({
          b2cSelectedDeliveryDate,
        });
      },
      setAgeConfirmedWithSmartId: (ageConfirmedWithSmartId: boolean) => {
        set({
          ageConfirmedWithSmartId,
        });
      },
      setShowB2cSelectDeliveryDateDialog: (
        showB2cSelectDeliveryDateDialog: boolean
      ) => {
        set({
          showB2cSelectDeliveryDateDialog,
        });
      },

      setB2bDeliverySlots: (b2bDeliverySlots: B2BDeliveryDatesContract[]) => {
        set({ b2bDeliverySlots });
      },
      setB2cDeliverySlots: (b2cDeliverySlots: DeliverySlotListContract[]) => {
        set({ b2cDeliverySlots });
      },
      updateCartQueue: (
        product: ProductContract,
        amount: number,
        invoiceNumber?: number
      ) => {
        const { cartItemsDictionary, b2bSelectedCart, b2cCart } = get();

        if (!cartItemsDictionary[product.id]) {
          cartItemsDictionary[product.id] = {
            product,
            amount,
            measureUnit: product.measureUnit,
            invoiceNumber: invoiceNumber || 0,
          };
        }

        cartItemsDictionary[product.id].amount = amount;

        cartItemsDictionary[product.id].invoiceNumber = invoiceNumber || 0;
        if (amount <= 0) {
          delete cartItemsDictionary[product.id];
        }
        if (b2bSelectedCart) {
          const pr = b2bSelectedCart.cartItems.find(
            (shopCartItem) => shopCartItem.product.id === product.id
          );
          if (pr) {
            pr.amount = amount;
          }
          if (amount <= 0) {
            b2bSelectedCart.cartItems = b2bSelectedCart.cartItems.filter(
              (shopCartItem) => shopCartItem.product.id !== product.id
            );
          }
        }
        if (b2cCart) {
          const pr = b2cCart.cartItems.find(
            (shopCartItem) => shopCartItem.product.id === product.id
          );
          if (pr) {
            pr.amount = amount;
          }
          if (amount <= 0) {
            b2cCart.cartItems = b2cCart.cartItems.filter(
              (shopCartItem) => shopCartItem.product.id !== product.id
            );
          }
        }
        set({
          cartItemsDictionary: {
            ...cartItemsDictionary,
          },
          b2bSelectedCart,
          b2cCart,
        });
      },
      updateCartQueueV2: (items) => {
        const { cartItemsDictionary, b2bSelectedCart, b2cCart } = get();
        items.forEach(({ product, amount, invoiceNumber }) => {
          if (!cartItemsDictionary[product.id]) {
            cartItemsDictionary[product.id] = {
              product,
              amount,
              measureUnit: product.measureUnit,
              invoiceNumber: invoiceNumber || 0,
            };
          }

          cartItemsDictionary[product.id].amount = amount;

          cartItemsDictionary[product.id].invoiceNumber = invoiceNumber || 0;
          if (amount <= 0) {
            delete cartItemsDictionary[product.id];
          }
          if (b2bSelectedCart) {
            const pr = b2bSelectedCart.cartItems.find(
              (shopCartItem) => shopCartItem.product.id === product.id
            );
            if (pr) {
              pr.amount = amount;
            }
            if (amount <= 0) {
              b2bSelectedCart.cartItems = b2bSelectedCart.cartItems.filter(
                (shopCartItem) => shopCartItem.product.id !== product.id
              );
            }
          }
          if (b2cCart) {
            const pr = b2cCart.cartItems.find(
              (shopCartItem) => shopCartItem.product.id === product.id
            );
            if (pr) {
              pr.amount = amount;
            }
            if (amount <= 0) {
              b2cCart.cartItems = b2cCart.cartItems.filter(
                (shopCartItem) => shopCartItem.product.id !== product.id
              );
            }
          }
        });
        set({
          cartItemsDictionary: {
            ...cartItemsDictionary,
          },
          b2bSelectedCart,
          b2cCart,
        });
      },
      setCheckoutLoading: (isCheckoutLoading: boolean) => {
        set({ isCheckoutLoading });
      },
      setCheckoutIsFetched: (isCheckoutFetched: boolean) => {
        set({ isCheckoutFetched });
      },
      mapB2cCartResponse: (
        result,
        req?: UpdateShoppingCartContract,
        isCheckoutPage?: boolean
      ) => {
        set({ cartContainsOosProducts: false });

        const totalPrice = calculateB2cTotalPrice(result.cartItems);

        const productsDictionary = result.cartItems.reduce(
          (prev: Record<string, ShoppingCartItemModel>, current) => {
            if (!prev[current.product.id]) {
              return {
                ...prev,
                [current.product.id]: {
                  product: current.product,
                  amount: current.amount,
                  measureUnit: current.product.measureUnit,
                },
              };
            }
            return { ...prev };
          },
          {}
        );
        if (isCheckoutPage) {
          set({
            totalItems: result.cartItems.length,
            totalPrice: calculateB2cTotalPrice(result.cartItems),
            leftToFreeDelivery: totalPrice - result.freeDeliveryPrice,
            freeDeliveryFee: result.freeDeliveryPrice,
            leftToMinOrder: totalPrice - result.minimumCartPrice,
            minAmount: result.minimumCartPrice,
            cartItemsDictionary: {
              ...productsDictionary,
            },
            b2cCart: result,
          });
        } else {
          set(() => {
            const productsItemsArr = Object.values(productsDictionary).map(
              (item) => item
            );

            return {
              totalItems: productsItemsArr.length,
              totalPrice: calculateB2cTotalPrice(productsItemsArr),
              b2cCart: result,
              cartItemsDictionary: productsDictionary,
            };
          });
        }
      },
      mapB2bCartResponse: (
        result: B2bDeliveryContract,
        request?: B2bUpdateCartReq[],
        isCheckoutPage?: boolean
      ) => {
        set({ cartContainsOosProducts: false });
        if (result.slots.length === 0) {
          set({
            totalItems: 0,
            totalPrice: 0,
            leftToMinOrder: 0,
            leftToFreeDelivery: 0,
            freeDeliveryFee: 0,
            b2bSelectedCart: null,
            b2bCarts: { slots: [] },
            cartItemsDictionary: {},
          });
          return;
        }

        const sortedByDate = result.slots.sort(
          (slotA, slotB) => slotA.deliveryDate - slotB.deliveryDate
        );

        const selectedCartIndex = isCheckoutPage
          ? sortedByDate.findIndex((slot) =>
              findSelectedSlot(slot, get().b2bSelectedCart)
            )
          : 0;

        const selectedCart = sortedByDate[selectedCartIndex] || sortedByDate[0];
        const productIdsToUpdate =
          request?.map((reqItem) => reqItem.productId) || [];

        const flattenedCartItems = flattenCartItems(sortedByDate);

        const productsDictionary = buildProductsDictionary(flattenedCartItems);

        if (isCheckoutPage) {
          set({
            b2bSelectedCart: selectedCart,
            totalItems: flattenedCartItems.length,
            totalPrice: getB2BTotalPrice(sortedByDate),
            leftToFreeDelivery: selectedCart.leftToFreeDelivery,
            freeDeliveryFee: selectedCart.freeDelivery,
            leftToMinOrder:
              selectedCart.priceWithoutVat - selectedCart.minAmount,
            minAmount: selectedCart.minAmount,
            cartItemsDictionary: {
              ...productsDictionary,
            },
            b2bCarts: { slots: sortedByDate },
          });
        } else {
          set({
            totalItems: flattenedCartItems.length,
            totalPrice: getB2BTotalPrice(sortedByDate),
            b2bSelectedCart: selectedCart,
            cartItemsDictionary: {
              ...productsDictionary,
            },
          });
        }
      },
      setTemporaryB2bAmounts: (data) => {
        set(({ temporaryB2bAmounts }) => {
          const objToSet = data.reduce((acc: Record<string, number>, val) => {
            acc[val.productId] = val.amount;
            return acc;
          }, {});

          return {
            temporaryB2bAmounts: { ...temporaryB2bAmounts, ...objToSet },
          };
        });
      },
      resetIsQtyChanged: (id: string) => {
        const b2bSelectedCart = get().b2bSelectedCart;

        if (b2bSelectedCart) {
          set({
            b2bSelectedCart: {
              ...b2bSelectedCart,
              cartItems: b2bSelectedCart.cartItems.map((item) => {
                const resetChanged = id === item.product.id;
                return {
                  ...item,
                  qtyChanged: resetChanged ? false : item.qtyChanged,
                };
              }),
            },
          });
        }
      },
      mapB2bCartCheckoutResponse: (
        result: WebB2BGetCheckoutSummaryContract[],
        req?: B2BOrderContinueContract,
        isCheckoutPage?: boolean
      ) => {
        set({ cartContainsOosProducts: false });

        if (result.length === 0) {
          set({
            totalItems: 0,
            totalPrice: 0,
            leftToMinOrder: 0,
            leftToFreeDelivery: 0,
            freeDeliveryFee: 0,
            b2bSelectedCart: null,
            b2bCarts: { slots: [] },
            cartItemsDictionary: {},
          });
          return;
        }
        const sortedByDate = result.sort(
          (slotA, slotB) => slotA.deliveryDate - slotB.deliveryDate
        );

        const flattenSlots: B2bSlotContract[] = [];
        let selectedCartIndex = 0;
        let selectedCart = sortedByDate[selectedCartIndex].slots[0];

        sortedByDate.forEach((cart, i) => {
          cart.slots.forEach((slot) => {
            const { changedProducts } = cart;
            const mappedSlot = {
              ...slot,
              cartItems: slot.cartItems.map((item) => {
                const qtyChanged = changedProducts.includes(item.product.id);
                return {
                  ...item,
                  qtyChanged,
                  maxQty: qtyChanged ? item.amount : undefined,
                };
              }),
            };

            if (
              isCheckoutPage &&
              findSelectedSlot(slot, get().b2bSelectedCart)
            ) {
              selectedCart = mappedSlot;
              selectedCartIndex = i;
            }
            flattenSlots.push(mappedSlot);
          });
        });

        const flattenedCartItems = flattenCartItems(flattenSlots);

        const productsDictionary = buildProductsDictionary(flattenedCartItems);

        set(({ temporaryB2bAmounts }) => {
          const addTemporaryAmounts = (
            slot: B2bSlotContract
          ): B2bSlotContract => {
            return {
              ...slot,
              cartItems: slot.cartItems.map((item) => {
                return {
                  ...item,
                  previousInvalidAmount: temporaryB2bAmounts?.[item.product.id],
                };
              }),
            };
          };

          const selectedCartWithTempAmounts = addTemporaryAmounts(selectedCart);
          const fallbackSelectedCart = addTemporaryAmounts(
            sortedByDate[0].slots[0]
          );

          return {
            b2bSelectedCart:
              selectedCartWithTempAmounts || fallbackSelectedCart,
            totalItems: flattenedCartItems.length,
            totalPrice: getB2BTotalPrice(flattenSlots),
            leftToFreeDelivery: selectedCart.leftToFreeDelivery,
            freeDeliveryFee: selectedCart.freeDelivery,
            leftToMinOrder:
              selectedCart.priceWithoutVat - selectedCart.minAmount,
            minAmount: selectedCart.minAmount,
            cartItemsDictionary: {
              ...productsDictionary,
            },
            b2bCarts: { slots: flattenSlots },
            summary: sortedByDate[selectedCartIndex].checkoutSummary,
            b2bDeliverySlots: sortedByDate[selectedCartIndex].availableDates,
            b2bSummaries: sortedByDate.map(
              ({ checkoutSummary }) => checkoutSummary
            ),
            b2bAvailableDeliveryDates: sortedByDate.map(
              ({ availableDates }) => availableDates
            ),
          };
        });
      },
    }),
    { name: 'cart' }
  )
);

export const useCartStateTrackedStore = createTrackedSelector(useCartState);
