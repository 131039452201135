import { ReactNode } from 'react';
import { toast as originalToast, ToastPosition } from 'react-toastify';
import { Icon } from 'ui/components/atoms/Icon';

export type ToastTypeOptions = 'info' | 'error' | 'success' | 'warning';

const IconsMap: Record<ToastTypeOptions, ReactNode> = {
  info: <Icon name="infoFilled" className="toast-info-icon" />,
  error: <Icon name="infoFilled" className="toast-error-icon" />,
  success: <Icon name="successFilled" className="toast-success-icon" />,
  warning: <Icon name="warningFilled" className="toast-warning-icon" />,
};

const ClassMap: Record<ToastTypeOptions, string> = {
  info: 'toast-info',
  error: 'toast-error',
  success: 'toast-success',
  warning: 'toast-warning',
};

export type ToasterProps = {
  type?: ToastTypeOptions;
  message: string;
  position?: ToastPosition;
  hideProgressBar?: boolean;
  draggable?: boolean;
  pauseOnHover?: boolean;
  toastId?: string;
};

export function useToaster() {
  const toast = ({
    type = 'info',
    message,
    hideProgressBar = false,
    position = 'bottom-center',
    draggable = false,
    pauseOnHover = true,
    toastId,
  }: ToasterProps) => {
    originalToast[type](message, {
      progressClassName: ClassMap[type],
      className: 'toast-box',
      bodyClassName: 'toast-body',
      hideProgressBar,
      position,
      type,
      icon: IconsMap[type],
      draggable,
      pauseOnHover,
      toastId,
    });
  };
  return {
    toast,
  };
}
