import { useRouter } from 'next/router';

import { StaticLinks } from '../constants';

type UseRedirectProps = {
  toHomePage: () => void;
  toSignUpPage: () => void;
  toPage: (path: string) => void;
  toSearchPage: (term: string) => void;
  toPaymentResultPage: (id: string) => void;
  toShoppingCart: () => void;
};

export const useRedirect = (): UseRedirectProps => {
  const router = useRouter();
  const redirectTo = (path: string, query?: string) => {
    router.push(path, query, {
      locale: router.locale,
    });
  };

  return {
    toHomePage: () => redirectTo(StaticLinks.Home, router.pathname),
    toSignUpPage: () => redirectTo(StaticLinks.Register, ''),
    toPaymentResultPage: (id: string) =>
      redirectTo(`${StaticLinks.PaymentResult}/${id}`, ''),
    toPage: (path: string) => redirectTo(path, ''),
    toSearchPage: (term: string) =>
      redirectTo(`${StaticLinks.SearchResults}?term=${term}`),
    toShoppingCart: () => redirectTo(StaticLinks.ShoppingCart, ''),
  };
};
