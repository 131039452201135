import getConfig from 'next/config';
import { useEffect, ReactNode } from 'react';
import axios from 'axios';

import { isDeliveryAddressB2c } from 'epromo-lib/utils/address';
import { getTokenFromCookie } from 'epromo-lib/utils/token';
import { useUserProfile } from 'epromo-lib/store';
import { bootIntercom } from 'epromo-lib/intercom';

interface Props {
  children: ReactNode;
}

const { publicRuntimeConfig } = getConfig();

const getUserHash = async (userId: string) =>
  await axios({
    baseURL: publicRuntimeConfig.baseUrl,
    url: '/api/intercom-hash',
    headers: { userId },
  });

export const IntercomWrapper = ({ children }: Props) => {
  const { userProfile, selectedDeliveryAddress } = useUserProfile();

  const userId = userProfile?.id;

  const token = getTokenFromCookie();

  useEffect(() => {
    async function initIntercomUser(userId: string) {
      const response = await getUserHash(userId);
      const isB2c = isDeliveryAddressB2c(selectedDeliveryAddress?.id);
      const interComOpts = {
        user_id: userId,
        user_hash: response.data,
        name: `${userProfile?.firstName} ${userProfile?.lastName}`,
        email: userProfile?.email || 'N/A',
        phone: userProfile?.phoneNumber || 'N/A',
        company: isB2c
          ? undefined
          : {
              companyId: String(selectedDeliveryAddress?.orgId),
              name: selectedDeliveryAddress?.holderName,
            },
        Activity: selectedDeliveryAddress?.title,
        DeliveryAddress: selectedDeliveryAddress?.address,
        POSID: selectedDeliveryAddress?.posId,
      };
      bootIntercom(interComOpts);
    }

    if (!!userId && !!token) {
      initIntercomUser(userId);
    } else {
      if (!publicRuntimeConfig.isDevMode) {
        bootIntercom();
      }
    }
  }, [
    selectedDeliveryAddress?.address,
    selectedDeliveryAddress?.holderName,
    selectedDeliveryAddress?.id,
    selectedDeliveryAddress?.orgId,
    selectedDeliveryAddress?.posId,
    selectedDeliveryAddress?.title,
    token,
    userId,
    userProfile?.email,
    userProfile?.firstName,
    userProfile?.lastName,
    userProfile?.phoneNumber,
  ]);

  return <>{children}</>;
};
