import { useEffect } from 'react';

import { useNativeAppState } from '../store/native-app';
import { getCookie, setCookie } from '../utils/cookie';

const cookieKey = 'native_epromo_app_notification';
enum NotificationStatus {
  Closed = '0',
  Opened = '1',
}

export function useNativeAppNotification() {
  const {
    nativeAppNotificationIsClosed,
    closeNativeAppNotification,
    openNativeAppNotification,
  } = useNativeAppState();

  useEffect(() => {
    if (getCookie(cookieKey) === NotificationStatus.Closed) {
      close();
    } else {
      open();
    }
  }, []);

  const open = () => {
    setCookie(cookieKey, NotificationStatus.Opened);
    openNativeAppNotification();
  };

  const close = () => {
    setCookie(cookieKey, NotificationStatus.Closed);
    closeNativeAppNotification();
  };

  return {
    nativeAppNotificationIsClosed,
    open,
    close,
  };
}
