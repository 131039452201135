import axios, { AxiosError } from 'axios';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';

import {
  DeliveryAddressContract,
  VerificationCodeReq,
  VerificationCodeResponse,
  VerificationInput,
} from 'epromo-types';
import { getTokenFromCookie } from '../utils/token';
import { Endpoints, resolveHeaders, useMutationQuery } from '../index';

const { publicRuntimeConfig } = getConfig();

type UsePhoneVerificationProps = {
  onPhoneVerified?: (data: VerificationCodeResponse) => void;
  onCodeResent?: (data: DeliveryAddressContract) => void;
  verificationEndpoint?: string;
  resendEndpoint?: string;
};

const formatVerificationBody = (data: { code: number | string }) => ({
  code: Number(data.code),
});
const formatResendCodeBody = (phoneNumber?: string | undefined | null) => ({
  deviceType: 'notSpecified',
  string: phoneNumber || '',
});

export function useAddressPhoneVerification({
  onPhoneVerified,
  onCodeResent,
}: UsePhoneVerificationProps) {
  const router = useRouter();

  const submitVerifyNewAddressPhone = (data: VerificationInput) => {
    verifyNumber(formatVerificationBody(data));
  };

  const {
    mutate: changeNumber,
    isPending: changeNumberIsLoading,
    error: changeNumberErr,
  } = useMutation({
    mutationKey: ['changing-address-number'],
    mutationFn: async (payload: {
      phoneNumber: string | undefined | null;
      addressId: string;
    }) => {
      const { addressId, phoneNumber } = payload;
      const response = await axios.request<any>({
        url: `${publicRuntimeConfig.apiUrl}/${Endpoints.changeAddressNumber}`,
        method: 'POST',
        data: formatResendCodeBody(phoneNumber),
        headers: resolveHeaders(getTokenFromCookie(), router.locale, addressId),
      });
      return response.data;
    },
    onSuccess: (data: DeliveryAddressContract) => {
      onCodeResent?.(data);
    },
  });

  const {
    mutate: verifyNumber,
    error: verifyNumberErr,
    isPending: verifyNumberIsLoading,
    reset: verifyNumberReset,
  } = useMutationQuery<VerificationCodeResponse, VerificationCodeReq>({
    endPoint: Endpoints.verifyAddressNumber,
    mutationKey: ['verify-new-address-number'],
    onSuccess: (data: VerificationCodeResponse) => {
      onPhoneVerified?.(data);
    },
    toastErrorGlobally: false,
  });

  return {
    changeNumber,
    changeNumberIsLoading,
    changeNumberErr: changeNumberErr as AxiosError,
    verifyNumber: submitVerifyNewAddressPhone,
    verifyNumberErr,
    verifyNumberIsLoading,
    verifyNumberReset,
  };
}
