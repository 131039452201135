import { ProductContract, ShoppingCartItemModel } from 'epromo-types';

import { priceFormatter } from './priceFormatter';

export function roundAt2DecimalPlaces(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function formatPrice(price: number) {
  return priceFormatter.format(roundAt2DecimalPlaces(price));
}

export function calculateTemplateProductsPriceSum(products: ProductContract[]) {
  return products.reduce((prev, curr) => {
    return prev + curr.minimumAmount * curr.price;
  }, 0);
}

export const isDiscountedPrice = (basePrice: number, price: number) =>
  basePrice > price;

export const calculateB2cTotalPrice = (cartItems: ShoppingCartItemModel[]) => {
  const totalPrice = cartItems.reduce(
    (prev, current) => prev + current.amount * current.product.price,
    0
  );
  return totalPrice;
};
