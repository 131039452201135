import { IncomingMessage } from 'http';

import { getCookie, removeCookie, setCookie } from './cookie';

export const USER_AUTH_TOKEN = 'token';

export const setTokenToCookie = (
  token: string,
  expires?: number | Date | undefined
) => {
  setCookie(USER_AUTH_TOKEN, token, expires);
};

export const getTokenFromCookie = (req?: IncomingMessage) => {
  return getCookie(USER_AUTH_TOKEN, req);
};

export const removeTokenCookie = () => {
  removeCookie(USER_AUTH_TOKEN);
};
