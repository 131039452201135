import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { UserAddress, UserProfile } from 'epromo-types';

import { getTokenFromCookie } from '../../utils/token';
import { storage } from '../helpers';

export interface UserProfileData {
  userProfile?: UserProfile | null;
  selectedDeliveryAddress?: UserAddress | null;
}

export interface UserProfileState extends UserProfileData {
  setUserProfile: (userProfile: UserProfile | null) => void;
  setAddress: (data: UserAddress | null) => void;
  clearStore: () => void;
}

const initialUserProfileState: UserProfileData = {
  userProfile: null,
  selectedDeliveryAddress: null,
};

export const userProfileSelector = {
  userProfile: (state: UserProfileState) => {
    const token = getTokenFromCookie();
    if (!token) {
      return null;
    }
    return state.userProfile;
  },
};

export const PROFILE_KEY = 'user-profile';

export const useUserProfile = create<UserProfileState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialUserProfileState,
        setUserProfile: (userProfile: UserProfile | null) => {
          set({ userProfile });
        },
        setAddress: (selectedDeliveryAddress: UserAddress | null) => {
          set({ selectedDeliveryAddress });
        },
        clearStore: () => {
          set(initialUserProfileState);
        },
      }),
      {
        name: PROFILE_KEY,
        storage,
      }
    )
  )
);
