import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  FilterItem,
  FilterItemOption,
} from 'epromo-types/Filters/local-filter';
import { FilterContract } from 'epromo-types/Inventory';

interface FiltersData {
  selectedLocalFilters?: FilterItemOption[] | [];
  localFilters: FilterItem[];
}

export interface LocalFiltersState extends FiltersData {
  setSelectedLocalFilters: (data: FilterItemOption[]) => void;
  addFilterItem: (data: FilterItemOption) => void;
  removeFilterItem: (data: FilterItemOption) => void;
  clearLocalFilters: () => void;
  setLocalFilters: (localFilters: FilterItem[]) => void;
}

const initialState: FiltersData = {
  selectedLocalFilters: [],
  localFilters: [],
};

export const mapLocalFiltersToReq = (
  filters: FilterContract[],
  selectedLocalFilters?: FilterItemOption[]
) => {
  return filters.map((filter) => {
    const currentFilterValues = (selectedLocalFilters || [])
      // eslint-disable-next-line max-len
      .filter((selectedFilter) => selectedFilter.parentId === filter.id)
      .map((selectedFilter) => selectedFilter.title.value);

    return {
      id: filter.id,
      title: filter.name,
      searchable: false,
      options: filter.values.map((val) => ({
        title: { value: val.value, count: val.count },
        value: currentFilterValues.includes(val.value),
        parentId: filter.id,
      })),
    };
  });
};

export const LOCAL_FILTERS_KEY = 'local-filters';

export const useLocalFilters = create<LocalFiltersState>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setLocalFilters: (localFilters: FilterItem[]) => {
        set({ localFilters });
      },
      setSelectedLocalFilters: (localFilters: FilterItemOption[]) => {
        set({ selectedLocalFilters: localFilters });
      },
      removeFilterItem: (itemToDelete: FilterItemOption) => {
        const state = get().selectedLocalFilters;
        // eslint-disable-next-line max-len
        const uniqIdToDelete = `${itemToDelete.title.value}_${itemToDelete.parentId}`;

        const filtered = state?.filter((filter) => {
          const uniqIdFilter = `${filter.title.value}_${filter.parentId}`;

          return uniqIdToDelete !== uniqIdFilter;
        });

        return set({
          selectedLocalFilters: filtered,
        });
      },
      addFilterItem: (data: FilterItemOption) => {
        set({
          selectedLocalFilters: [...(get().selectedLocalFilters || []), data],
        });
      },
      clearLocalFilters: () => {
        set({ selectedLocalFilters: [] });
      },
    }),
    { name: LOCAL_FILTERS_KEY }
  )
);
